import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Transition, Listbox, Tab } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/solid';

import Http from 'services/http';
import Customer from 'services/customer';
import Response from 'services/response';
import Structures from 'services/structures';

import Modal from 'components/modal';
import Loading from 'components/loading';
import Spinner from 'components/spinner';

import UString from 'utilities/string';
import Table from 'components/table';
import MoneyIn from 'services/moneyIn';
import MoneyOut from 'services/moneyOut';
import MoneyTransfers from 'services/money-transfers';
import CheckIn from 'services/checkIn';
import CheckOut from 'services/checkOut';
import Bank from 'services/bank';
import Invoice from 'services/invoice';
import Case from 'services/cases';
import Check from 'services/analysisCheck';

import {
  CheckInValidation,
  CheckOutValidation,
  CustomerValidation,
  MoneyInValidation,
  MoneyOutValidation,
} from 'validations';

import { toast } from 'react-toastify';
import {
  Stack,
  Pagination,
  Skeleton,
  Typography,
  Table as MTable,
  TableContainer,
  TableBody,
  TableHead,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  TextField,
} from '@mui/material';
import { InvoiceTableRow, ResponsiveTableCell, ResponsiveTableRow } from 'assets/table';

export default function () {
  const [modal, setModal] = useState<boolean>(false);
  const [delCustomer, setDelCustomer] = useState<any | null>(null);
  const [total, setTotal] = useState<any>(0);
  const [allData, setAllData] = useState<any>([]);
  const [moneyData, setMoneyData] = useState<any>([]);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [transfersMoneyData, setTransfersMoneyData] = useState<any>([]);

  const Service = new Customer(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();
  const Delete = new Response();

  const Customers = new Response();
  const CustomerFetch = new Response();

  const CService = new MoneyIn(useContext(Http.Context)!);
  const CFetch = new Response();

  const CaseService = new Case(useContext(Http.Context)!);
  const CaseFetch = new Response();

  const CheckService = new Check(useContext(Http.Context)!);

  const OService = new MoneyOut(useContext(Http.Context)!);
  const OFetch = new Response();

  const MService = new CheckIn(useContext(Http.Context)!);
  const MFetch = new Response();

  const LService = new CheckOut(useContext(Http.Context)!);
  const LFetch = new Response();

  const BService = new Bank(useContext(Http.Context)!);
  const BFetch = new Response();

  const IService = new Invoice(useContext(Http.Context)!);
  const Invoices = new Response();

  const MoneyService = new MoneyTransfers(useContext(Http.Context)!);
  const MoneyFetch = new Response();
  const MoneyInvoiceFetch = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();

  const { id, path } = useParams();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => (id && Invoices.handle(IService.getByFilter(0, 8, id, '1')), undefined), [id]);

  useEffect(() => (id && Customers.handle(Service.getAll()), undefined), [id]);

  useEffect(() => (!BFetch.data && BFetch.handle(BService.get()), undefined), [BFetch.data]);

  useEffect(() => (id && CFetch.handle(CService.getById(id!)), undefined), [id]);
  useEffect(() => (id && Fetch.handle(Service.getById(id!)), undefined), [id]);
  useEffect(() => (id && OFetch.handle(OService.getById(id!)), undefined), [id]);
  useEffect(() => (id && MoneyFetch.handle(MoneyService.getAll(0, 8, id, '1')), undefined), [id]);
  useEffect(
    () => (!CaseFetch.data && CaseFetch.handle(CaseService.getAllOpen()), undefined),
    [CaseFetch.data],
  );

  useEffect(
    () => (!CustomerFetch.data && CustomerFetch.handle(Service.get()), undefined),
    [CustomerFetch.data],
  );
  useEffect(
    () => (
      id && MoneyInvoiceFetch.handle(MoneyService.getAllTransfersAndInvoices(0, 8, id, '1')),
      undefined
    ),
    [id],
  );
  useEffect(() => (id && MFetch.handle(MService.getById(id!)), undefined), [id]);
  useEffect(() => (id && LFetch.handle(LService.getById(id!)), undefined), [id]);
  useEffect(() => {
    if (MoneyFetch.data) {
      setMoneyData(MoneyFetch?.data?.data);
    }
  }, [MoneyFetch.data]);

  useEffect(() => {
    if (Invoices.data) {
      setInvoiceData(Invoices?.data?.data);
    }
  }, [Invoices.data]);

  useEffect(() => {
    if (MoneyInvoiceFetch.data) {
      setTransfersMoneyData(MoneyInvoiceFetch?.data?.data);
      setTotalPage(getPages(0));
    }
  }, [MoneyInvoiceFetch.data]);

  useEffect(() => {
    if (CFetch.data && OFetch.data && MFetch.data && LFetch.data) {
      let total = 0;
      CFetch.data.map((inf: any) => {
        total += inf.amount;
      });
      OFetch.data.map((out: any) => {
        total -= out.amount;
      });
      MFetch.data.map((inf: any) => {
        total += inf.amount;
      }),
        LFetch.data.map((out: any) => {
          total -= out.amount;
        }),
        setTotal(total);
      setAllData([...CFetch.data, ...OFetch.data, ...MFetch.data, ...LFetch.data]);
    }
  }, [CFetch.data, OFetch.data, MFetch.data, LFetch.data]);

  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading) {
      setTimeout(
        () => (
          (Fetch.data = Fetch.data.filter((data: any) => data.id !== delCustomer.id)),
          setModal(false)
        ),
        500,
      );
      navigate('/customers');
    }
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(delCustomer)), [delCustomer]);
  useEffect(
    () => (
      !modal && setTimeout(() => (setDelCustomer(null), (Delete.data = null)), 200), undefined
    ),
    [modal],
  );

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    if (Save.data && !Save.error) {
      if (path == 'new' || path == 'edit') {
        toast.success(`Müşteri/Tedarikçi ${id ? 'düzenleme' : 'ekleme'} başarılı.`);
        navigate('/customers');
      } else {
        toast.success(`${path == 'addPayment' ? 'Ödeme ekleme' : 'Tahsilat ekleme'} başarılı.`);
        navigate('/customers');
      }
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      if (path == 'new' || path == 'edit') {
        toast.error(`Müşteri/Tedarikçi ${id ? 'düzenleme' : 'ekleme'} başarısız.`);
        navigate('/customers');
      } else {
        toast.error(
          `${path == 'addPayment' ? 'Ödeme ekleme' : 'Tahsilat ekleme'} başarısız. (${Save.error})`,
        );
        navigate('/customers');
      }
    }
  }, [Save.error]);

  const getPages = (type: number) => {
    var pageCount: number = 0;
    switch (type) {
      case 0:
        var count = MoneyInvoiceFetch?.data?.totalCount;
        var pageCount = Math.ceil(count / 8);
        break;
      case 1:
        var count = Invoices?.data?.totalCount;
        var pageCount = Math.ceil(count / 8);
        break;
      case 2:
        var count = MoneyFetch?.data?.totalCount;
        var pageCount = Math.ceil(count / 8);
        break;
      default:
        break;
    }
    return pageCount;
  };

  const handleTabChange = (type: number) => {
    setPage(1);
    setMoneyData([]);
    setInvoiceData([]);
    switch (type) {
      case 0: //Tüm işlemler
        MoneyInvoiceFetch.handle(MoneyService.getAllTransfersAndInvoices(0, 8, id!, '1'));
        if (totalPage != getPages(0)) {
          setTotalPage(getPages(0));
        }
        break;
      case 1: //Fatura işlemleri
        Invoices.handle(IService.getByFilter(0, 8, id!, '1'));
        if (totalPage != getPages(1)) {
          setTotalPage(getPages(1));
        }
        break;
      case 2: //Para işlemleri
        MoneyFetch.handle(MoneyService.getAll(0, 8, id!, '1'));
        if (totalPage != getPages(2)) {
          setTotalPage(getPages(2));
        }
        break;
      default:
        break;
    }
  };

  const handlePageChange = (e: any, value: any, type: number) => {
    switch (type) {
      case 0: //Tüm işlemler
        MoneyInvoiceFetch.handle(MoneyService.getAllTransfersAndInvoices(value - 1, 8, id!, '1'));
        if (totalPage != getPages(0)) {
          setTotalPage(getPages(0));
        }
        setPage(value);
        break;
      case 1: //Fatura işlemleri
        Invoices.handle(IService.getByFilter(value - 1, 8, id!, '1'));
        if (totalPage != getPages(1)) {
          setTotalPage(getPages(1));
        }
        setPage(value);
        break;
      case 2: //Para işlemleri
        MoneyFetch.handle(MoneyService.getAll(value - 1, 8, id!, '1'));
        if (totalPage != getPages(2)) {
          setTotalPage(getPages(2));
        }
        setPage(value);
        break;
      case 3:
        break;
      default:
        break;
    }
  };

  const getCustomer = (data: any) => {
    if (data.process_type == 1) {
      if (data.section_id == id) {
        let customer = Customers?.data?.find((customer: any) => customer._id == data.sender);
        return customer?.account_name || customer?.company;
      }
      let customer = Customers?.data?.find((customer: any) => customer._id == data.section_id);
      return customer?.account_name || customer?.company;
    }
    if (data.section_id == id) {
      let customer = Customers?.data?.find((customer: any) => customer._id == data.receiver);
      return customer?.account_name || customer?.company;
    }
    let customer = Customers?.data?.find((customer: any) => customer._id == data.section_id);
    return customer?.account_name || customer?.company;
  };

  if (path == 'show') {
    return (
      <React.Fragment>
        <Modal onClose={() => !Delete.loading && setModal(false)} state={[modal, setModal]}>
          <React.Fragment>
            <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <div className='sm:flex sm:items-start'>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <h3 className='text-lg leading-6 font-medium text-gray-900'>
                    {delCustomer?.title}
                  </h3>
                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      Bu Müşteriyi silmek istediğinize emin misiniz? Bu işlem geri alınamaz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-actions'>
              <button
                disabled={Delete.data || Delete.loading}
                type='button'
                className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
                onClick={() => Delete.handle(Service.delete(delCustomer.id))}
              >
                {Delete.data ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path
                      fillRule='evenodd'
                      d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                      clipRule='evenodd'
                    />
                  </svg>
                ) : Delete.loading ? (
                  <Spinner className='h-5 m-auto' />
                ) : (
                  <span>Sil</span>
                )}
              </button>
              <button
                disabled={Delete.loading}
                type='button'
                className='mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto'
                onClick={setModal.bind(null, false)}
              >
                İptal
              </button>
            </div>
          </React.Fragment>
        </Modal>
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Müşteri Bilgileri</h2>
          <button
            className='flex shadow-md flex-row text-white bg-red-600 py-3 px-6 rounded-lg text-sm font-medium ml-auto'
            onClick={setDelCustomer.bind(null, { id })}
          >
            <TrashIcon className='h-5 w-5' />
            <span className='ml-3'>Müşteriyi Sil</span>
          </button>
        </div>
        <div id='content' className='flex flex-row'>
          <div className='flex-1 flex flex-col space-y-8'>
            <div>
              <Stack direction='row' justifyContent='space-between'>
                <ul className='flex flex-col divide divide-y'>
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>Firma Adı:</span>
                    <span>{Fetch.data?.company}</span>
                  </li>
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>E-Posta: </span>
                    <span>{Fetch.data?.e_mail}</span>
                  </li>
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>Telefon: </span>
                    <span>{Fetch.data?.number}</span>
                  </li>
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>Adres: </span>
                    <span>{Fetch.data?.address}</span>
                  </li>
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>Vergi Dairesi:</span>
                    <span>{Fetch.data?.tax_office}</span>
                  </li>
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>Vergi Numarası:</span>
                    <span>{Fetch.data?.identity_no}</span>
                  </li>
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>Açılış Bakiyesi:</span>
                    <span>{Fetch.data?.opening_balance?.toFixed(2)}</span>
                  </li>
                  {Fetch.data?.card_type == 4 ? (
                    <>
                      <li className='px-12 flex space-x-4 py-3'>
                        <span className='font-medium'>Maaş:</span>
                        <span>{Fetch.data?.salary?.toFixed(2)}</span>
                      </li>
                      <li className='px-12 flex space-x-4 py-3'>
                        <span className='font-medium'>Maaş Günü:</span>
                        <span>
                          {Fetch.data?.payday ? (
                            new Date(Fetch.data?.payday)?.toLocaleDateString()
                          ) : (
                            <>-</>
                          )}
                        </span>
                      </li>
                    </>
                  ) : null}
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>Döviz Türü:</span>
                    <span>{(Structures.types.currency_opt as any)[Fetch.data?.currency]}</span>
                  </li>
                </ul>
                <ul className='flex flex-col divide divide-y'>
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>Alınan Para:</span>
                    <span>{Fetch.data?.amount_given?.toFixed(2)}</span>
                  </li>
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>Verilen Para:</span>
                    <span>{Fetch.data?.amount_received?.toFixed(2)}</span>
                  </li>
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>Bakiye:</span>
                    <span>{Fetch.data?.balance?.toFixed(2)}</span>
                  </li>
                  <li className='px-12 flex space-x-4 py-3'>
                    <span className='font-medium'>Durum:</span>
                    {Fetch.data?.balance > 0 ? (
                      <span style={{ fontWeight: 'bold', color: 'red' }}>
                        Bu kişinin alacağı var.
                      </span>
                    ) : Fetch.data?.balance < 0 ? (
                      <span style={{ fontWeight: 'bold', color: 'green' }}>
                        Bu kişinin borcu var.
                      </span>
                    ) : (
                      <span>Bu kişinin borcu yok.</span>
                    )}
                  </li>
                </ul>
              </Stack>
            </div>

            <div>
              <div className='p-4'>
                <Tab.Group
                  onChange={(value: number) => {
                    handleTabChange(value);
                  }}
                >
                  <Tab.List className='flex space-x-2 p-4 rounded-xl bg-white max-w-md'>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                          selected
                            ? 'text-white shadow bg-green-600 border-2 border-green-600'
                            : 'text-green-600 hover:bg-green-600 hover:text-white border-2 border-green-600',
                        )
                      }
                    >
                      Tümü
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                          selected
                            ? 'text-white shadow bg-blue-600 border-2 border-blue-600'
                            : 'text-blue-600 hover:bg-blue-600 hover:text-white border-2 border-blue-600',
                        )
                      }
                    >
                      Fatura İşlemleri
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                          selected
                            ? 'text-white shadow bg-red-600 border-2 border-red-600'
                            : 'text-red-600 hover:bg-red-600 hover:text-white border-2 border-red-600',
                        )
                      }
                    >
                      Para İşlemleri
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className='mt-2'>
                    <Tab.Panel>
                      {MoneyInvoiceFetch?.data ? (
                        <>
                          {MoneyInvoiceFetch?.data?.totalCount == 0 ? (
                            <div style={{ height: '32vw' }}>
                              <Typography textAlign='center' variant='h5' fontWeight='bold'>
                                Müşterinin hiç işlemi bulunamadı.
                              </Typography>
                            </div>
                          ) : (
                            <>
                              <div style={{ height: '32vw' }}>
                                {transfersMoneyData && (
                                  <TableContainer>
                                    <MTable>
                                      <TableHead>
                                        <ResponsiveTableRow>
                                          <ResponsiveTableCell>Açıklama</ResponsiveTableCell>
                                          <ResponsiveTableCell>İşlem Tipi</ResponsiveTableCell>
                                          <ResponsiveTableCell>Tutar</ResponsiveTableCell>
                                          <ResponsiveTableCell>Oluşturan</ResponsiveTableCell>
                                          <ResponsiveTableCell>
                                            Oluşturulma Tarihi
                                          </ResponsiveTableCell>
                                        </ResponsiveTableRow>
                                      </TableHead>
                                      <TableBody>
                                        {transfersMoneyData?.map((invoice: any) => (
                                          <InvoiceTableRow
                                            isPaid={
                                              invoice.total_amount
                                                ? invoice.invoice_types == 1
                                                  ? true
                                                  : false
                                                : (invoice?.process_type == 1 &&
                                                    invoice?.section_id == id) ||
                                                  (invoice?.process_type == 0 &&
                                                    invoice?.receiver?.toString() == id)
                                                ? true
                                                : false
                                            }
                                          >
                                            <ResponsiveTableCell>
                                              {invoice.description}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.invoice_types
                                                ? 'Fatura İşlemi'
                                                : 'Para İşlemi'}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {isNaN(invoice.total_amount)
                                                ? invoice.amount?.toFixed(2)
                                                : invoice.total_amount?.toFixed(2)}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.user?.name} {invoice.user?.lastname}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {new Date(invoice.createdAt)?.toLocaleString()}
                                            </ResponsiveTableCell>
                                          </InvoiceTableRow>
                                        ))}
                                      </TableBody>
                                    </MTable>
                                  </TableContainer>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Stack
                            direction='column'
                            spacing={1}
                            alignItems='center'
                            sx={{ height: '32vw' }}
                          >
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={30}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                          </Stack>
                        </>
                      )}
                      {MoneyInvoiceFetch?.data?.totalCount != 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            width: '90%',
                            margin: 'auto',
                            marginTop: '1.3vw',
                          }}
                        >
                          <Pagination
                            sx={{ margin: 'auto' }}
                            onChange={(e, value) => handlePageChange(e, value, 0)}
                            color='primary'
                            page={page}
                            count={totalPage}
                            size='large'
                          />
                        </div>
                      ) : null}
                      <div style={{ height: '0.6vw' }}></div>
                    </Tab.Panel>
                    <Tab.Panel>
                      {Invoices?.data ? (
                        <>
                          {Invoices?.data?.totalCount == 0 ? (
                            <div style={{ height: '32vw' }}>
                              <Typography textAlign='center' variant='h5' fontWeight='bold'>
                                Müşterinin hiç fatura işlemi bulunamadı.
                              </Typography>
                            </div>
                          ) : (
                            <>
                              <div style={{ height: '32vw' }}>
                                {invoiceData && (
                                  <TableContainer>
                                    <MTable>
                                      <TableHead>
                                        <ResponsiveTableRow>
                                          <ResponsiveTableCell>Açıklama</ResponsiveTableCell>
                                          <ResponsiveTableCell>
                                            Fatura Sıra Numarası
                                          </ResponsiveTableCell>
                                          <ResponsiveTableCell>
                                            Fatura Seri Kodu
                                          </ResponsiveTableCell>
                                          <ResponsiveTableCell>Toplam Tutar</ResponsiveTableCell>
                                          <ResponsiveTableCell>Oluşturan</ResponsiveTableCell>
                                          <ResponsiveTableCell>
                                            Oluşturulma Tarihi
                                          </ResponsiveTableCell>
                                        </ResponsiveTableRow>
                                      </TableHead>
                                      <TableBody>
                                        {invoiceData?.map((invoice: any) => (
                                          <InvoiceTableRow isPaid={invoice.invoice_types == 1}>
                                            <ResponsiveTableCell>
                                              {invoice.description}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.invoice_code}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.invoice_no}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.total_amount}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.user?.name} {invoice.user?.lastname}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {new Date(invoice?.createdAt)?.toLocaleString()}
                                            </ResponsiveTableCell>
                                          </InvoiceTableRow>
                                        ))}
                                      </TableBody>
                                    </MTable>
                                  </TableContainer>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Stack
                            direction='column'
                            spacing={1}
                            alignItems='center'
                            sx={{ height: '32vw' }}
                          >
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={30}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                          </Stack>
                        </>
                      )}
                      {Invoices?.data?.totalCount != 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            width: '90%',
                            margin: 'auto',
                            marginTop: '1.3vw',
                          }}
                        >
                          <Pagination
                            sx={{ margin: 'auto' }}
                            onChange={(e, value) => handlePageChange(e, value, 1)}
                            color='primary'
                            page={page}
                            count={totalPage}
                            size='large'
                          />
                        </div>
                      ) : null}
                      <div style={{ height: '0.6vw' }}></div>
                    </Tab.Panel>
                    <Tab.Panel>
                      {MoneyFetch?.data ? (
                        <>
                          {MoneyFetch?.data?.totalCount == 0 ? (
                            <>
                              <div style={{ height: '32vw' }}>
                                <Typography textAlign='center' variant='h5' fontWeight='bold'>
                                  Müşterinin hiç para işlemi bulunamadı.
                                </Typography>
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ height: '32vw' }}>
                                {moneyData && (
                                  <TableContainer>
                                    <MTable>
                                      <TableHead>
                                        <ResponsiveTableRow>
                                          <ResponsiveTableCell>Açıklama</ResponsiveTableCell>
                                          <ResponsiveTableCell>Tarih</ResponsiveTableCell>
                                          <ResponsiveTableCell>Tutar</ResponsiveTableCell>
                                          <ResponsiveTableCell>Alıcı/Gönderen</ResponsiveTableCell>
                                          <ResponsiveTableCell>
                                            Oluşturulma Tarihi
                                          </ResponsiveTableCell>
                                        </ResponsiveTableRow>
                                      </TableHead>
                                      <TableBody>
                                        {moneyData?.map((moneys: any) => (
                                          <InvoiceTableRow
                                            isPaid={
                                              (moneys?.process_type == 1 &&
                                                moneys?.section_id == id) ||
                                              (moneys?.process_type == 0 &&
                                                moneys?.receiver?.toString() == id)
                                                ? true
                                                : false
                                            }
                                          >
                                            <ResponsiveTableCell>
                                              {moneys.description}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {moneys?.in_time
                                                ? new Date(moneys?.in_time)?.toLocaleDateString()
                                                : new Date(moneys?.out_time)?.toLocaleDateString()}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {moneys.amount?.toFixed(2)}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {getCustomer(moneys)}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {new Date(moneys?.createdAt)?.toLocaleString()}
                                            </ResponsiveTableCell>
                                          </InvoiceTableRow>
                                        ))}
                                      </TableBody>
                                    </MTable>
                                  </TableContainer>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <>
                            <Stack
                              direction='column'
                              spacing={1}
                              alignItems='center'
                              sx={{ height: '32vw' }}
                            >
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={30}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                            </Stack>
                          </>
                        </>
                      )}
                      {MoneyFetch?.data?.totalCount != 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            width: '90%',
                            margin: 'auto',
                            marginTop: '1.3vw',
                          }}
                        >
                          <Pagination
                            sx={{ margin: 'auto' }}
                            onChange={(e, value) => handlePageChange(e, value, 2)}
                            color='primary'
                            page={page}
                            count={totalPage}
                            size='large'
                          />
                        </div>
                      ) : null}
                      <div style={{ height: '0.6vw' }}></div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  if (path == 'payment') {
    return (
      <React.Fragment>
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Ödemeler</h2>
          <div className='ml-auto flex space-x-2'>
            <Link
              className='flex items-center text-white bg-blue-600 py-3 px-6 rounded-lg text-sm font-medium ml-auto'
              to={`/customers/action/addCollection/${id}`}
            >
              <span>Ödeme Al</span>
            </Link>
            <Link
              className='flex items-center text-white bg-red-600 py-3 px-6 rounded-lg text-sm font-medium ml-auto'
              to={`/customers/action/addPayment/${id}`}
            >
              <span>Ödeme Yap</span>
            </Link>
          </div>
        </div>
        <div id='content' className='flex flex-row'>
          <div className='flex-1 space-y-8'>
            <div>
              <ul className='flex flex-col divide divide-y'>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Firma Adı:</span>
                  <span>{Fetch.data?.company}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Vergi Dairesi: </span>
                  <span>{Fetch.data?.tax_office}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Vergi Numarası: </span>
                  <span>{Fetch.data?.identity_no}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Bakiye: </span>
                  <span>{Number(Fetch.data?.opening_balance || 0) + total}</span>
                </li>
              </ul>
            </div>
            <div className='mb-24'>
              {allData && (
                <Table
                  data={{
                    head: [
                      {
                        element: 'Açıklama',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Tarih',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Tutar',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'İşlem Tipi',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Çek Senet',
                        props: { scope: 'col' },
                      },
                    ],
                    body: allData?.map((moneys: any) => ({
                      id: moneys._id,
                      elements: [
                        {
                          element: (
                            <div className='flex items-center'>
                              <div className='text-sm font-medium text-gray-900'>
                                {moneys.description}
                              </div>
                            </div>
                          ),
                        },
                        {
                          element: new Date(
                            moneys?.in_time
                              ? moneys?.in_time
                              : moneys?.out_time
                              ? moneys?.out_time
                              : moneys.check_time
                              ? moneys.check_time
                              : moneys.checkout_time,
                          ).toLocaleDateString('tr-TR', {
                            minute: 'numeric',
                            hour: 'numeric',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          }),
                          props: {
                            className: 'text-left text-sm text-gray-900',
                          },
                        },
                        {
                          element: `${moneys.process_type == 1 ? moneys.amount : -moneys.amount}`,
                          props: {
                            className: 'text-left text-sm text-gray-900',
                          },
                        },
                        {
                          element: `${moneys.process_type == 1 ? 'Ödeme' : 'Tahsilat'}`,
                          props: {
                            className: 'text-left text-sm text-gray-900',
                          },
                        },
                        {
                          element: `${
                            moneys?.check == 1 ? 'Çek' : moneys?.check == 0 ? 'Senet' : '-'
                          }`,
                          props: {
                            className: 'text-left text-sm text-gray-900',
                          },
                        },
                      ],
                    })),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  if (path == 'new' || path == 'edit') {
    return (
      <React.Fragment>
        <Loading done={!Fetch.loading || !CFetch.loading || !OFetch.loading} />
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Müşteri {id ? 'Düzenle' : 'Ekle'}</h2>
        </div>

        <div id='content'>
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              title: Fetch.data?.title || String(),
              company: Fetch.data?.company || String(),
              number: Fetch.data?.number || Number(),
              e_mail: Fetch.data?.e_mail || String(),
              identity_no: Fetch.data?.identity_no || Number(),
              tax_office: Fetch.data?.tax_office || String(),
              address: Fetch.data?.address || String(),
              city: Fetch.data?.city || String(),
              district: Fetch.data?.district || String(),
              country: Fetch.data?.country || String(),
              iban_no: Fetch.data?.iban_no || String(),
              opening_balance: Fetch.data?.opening_balance || Number(),
              open_account_risk_limit: Fetch.data?.open_account_risk_limit || Number(),
              fix_discount: Fetch.data?.fix_discount || Number(),
              maturity: Fetch.data?.maturity || Number(),
              code: Fetch.data?.code || String(),
              delivery_address: Fetch.data?.delivery_address || String(),
              description: Fetch.data?.description || String(),
              category: Fetch.data?.category || CFetch.data?.[0]?.id || String(),
              image: Fetch.data?.image || String(),
              start_time: Fetch.data?.start_time || String(),
              end_time: Fetch.data?.end_time || String(),
              options: Fetch.data?.options || Array(),
              currency:
                Fetch.data?.currency || Number(Object.keys(Structures.types.currency_opt)[0]),
              company_type:
                Fetch.data?.company_type || Number(Object.keys(Structures.types.company_opt)[0]),
              card_type: Fetch.data?.card_type || Number(Object.keys(Structures.types.card_opt)[0]),
              salary: Fetch.data?.salary || Number(),
              payday: Fetch.data?.payday || Date(),
            }}
            validationSchema={CustomerValidation}
            onSubmit={(values, errors) => {
              if (values.card_type == 4 && !values.salary) {
                errors.setErrors({ salary: 'maaş zorunludur' });
                return;
              }
              if (values.card_type != 4) values.payday = undefined;
              Save.handle(
                id && Fetch.data
                  ? Service.update(id, {
                      ...Fetch.data,
                      ...values,
                    })
                  : Service.create(values),
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className='action' onSubmit={handleSubmit}>
                <Tab.Group>
                  <Tab.List className='my-4'>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'mr-3 ',
                          '',
                          selected
                            ? 'border-b border-red-600 text-red-600 font-bold'
                            : 'border-b border-black',
                        )
                      }
                    >
                      Temel Bilgiler
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? 'border-b border-red-600 text-red-600 font-bold'
                            : 'border-b border-black',
                        )
                      }
                    >
                      Diğer Bilgiler
                    </Tab>
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel>
                      <div className='wrapper'>
                        <div className='item'>
                          <div className='w-full'>
                            <label htmlFor='company'>Firma Ünvanı</label>
                            <input
                              type='text'
                              name='company'
                              className={UString.concat_class_name(
                                errors.company && touched.company ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Firma Ünvanı'
                              value={values.company}
                            />
                            {errors.company && touched.company ? (
                              <span className='error'>* {errors.company}</span>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label htmlFor='number'>Telefon</label>
                            <input
                              type='text'
                              name='number'
                              className={UString.concat_class_name(
                                errors.number && touched.number ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='(___) ___-__-__'
                              value={values.number}
                            />
                            {errors.number && touched.number ? (
                              <span className='error'>* {errors.number}</span>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label htmlFor='e_mail'>E-Posta</label>
                            <input
                              type='text'
                              name='e_mail'
                              className={UString.concat_class_name(
                                errors.e_mail && touched.e_mail ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='E-Posta'
                              value={values.e_mail}
                            />
                            {errors.e_mail && touched.e_mail ? (
                              <span className='error'>* {errors.e_mail}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className='item'>
                          <div className='w-full'>
                            <label htmlFor='title'>Kısa Ad</label>
                            <input
                              type='text'
                              name='title'
                              className={UString.concat_class_name(
                                errors.title && touched.title ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Kısa Ad'
                              value={values.title}
                            />
                            {errors.title && touched.title ? (
                              <span className='error'>* {errors.title}</span>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label htmlFor='code'>Kodu</label>
                            <input
                              type='text'
                              name='code'
                              className={UString.concat_class_name(
                                errors.code && touched.code ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Kodu'
                              value={values.code}
                            />
                            {errors.code && touched.code ? (
                              <span className='error'>* {errors.code}</span>
                            ) : null}
                          </div>
                          <div className='relative w-full'>
                            <label>Kart Tipi</label>
                            <Listbox
                              value={values.card_type}
                              onChange={(value) => setFieldValue('card_type', Number(value))}
                            >
                              <div className='relative mt-1'>
                                <Listbox.Button className='listbox-btn'>
                                  <span className='listbox-title'>
                                    {(Structures.types.card_opt as any)[values.card_type]}
                                  </span>
                                  <span className='listbox-selector-icon'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      className='w-5 h-5 text-gray-400'
                                      viewBox='0 0 20 20'
                                      fill='currentColor'
                                    >
                                      <path
                                        fillRule='evenodd'
                                        d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                        clipRule='evenodd'
                                      />
                                    </svg>
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={React.Fragment}
                                  leave='transition ease-in duration-100'
                                  leaveFrom='opacity-100'
                                  leaveTo='opacity-0'
                                >
                                  <Listbox.Options className='listbox-options'>
                                    {Object.keys(Structures.types.card_opt).map((type, index) => (
                                      <Listbox.Option
                                        className={UString.concat_class_name(
                                          values.card_type === Number(type)
                                            ? 'bg-ebony text-white'
                                            : 'hover:bg-ebony hover:text-white',
                                          'flex items-center cursor-pointer px-6 py-2',
                                        )}
                                        key={index}
                                        value={Number(type)}
                                      >
                                        <span>{(Structures.types.card_opt as any)[type]}</span>
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          </div>
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='wrapper'>
                        <div className='item'>
                          <div className='w-full'>
                            <label htmlFor='identity_no'>Vkn | Tckn</label>
                            <input
                              type='text'
                              name='identity_no'
                              className={UString.concat_class_name(
                                errors.identity_no && touched.identity_no ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Vkn | Tckn'
                              value={values.identity_no}
                            />
                            {errors.identity_no && touched.identity_no ? (
                              <span className='error'>* {errors.identity_no}</span>
                            ) : null}
                          </div>
                          <div className='relative w-full'>
                            <label>Firma Türü</label>
                            <Listbox
                              value={values.company_type}
                              onChange={(value) => setFieldValue('company_type', Number(value))}
                            >
                              <div className='relative mt-1'>
                                <Listbox.Button className='listbox-btn'>
                                  <span className='listbox-title'>
                                    {(Structures.types.company_opt as any)[values.company_type]}
                                  </span>
                                  <span className='listbox-selector-icon'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      className='w-5 h-5 text-gray-400'
                                      viewBox='0 0 20 20'
                                      fill='currentColor'
                                    >
                                      <path
                                        fillRule='evenodd'
                                        d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                        clipRule='evenodd'
                                      />
                                    </svg>
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={React.Fragment}
                                  leave='transition ease-in duration-100'
                                  leaveFrom='opacity-100'
                                  leaveTo='opacity-0'
                                >
                                  <Listbox.Options className='listbox-options'>
                                    {Object.keys(Structures.types.company_opt).map(
                                      (type, index) => (
                                        <Listbox.Option
                                          className={UString.concat_class_name(
                                            values.company_type === Number(type)
                                              ? 'bg-ebony text-white'
                                              : 'hover:bg-ebony hover:text-white',
                                            'flex items-center cursor-pointer px-6 py-2',
                                          )}
                                          key={index}
                                          value={Number(type)}
                                        >
                                          <span>{(Structures.types.company_opt as any)[type]}</span>
                                        </Listbox.Option>
                                      ),
                                    )}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          </div>
                        </div>
                        <div className='item'>
                          <div className='w-full'>
                            <label htmlFor='tax_office'>Vergi Dairesi</label>
                            <input
                              type='text'
                              name='tax_office'
                              className={UString.concat_class_name(
                                errors.tax_office && touched.tax_office ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Vergi Dairesi'
                              value={values.tax_office}
                            />
                            {errors.tax_office && touched.tax_office ? (
                              <span className='error'>* {errors.tax_office}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='item'>
                        <div className='w-full'>
                          <label htmlFor='address'>Açık Adres</label>
                          <textarea
                            name='address'
                            className={UString.concat_class_name(
                              errors.address && touched.address ? 'has-error' : undefined,
                              'my-1',
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder='Açık Adres'
                            value={values.address}
                          />
                          {errors.address && touched.address ? (
                            <span className='error'>* {errors.address}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className='wrapper mt-3'>
                        <div className='item'>
                          <div className='w-full'>
                            <label htmlFor='city'>İl</label>
                            <input
                              type='text'
                              name='city'
                              className={UString.concat_class_name(
                                errors.city && touched.city ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='İl'
                              value={values.city}
                            />
                            {errors.city && touched.city ? (
                              <span className='error'>* {errors.city}</span>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label htmlFor='country'>Ülke</label>
                            <input
                              type='text'
                              name='country'
                              className={UString.concat_class_name(
                                errors.country && touched.country ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Ülke'
                              value={values.country}
                            />
                            {errors.country && touched.country ? (
                              <span className='error'>* {errors.country}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className='item'>
                          <div className='w-full'>
                            <label htmlFor='district'>İlçe</label>
                            <input
                              type='text'
                              name='district'
                              className={UString.concat_class_name(
                                errors.district && touched.district ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='İlçe'
                              value={values.district}
                            />
                            {errors.district && touched.district ? (
                              <span className='error'>* {errors.district}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='wrapper mt-3'>
                        <div className='item'>
                          <div className='w-full'>
                            <label htmlFor='opening_balance'>
                              {id ? <>Bakiye</> : <>Açılış Bakiyesi</>}
                            </label>
                            <input
                              type='text'
                              name='opening_balance'
                              className={UString.concat_class_name(
                                errors.opening_balance && touched.opening_balance
                                  ? 'has-error'
                                  : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={id ? 'Bakiye' : 'Açılış Bakiyesi'}
                              value={values.opening_balance}
                            />
                            {errors.opening_balance && touched.opening_balance ? (
                              <span className='error'>* {errors.opening_balance}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel>
                      <div className='wrapper'>
                        <div className='item'>
                          <div className='w-full'>
                            <label htmlFor='iban_no'>İban Numarası</label>
                            <input
                              type='text'
                              name='iban_no'
                              minLength={26}
                              className={UString.concat_class_name(
                                errors.iban_no && touched.iban_no ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='____ ____ ____ ____ ____ __'
                              value={values.iban_no}
                            />
                            {errors.iban_no && touched.iban_no ? (
                              <span className='error'>* {errors.iban_no}</span>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label htmlFor='open_account_risk_limit'>Açık Hesap Risk Limit</label>
                            <input
                              type='text'
                              name='open_account_risk_limit'
                              className={UString.concat_class_name(
                                errors.open_account_risk_limit && touched.open_account_risk_limit
                                  ? 'has-error'
                                  : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Açık Hesap Risk Limit'
                              value={values.open_account_risk_limit}
                            />
                            {errors.open_account_risk_limit && touched.open_account_risk_limit ? (
                              <span className='error'>* {errors.open_account_risk_limit}</span>
                            ) : null}
                          </div>
                          {values.card_type == 4 ? (
                            <>
                              <div className='w-full'>
                                <label htmlFor='salary'>Maaş</label>
                                <input
                                  type='text'
                                  name='salary'
                                  className={UString.concat_class_name(
                                    errors.salary && touched.salary ? 'has-error' : undefined,
                                    'my-1',
                                  )}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder='Maaş'
                                  value={values.salary}
                                />
                                {errors.salary ? (
                                  <span className='error'>* {errors.salary}</span>
                                ) : null}
                              </div>
                              <div className='w-full'>
                                <label htmlFor='payday'>Maaş Günü</label>
                                <input
                                  type='datetime-local'
                                  name='payday'
                                  className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                                    errors.payday && touched.payday ? 'has-error' : undefined,
                                    'my-1',
                                  )}`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.payday}
                                />
                              </div>
                            </>
                          ) : null}
                          <div className='w-full'>
                            <label htmlFor='fix_discount'>İndirim Oranı</label>
                            <input
                              type='text'
                              name='fix_discount'
                              className={UString.concat_class_name(
                                errors.fix_discount && touched.fix_discount
                                  ? 'has-error'
                                  : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='İndirim Oranı'
                              value={values.fix_discount}
                            />
                            {errors.fix_discount && touched.fix_discount ? (
                              <span className='error'>* {errors.fix_discount}</span>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label htmlFor='delivery_address'>Sevk Adresi</label>
                            <textarea
                              name='delivery_address'
                              className={UString.concat_class_name(
                                errors.delivery_address && touched.delivery_address
                                  ? 'has-error'
                                  : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Sevk Adresi'
                              value={values.delivery_address}
                            />
                            {errors.delivery_address && touched.delivery_address ? (
                              <span className='error'>* {errors.delivery_address}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className='item'>
                          <div className='w-full'>
                            <label htmlFor='currency'>Para Birimi</label>
                            <Listbox
                              value={values.currency}
                              onChange={(value) => setFieldValue('currency', Number(value))}
                            >
                              <div className='relative mt-1'>
                                <Listbox.Button className='listbox-btn'>
                                  <span className='listbox-title'>
                                    {(Structures.types.currency_opt as any)[values.currency]}
                                  </span>
                                  <span className='listbox-selector-icon'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      className='w-5 h-5 text-gray-400'
                                      viewBox='0 0 20 20'
                                      fill='currentColor'
                                    >
                                      <path
                                        fillRule='evenodd'
                                        d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                        clipRule='evenodd'
                                      />
                                    </svg>
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={React.Fragment}
                                  leave='transition ease-in duration-100'
                                  leaveFrom='opacity-100'
                                  leaveTo='opacity-0'
                                >
                                  <Listbox.Options className='listbox-options'>
                                    {Object.keys(Structures.types.currency_opt).map(
                                      (type, index) => (
                                        <Listbox.Option
                                          className={UString.concat_class_name(
                                            values.currency === Number(type)
                                              ? 'bg-ebony text-white'
                                              : 'hover:bg-ebony hover:text-white',
                                            'flex items-center cursor-pointer px-6 py-2',
                                          )}
                                          key={index}
                                          value={Number(type)}
                                        >
                                          <span>
                                            {(Structures.types.currency_opt as any)[type]}
                                          </span>
                                        </Listbox.Option>
                                      ),
                                    )}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          </div>
                          <div className='w-full'>
                            <label htmlFor='maturity'>Vade(Ay)</label>
                            <input
                              type='text'
                              name='maturity'
                              className={UString.concat_class_name(
                                errors.maturity && touched.maturity ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Vade'
                              value={values.maturity}
                            />
                            {errors.maturity && touched.maturity ? (
                              <span className='error'>* {errors.maturity}</span>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label htmlFor='description'>Genel Not</label>
                            <textarea
                              name='description'
                              className={UString.concat_class_name(
                                errors.description && touched.description ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Genel Not'
                              value={values.description}
                            />
                            {errors.description && touched.description ? (
                              <span className='error'>* {errors.description}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </form>
            )}
          </Formik>
        </div>
        <div className='header-bottom'>
          <button
            type='submit'
            disabled={Save.loading}
            className='ml-auto classic-btn'
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
          </button>
        </div>
      </React.Fragment>
    );
  }
  if (path == 'addPayment') {
    return (
      <React.Fragment>
        <Loading done={!Fetch.loading || !CFetch.loading || !OFetch.loading} />
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Ödeme Yap</h2>
        </div>
        <div id='content'>
          <Tab.Group>
            <Tab.List className='my-4 ml-7'>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'mr-3 ',
                    '',
                    selected
                      ? 'border-b border-red-600 text-red-600 font-bold'
                      : 'border-b border-black',
                  )
                }
              >
                Nakit
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'mr-3 ',
                    '',
                    selected
                      ? 'border-b border-red-600 text-red-600 font-bold'
                      : 'border-b border-black',
                  )
                }
              >
                Çek
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'mr-3 ',
                    '',
                    selected
                      ? 'border-b border-red-600 text-red-600 font-bold'
                      : 'border-b border-black',
                  )
                }
              >
                Senet
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <Formik
                  innerRef={Form}
                  enableReinitialize={true}
                  initialValues={{
                    in_time: CFetch.data?.in_time || String(),
                    amount: CFetch.data?.amount || Number(),
                    description: CFetch.data?.description || String(),
                    sender: CFetch.data?.sender || String(),
                    senderType: CFetch.data?.senderType || 1,
                    process_type: 1,
                    section_id: id,
                  }}
                  validationSchema={MoneyInValidation}
                  onSubmit={(values) => Save.handle(BService.deposit(values))}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form className='action' onSubmit={handleSubmit}>
                      <div className='wrapper'>
                        <div className='item'>
                          <div className='w-full'>
                            <label htmlFor='in_time'>Tarih ve Saat</label>

                            <input
                              type='datetime-local'
                              name='in_time'
                              className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                                errors.in_time && touched.in_time ? 'has-error' : undefined,
                                'my-1',
                              )}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.in_time}
                            />
                            {errors.in_time && touched.in_time ? (
                              <span className='error'>* {errors.in_time}</span>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label htmlFor='amount'>Tutar</label>
                            <input
                              type='number'
                              name='amount'
                              className={UString.concat_class_name(
                                errors.amount && touched.amount ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Tutar'
                              value={values.amount}
                            />
                            {errors.amount && touched.amount ? (
                              <span className='error'>* {errors.amount}</span>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <FormLabel id='sender-select-label'>Gönderen Tipi</FormLabel>
                            <RadioGroup
                              value={values.senderType}
                              aria-labeledBy='sender-select-label'
                              name='senderType'
                              onChange={(e: any) => {
                                handleChange(e);
                                setFieldValue('sender', '');
                              }}
                            >
                              <Stack direction='row'>
                                <FormControlLabel value={1} control={<Radio />} label='Müşteri' />
                                <FormControlLabel value={2} control={<Radio />} label='Banka' />
                                <FormControlLabel value={3} control={<Radio />} label='Kasa' />
                              </Stack>
                            </RadioGroup>
                          </div>
                          <div className='w-full'>
                            {values.senderType == 1 ? (
                              <Stack direction='column'>
                                <Autocomplete
                                  componentName='sender'
                                  options={CustomerFetch?.data
                                    ?.filter((customer: any) => customer.id != id)
                                    .map((customer: any) => ({
                                      id: customer.id,
                                      label: customer.company || customer.title,
                                    }))}
                                  getOptionLabel={(option: any) => option.label}
                                  onChange={(e: any, value: any) => {
                                    setFieldValue('sender', value?.id || '');
                                  }}
                                  clearOnEscape
                                  renderInput={(params) => (
                                    <TextField
                                      variant='outlined'
                                      {...params}
                                      label='Müşteri / Çalışan'
                                    />
                                  )}
                                />
                                {errors.sender && touched.sender ? (
                                  <span className='error'>* {errors.sender}</span>
                                ) : null}
                              </Stack>
                            ) : null}
                            {values.senderType == 2 ? (
                              <Stack direction='column'>
                                <Autocomplete
                                  componentName='sender'
                                  options={BFetch?.data?.map((bank: any) => ({
                                    id: bank._id,
                                    label: bank.account_name,
                                  }))}
                                  getOptionLabel={(option: any) => option.label}
                                  onChange={(e: any, value: any) => {
                                    setFieldValue('sender', value?.id || '');
                                  }}
                                  clearOnEscape
                                  renderInput={(params) => (
                                    <TextField
                                      variant='outlined'
                                      {...params}
                                      label='Banka Hesabı'
                                    />
                                  )}
                                />
                                {errors.sender && touched.sender ? (
                                  <span className='error'>* {errors.sender}</span>
                                ) : null}
                              </Stack>
                            ) : null}
                            {values.senderType == 3 ? (
                              <Stack direction='column'>
                                <Autocomplete
                                  componentName='sender'
                                  options={CaseFetch?.data?.map((_case: any) => _case._id)}
                                  getOptionLabel={(option: any) =>
                                    CaseFetch?.data?.find((c: any) => c._id == option)?.account_name
                                  }
                                  onChange={(e: any, value: any) => {
                                    setFieldValue('sender', value || '');
                                  }}
                                  clearOnEscape
                                  noOptionsText='Açık kasa bulunamadı'
                                  renderInput={(params) => (
                                    <TextField variant='outlined' {...params} label='Kasa' />
                                  )}
                                />
                                {errors.sender && touched.sender ? (
                                  <span className='error'>* {errors.sender}</span>
                                ) : null}
                              </Stack>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label htmlFor='description'>Açıklama</label>
                            <textarea
                              name='description'
                              className={UString.concat_class_name(
                                errors.description && touched.description ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Açıklama'
                              value={values.description}
                            />
                            {errors.description && touched.description ? (
                              <span className='error'>* {errors.description}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </Tab.Panel>
              <Tab.Panel>
                <div id='content'>
                  <Formik
                    innerRef={Form}
                    enableReinitialize={true}
                    initialValues={{
                      description: MFetch.data?.description || String(),
                      amount: MFetch.data?.amount || String(),
                      checkDate: MFetch.data?.checkDate || String(),
                      expiryDate: MFetch.data?.expiry_date || String(),
                      documentType: 1,
                      checkNo: MFetch.data?.checkNo || Number(),
                      receiverModel: 'AnalysisCustomer',
                      receiver: id,
                    }}
                    validationSchema={CheckInValidation}
                    onSubmit={(values) => Save.handle(CheckService.create(values))}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form className='action' onSubmit={handleSubmit}>
                        <div className='wrapper'>
                          <div className='item'>
                            <div className='w-full'>
                              <label htmlFor='checkDate'>Tarih ve Saat</label>
                              <input
                                type='datetime-local'
                                name='checkDate'
                                className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                                  errors.checkDate && touched.checkDate ? 'has-error' : undefined,
                                  'my-1',
                                )}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.checkDate}
                              />
                              {errors.checkDate && touched.checkDate ? (
                                <span className='error'>* {errors.checkDate}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='expiry_date'>Vade Tarihi</label>
                              <input
                                type='datetime-local'
                                name='expiryDate'
                                className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                                  errors.expiryDate && touched.expiryDate ? 'has-error' : undefined,
                                  'my-1',
                                )}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expiryDate}
                              />
                              {errors.expiryDate && touched.expiryDate ? (
                                <span className='error'>* {errors.expiryDate}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='checkNo'>Çek No</label>
                              <input
                                type='text'
                                name='checkNo'
                                className={UString.concat_class_name(
                                  errors.checkNo && touched.checkNo ? 'has-error' : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Çek No'
                                value={values.checkNo}
                              />
                              {errors.checkNo && touched.checkNo ? (
                                <span className='error'>* {errors.checkNo}</span>
                              ) : null}
                            </div>

                            <div className='w-full'>
                              <label htmlFor='amount'>Tutar</label>
                              <input
                                type='number'
                                name='amount'
                                className={UString.concat_class_name(
                                  errors.amount && touched.amount ? 'has-error' : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Tutar'
                                value={values.amount}
                              />
                              {errors.amount && touched.amount ? (
                                <span className='error'>* {errors.amount}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='description'>Açıklama</label>
                              <textarea
                                name='description'
                                className={UString.concat_class_name(
                                  errors.description && touched.description
                                    ? 'has-error'
                                    : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Açıklama'
                                value={values.description}
                              />
                              {errors.description && touched.description ? (
                                <span className='error'>* {errors.description}</span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div id='content'>
                  <Formik
                    innerRef={Form}
                    enableReinitialize={true}
                    initialValues={{
                      description: MFetch.data?.description || String(),
                      amount: MFetch.data?.amount || String(),
                      checkDate: MFetch.data?.checkDate || String(),
                      expiryDate: MFetch.data?.expiryDate || String(),
                      checkNo: MFetch.data?.checkNo || Number(),
                      documentType: 2,
                      receiverModel: 'AnalysisCustomer',
                      receiver: id,
                    }}
                    validationSchema={CheckInValidation}
                    onSubmit={(values) => Save.handle(CheckService.create(values))}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form className='action' onSubmit={handleSubmit}>
                        <div className='wrapper'>
                          <div className='item'>
                            <div className='w-full'>
                              <label htmlFor='checkDate'>Tarih ve Saat</label>
                              <input
                                type='datetime-local'
                                name='checkDate'
                                className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                                  errors.checkDate && touched.checkDate ? 'has-error' : undefined,
                                  'my-1',
                                )}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.checkDate}
                              />
                              {errors.checkDate && touched.checkDate ? (
                                <span className='error'>* {errors.checkDate}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='expiryDate'>Vade Tarihi</label>
                              <input
                                type='datetime-local'
                                name='expiryDate'
                                className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                                  errors.expiryDate && touched.expiryDate ? 'has-error' : undefined,
                                  'my-1',
                                )}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expiryDate}
                              />
                              {errors.expiryDate && touched.expiryDate ? (
                                <span className='error'>* {errors.expiryDate}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='checkNo'>Senet No</label>
                              <input
                                type='number'
                                name='checkNo'
                                className={UString.concat_class_name(
                                  errors.checkNo && touched.checkNo ? 'has-error' : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Senet No'
                                value={values.checkNo}
                              />
                              {errors.checkNo && touched.checkNo ? (
                                <span className='error'>* {errors.checkNo}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='amount'>Tutar</label>
                              <input
                                type='number'
                                name='amount'
                                className={UString.concat_class_name(
                                  errors.amount && touched.amount ? 'has-error' : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Tutar'
                                value={values.amount}
                              />
                              {errors.amount && touched.amount ? (
                                <span className='error'>* {errors.amount}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='description'>Açıklama</label>
                              <textarea
                                name='description'
                                className={UString.concat_class_name(
                                  errors.description && touched.description
                                    ? 'has-error'
                                    : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Açıklama'
                                value={values.description}
                              />
                              {errors.description && touched.description ? (
                                <span className='error'>* {errors.description}</span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div className='header-bottom'>
          <button
            type='submit'
            disabled={Save.loading}
            className='ml-auto classic-btn'
            onClick={() => {
              Form.current.handleSubmit();
            }}
          >
            {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
          </button>
        </div>
      </React.Fragment>
    );
  }
  if (path == 'addCollection') {
    return (
      <React.Fragment>
        <Loading done={!Fetch.loading || !CFetch.loading || !OFetch.loading} />
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Ödeme Al</h2>
        </div>
        <div id='content'>
          <Tab.Group>
            <Tab.List className='my-4 ml-7'>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'mr-3 ',
                    '',
                    selected
                      ? 'border-b border-red-600 text-red-600 font-bold'
                      : 'border-b border-black',
                  )
                }
              >
                Nakit
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'mr-3 ',
                    '',
                    selected
                      ? 'border-b border-red-600 text-red-600 font-bold'
                      : 'border-b border-black',
                  )
                }
              >
                Çek
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'mr-3 ',
                    '',
                    selected
                      ? 'border-b border-red-600 text-red-600 font-bold'
                      : 'border-b border-black',
                  )
                }
              >
                Senet
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <Formik
                  innerRef={Form}
                  enableReinitialize={true}
                  initialValues={{
                    out_time: OFetch.data?.out_time || String(),
                    amount: OFetch.data?.amount || String(),
                    description: OFetch.data?.description || String(),
                    receiver: CFetch.data?.sender || String(),
                    receiverType: CFetch.data?.senderType || 1,
                    process_type: 0,
                    section_id: id,
                  }}
                  validationSchema={MoneyOutValidation}
                  onSubmit={(values) => Save.handle(BService.withdraw(values))}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form className='action' onSubmit={handleSubmit}>
                      <div className='wrapper'>
                        <div className='item'>
                          <div className='w-full'>
                            <label htmlFor='out_time'>Tarih ve Saat</label>
                            <input
                              type='datetime-local'
                              name='out_time'
                              className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                                errors.out_time && touched.out_time ? 'has-error' : undefined,
                                'my-1',
                              )}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.out_time}
                            />
                            {errors.out_time && touched.out_time ? (
                              <span className='error'>* {errors.out_time}</span>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label htmlFor='amount'>Tutar</label>
                            <input
                              type='number'
                              name='amount'
                              className={UString.concat_class_name(
                                errors.amount && touched.amount ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Tutar'
                              value={values.amount}
                            />
                            {errors.amount && touched.amount ? (
                              <span className='error'>* {errors.amount}</span>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <FormLabel id='receiver-select-label'>Alıcı Tipi</FormLabel>
                            <RadioGroup
                              value={values.receiverType}
                              aria-labeledBy='receiver-select-label'
                              name='receiverType'
                              onChange={(e: any) => {
                                handleChange(e);
                                setFieldValue('receiver', '');
                              }}
                            >
                              <Stack direction='row'>
                                <FormControlLabel value={1} control={<Radio />} label='Müşteri' />
                                <FormControlLabel value={2} control={<Radio />} label='Banka' />
                                <FormControlLabel value={3} control={<Radio />} label='Kasa' />
                              </Stack>
                            </RadioGroup>
                          </div>
                          <div className='w-full'>
                            {values.receiverType == 1 ? (
                              <Stack direction='column'>
                                <Autocomplete
                                  componentName='receiver'
                                  options={CustomerFetch?.data
                                    ?.filter((customer: any) => customer.id != id)
                                    .map((customer: any) => ({
                                      id: customer.id,
                                      label: customer.company || customer.title,
                                    }))}
                                  getOptionLabel={(option: any) => option.label}
                                  onChange={(e: any, value: any) => {
                                    setFieldValue('receiver', value?.id || '');
                                  }}
                                  clearOnEscape
                                  renderInput={(params) => (
                                    <TextField
                                      variant='outlined'
                                      {...params}
                                      label='Müşteri / Çalışan'
                                    />
                                  )}
                                />
                                {errors.receiver && touched.receiver ? (
                                  <span className='error'>* {errors.receiver}</span>
                                ) : null}
                              </Stack>
                            ) : null}
                            {values.receiverType == 2 ? (
                              <Stack direction='column'>
                                <Autocomplete
                                  componentName='receiver'
                                  options={BFetch?.data?.map((bank: any) => ({
                                    id: bank._id,
                                    label: bank.account_name,
                                  }))}
                                  getOptionLabel={(option: any) => option.label}
                                  onChange={(e: any, value: any) => {
                                    setFieldValue('receiver', value?.id || '');
                                  }}
                                  clearOnEscape
                                  renderInput={(params) => (
                                    <TextField
                                      variant='outlined'
                                      {...params}
                                      label='Banka Hesabı'
                                    />
                                  )}
                                />
                                {errors.receiver && touched.receiver ? (
                                  <span className='error'>* {errors.receiver}</span>
                                ) : null}
                              </Stack>
                            ) : null}
                            {values.receiverType == 3 ? (
                              <Stack direction='column'>
                                <Autocomplete
                                  componentName='receiver'
                                  options={CaseFetch?.data?.map((_case: any) => _case?._id)}
                                  getOptionLabel={(option: any) =>
                                    CaseFetch?.data?.find((c: any) => c._id == option)?.account_name
                                  }
                                  onChange={(e: any, value: any) => {
                                    setFieldValue('receiver', value || '');
                                  }}
                                  clearOnEscape
                                  noOptionsText='Açık kasa bulunamadı'
                                  renderInput={(params) => (
                                    <TextField variant='outlined' {...params} label='Kasa' />
                                  )}
                                />
                                {errors.receiver && touched.receiver ? (
                                  <span className='error'>* {errors.receiver}</span>
                                ) : null}
                              </Stack>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label htmlFor='description'>Açıklama</label>
                            <textarea
                              name='description'
                              className={UString.concat_class_name(
                                errors.description && touched.description ? 'has-error' : undefined,
                                'my-1',
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Açıklama'
                              value={values.description}
                            />
                            {errors.description && touched.description ? (
                              <span className='error'>* {errors.description}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </Tab.Panel>
              <Tab.Panel>
                <div id='content'>
                  <Formik
                    innerRef={Form}
                    enableReinitialize={true}
                    initialValues={{
                      description: LFetch.data?.description || String(),
                      amount: LFetch.data?.amount || String(),
                      checkDate: LFetch.data?.checkDate || String(),
                      expiryDate: LFetch.data?.expiryDate || String(),
                      checkNo: LFetch.data?.checkNo || String(),
                      user: id,
                      userModel: 'AnalysisCustomer',
                      documentType: 1,
                    }}
                    validationSchema={CheckOutValidation}
                    onSubmit={(values) => Save.handle(CheckService.create(values))}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form className='action' onSubmit={handleSubmit}>
                        <div className='wrapper'>
                          <div className='item'>
                            <div className='w-full'>
                              <label htmlFor='checkDate'>Tarih ve Saat</label>
                              <input
                                type='datetime-local'
                                name='checkDate'
                                className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                                  errors.checkDate && touched.checkDate ? 'has-error' : undefined,
                                  'my-1',
                                )}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.checkDate}
                              />
                              {errors.checkDate && touched.checkDate ? (
                                <span className='error'>* {errors.checkDate}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='expiryDate'>Vade Tarihi</label>
                              <input
                                type='datetime-local'
                                name='expiryDate'
                                className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                                  errors.expiryDate && touched.expiryDate ? 'has-error' : undefined,
                                  'my-1',
                                )}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expiryDate}
                              />
                              {errors.expiryDate && touched.expiryDate ? (
                                <span className='error'>* {errors.expiryDate}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='checkNo'>Çek No</label>
                              <input
                                type='number'
                                name='checkNo'
                                className={UString.concat_class_name(
                                  errors.checkNo && touched.checkNo ? 'has-error' : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Çek No'
                                value={values.checkNo}
                              />
                              {errors.checkNo && touched.checkNo ? (
                                <span className='error'>* {errors.checkNo}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='amount'>Tutar</label>
                              <input
                                type='number'
                                name='amount'
                                className={UString.concat_class_name(
                                  errors.amount && touched.amount ? 'has-error' : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Tutar'
                                value={values.amount}
                              />
                              {errors.amount && touched.amount ? (
                                <span className='error'>* {errors.amount}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='description'>Açıklama</label>
                              <textarea
                                name='description'
                                className={UString.concat_class_name(
                                  errors.description && touched.description
                                    ? 'has-error'
                                    : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Açıklama'
                                value={values.description}
                              />
                              {errors.description && touched.description ? (
                                <span className='error'>* {errors.description}</span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div id='content'>
                  <Formik
                    innerRef={Form}
                    enableReinitialize={true}
                    initialValues={{
                      description: LFetch.data?.description || String(),
                      amount: LFetch.data?.amount || String(),
                      checkDate: LFetch.data?.checkDate || String(),
                      expiryDate: LFetch.data?.expiryDate || String(),
                      checkNo: LFetch.data?.checkNo || String(),
                      user: id,
                      userModel: 'AnalysisCustomer',
                      documentType: 2,
                    }}
                    validationSchema={CheckOutValidation}
                    onSubmit={(values) => Save.handle(CheckService.create(values))}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form className='action' onSubmit={handleSubmit}>
                        <div className='wrapper'>
                          <div className='item'>
                            <div className='w-full'>
                              <label htmlFor='checkDate'>Tarih ve Saat</label>
                              <input
                                type='datetime-local'
                                name='checkDate'
                                className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                                  errors.checkDate && touched.checkDate ? 'has-error' : undefined,
                                  'my-1',
                                )}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.checkDate}
                              />
                              {errors.checkDate && touched.checkDate ? (
                                <span className='error'>* {errors.checkDate}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='expiryDate'>Vade Tarihi</label>
                              <input
                                type='datetime-local'
                                name='expiryDate'
                                className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                                  errors.expiryDate && touched.expiryDate ? 'has-error' : undefined,
                                  'my-1',
                                )}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expiryDate}
                              />
                              {errors.expiryDate && touched.expiryDate ? (
                                <span className='error'>* {errors.expiryDate}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='checkNo'>Senet No</label>
                              <input
                                type='text'
                                name='checkNo'
                                className={UString.concat_class_name(
                                  errors.checkNo && touched.checkNo ? 'has-error' : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Senet No'
                                value={values.checkNo}
                              />
                              {errors.checkNo && touched.checkNo ? (
                                <span className='error'>* {errors.checkNo}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='amount'>Tutar</label>
                              <input
                                type='number'
                                name='amount'
                                className={UString.concat_class_name(
                                  errors.amount && touched.amount ? 'has-error' : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Tutar'
                                value={values.amount}
                              />
                              {errors.amount && touched.amount ? (
                                <span className='error'>* {errors.amount}</span>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label htmlFor='description'>Açıklama</label>
                              <textarea
                                name='description'
                                className={UString.concat_class_name(
                                  errors.description && touched.description
                                    ? 'has-error'
                                    : undefined,
                                  'my-1',
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Açıklama'
                                value={values.description}
                              />
                              {errors.description && touched.description ? (
                                <span className='error'>* {errors.description}</span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div className='header-bottom'>
          <button
            type='submit'
            disabled={Save.loading}
            className='ml-auto classic-btn'
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
          </button>
        </div>
      </React.Fragment>
    );
  }

  return <React.Fragment></React.Fragment>;
}
