import React, { useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { object, string } from "yup";

import Http from "services/http";
import Warehouse from "services/warehouse";
import Response from "services/response";

import Spinner from "components/spinner";

import UString from "utilities/string";

import { toast } from "react-toastify";

export default function () {
  const Service = new Warehouse(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => (id && Fetch.handle(Service.getById(id!)), undefined), [id]);

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(`Depo ${id ? "düzenleme" : "ekleme"} başarılı.`);
      navigate("/storage");
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(`Depo ${id ? "düzenleme" : "ekleme"} başarısız.`);
      navigate("/storage");
    }
  }, [Save.error]);

  return (
    <React.Fragment>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">
          Depo {id ? "Düzenle" : "Ekle"}
        </h2>
      </div>
      <div id="content">
        <Formik
          innerRef={Form}
          enableReinitialize={true}
          initialValues={{
            title: Fetch.data?.title || String(),
          }}
          validationSchema={object({
            title: string().required("Title is required"),
          })}
          onSubmit={(values) =>
            Save.handle(
              id && Fetch.data
                ? Service.update(id, values)
                : Service.create(values)
            )
          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form className="action" onSubmit={handleSubmit}>
              <div className="wrapper">
                <div className="item">
                  <div className="w-full">
                    <label htmlFor="title">Depo Adı</label>
                    <input
                      type="text"
                      name="title"
                      className={UString.concat_class_name(
                        errors.title && touched.title ? "has-error" : undefined,
                        "my-1"
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Depo"
                      value={values.title}
                    />
                    {errors.title && touched.title ? (
                      <span className="error">* {errors.title}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="header-bottom">
        <button
          type="submit"
          disabled={Save.loading}
          className="ml-auto classic-btn"
          onClick={() => Form.current.handleSubmit()}
        >
          {Save.loading ? <Spinner className="h-5 m-auto" /> : "Kaydet"}
        </button>
      </div>
    </React.Fragment>
  );
}
