import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Http from 'services/http';
import Invoice from 'services/invoice';
import Response from 'services/response';

import Table from 'components/table';
import Modal from 'components/modal';
import Loading from 'components/loading';
import NetworkError from 'components/network-error';
import Spinner from 'components/spinner';

import Structures from 'services/structures';
import { Menu, Transition, Tab } from '@headlessui/react';

export default function () {
  const Service = new Invoice(useContext(Http.Context)!);
  const Invoices = new Response();
  const Delete = new Response();

  const [modal, setModal] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<any | null>(null);
  const [data, setData] = useState<any[]>([]);

  useEffect(
    () => (
      Invoices?.data &&
        setData(Invoices.data?.filter((invoice: any) => invoice.invoice_type == true)),
      undefined
    ),
    [Invoices?.data],
  );
  useEffect(() => (!Invoices.data && Invoices.handle(Service.get()), undefined), [Invoices.data]);
  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading)
      setTimeout(
        () => (
          (Invoices.data = Invoices.data.filter((data: any) => data.id !== invoice.id)),
          setModal(false)
        ),
        500,
      );
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(invoice)), [invoice]);
  useEffect(
    () => (!modal && setTimeout(() => (setInvoice(null), (Delete.data = null)), 200), undefined),
    [modal],
  );

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <React.Fragment>
      <Modal onClose={() => !Delete.loading && setModal(false)} state={[modal, setModal]}>
        <React.Fragment>
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='sm:flex sm:items-start'>
              <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-red-600'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
              </div>
              <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>{invoice?.title}</h3>
                <div className='mt-2'>
                  <p className='text-sm text-gray-500'>
                    Bu Faturayı silmek istediğinize emin misiniz? Bu işlem geri alınamaz.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-actions'>
            <button
              disabled={Delete.data || Delete.loading}
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={() => Delete.handle(Service.delete(invoice.id))}
            >
              {Delete.data ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : Delete.loading ? (
                <Spinner className='h-5 m-auto' />
              ) : (
                <span>Sil</span>
              )}
            </button>
            <button
              disabled={Delete.loading}
              type='button'
              className='mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto'
              onClick={setModal.bind(null, false)}
            >
              İptal
            </button>
          </div>
        </React.Fragment>
      </Modal>
      <div className='header-top'>
        <button className='to-gray-btn'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5 xl:mr-2'
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path
              fillRule='evenodd'
              d='M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z'
              clipRule='evenodd'
            />
          </svg>
          <span className='hidden xl:block'>İçeri aktar</span>
        </button>
        <button className='to-gray-btn'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5 xl:mr-2'
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path
              fillRule='evenodd'
              d='M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
          <span className='hidden xl:block'>Dışarı aktar</span>
        </button>
        <div className='flex flex-col ml-auto relative z-10 shadow-md'>
          <Link to='/bill/action'>
            <Menu>
              <button className='flex flex-row font-medium bg-ebony text-white py-3 px-6 rounded-lg text-sm'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5 mr-2'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                    clipRule='evenodd'
                  />
                </svg>
                <span>Yeni Alış Faturası Oluştur</span>
              </button>
            </Menu>
          </Link>
        </div>
      </div>
      <div id='content'>
        <Tab.Group>
          <Tab.List className='flex space-x-2 p-4 rounded-xl bg-white max-w-md'>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-blue-600 border-2 border-blue-600'
                    : 'text-blue-600 hover:bg-blue-600 hover:text-white border-2 border-blue-600',
                )
              }
            >
              Alış Faturaları
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-red-600 border-2 border-red-600'
                    : 'text-red-600 hover:bg-red-600 hover:text-white border-2 border-red-600',
                )
              }
            >
              Alış İade Faturaları
            </Tab>
          </Tab.List>
          <Tab.Panels className='mt-2'>
            <Tab.Panel
              className={classNames(
                'rounded-xl bg-white',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none',
              )}
            >
              <Loading done={!Invoices.loading} />
              {!Invoices.loading ? (
                <NetworkError error={Invoices.error} retry={() => Invoices.handle(Service.get())} />
              ) : null}
              {Invoices.data && !Invoices.loading && !Invoices.error && (
                <Table
                  data={{
                    head: [
                      {
                        element: 'Tarih',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Açıklama',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Durum',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Fatura Sıra No',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Fatura Seri No',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Toplam Tutar',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Aksiyonlar',
                        props: { scope: 'col' },
                        screenReader: true,
                      },
                    ],
                    body: Invoices.data
                      ?.filter((invoice: any) => invoice.invoice_types == 1)
                      .map((invoice: any) => ({
                        id: invoice.id,
                        elements: [
                          {
                            element: new Date(invoice.createdAt).toLocaleDateString('tr-TR', {
                              minute: 'numeric',
                              hour: 'numeric',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            }),
                            props: {
                              className: 'text-left text-sm text-gray-900',
                            },
                          },
                          {
                            element: (
                              <div className='flex items-center'>
                                <div className='text-sm font-medium text-gray-900'>
                                  {invoice.description}
                                </div>
                              </div>
                            ),
                          },
                          {
                            element: (
                              <div className='flex items-center'>
                                {invoice?.isPaid ? (
                                  <div className='text-sm font-medium text-green-700'>Ödendi</div>
                                ) : invoice?.isCancelled ? (
                                  <div className='text-sm font-medium text-red-600'>
                                    İade Edildi
                                  </div>
                                ) : (
                                  <div className='text-sm font-medium text-orange-600'>
                                    Ödeme Bekleniyor
                                  </div>
                                )}
                              </div>
                            ),
                          },
                          {
                            element: (
                              <div className='flex items-center'>
                                <div className='text-sm font-medium text-gray-900'>
                                  {invoice.invoice_code}
                                </div>
                              </div>
                            ),
                          },
                          {
                            element: (
                              <div className='flex items-center'>
                                <div className='text-sm font-medium text-gray-900'>
                                  {invoice.invoice_no}
                                </div>
                              </div>
                            ),
                          },
                          {
                            element: (
                              <div className='flex items-center'>
                                <div className='text-sm font-medium text-gray-900'>
                                  {invoice.total_amount +
                                    ' ' +
                                    (Structures.types.currency_opt as any)[invoice.currency]}
                                </div>
                              </div>
                            ),
                          },
                          {
                            element: (
                              <div
                                className='space-x-4'
                                style={{
                                  width: '20vw',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                {!invoice.isCancelled && !invoice.isPaid ? (
                                  <Link
                                    to={`/bill/action/${invoice.id}`}
                                    className='text-indigo-600 hover:text-indigo-900'
                                  >
                                    Düzenle
                                  </Link>
                                ) : null}
                                {!invoice.isCancelled && !invoice.isPaid ? (
                                  <Link
                                    to={`/bill/action/rebate/${invoice.id}/create`}
                                    className='text-orange-600 hover:text-orange-900'
                                  >
                                    İade Oluştur
                                  </Link>
                                ) : null}
                                <button
                                  onClick={setInvoice.bind(null, invoice)}
                                  className='text-red-600 hover:text-red-900'
                                >
                                  Sil
                                </button>
                              </div>
                            ),
                            props: {
                              className: 'text-right text-sm font-medium space-x-1',
                            },
                          },
                        ],
                      })),
                  }}
                />
              )}
            </Tab.Panel>
            <Tab.Panel
              className={classNames(
                'rounded-xl bg-white',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none',
              )}
            >
              <Loading done={!Invoices.loading} />
              {!Invoices.loading ? (
                <NetworkError error={Invoices.error} retry={() => Invoices.handle(Service.get())} />
              ) : null}
              {Invoices.data && !Invoices.loading && !Invoices.error && (
                <Table
                  data={{
                    head: [
                      {
                        element: 'Tarih',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Açıklama',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Fatura Sıra No',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Fatura Seri No',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Toplam Tutar',
                        props: { scope: 'col' },
                      },
                      {
                        element: 'Aksiyonlar',
                        props: { scope: 'col' },
                        screenReader: true,
                      },
                    ],
                    body: Invoices.data
                      ?.filter((invoice: any) => invoice.invoice_types == 2)
                      .map((invoice: any) => ({
                        id: invoice.id,
                        elements: [
                          {
                            element: new Date(invoice.createdAt).toLocaleDateString('tr-TR', {
                              minute: 'numeric',
                              hour: 'numeric',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            }),
                            props: {
                              className: 'text-left text-sm text-gray-900',
                            },
                          },
                          {
                            element: (
                              <div className='flex items-center'>
                                <div className='text-sm font-medium text-gray-900'>
                                  {invoice.description}
                                </div>
                              </div>
                            ),
                          },

                          {
                            element: (
                              <div className='flex items-center'>
                                <div className='text-sm font-medium text-gray-900'>
                                  {invoice.invoice_code}
                                </div>
                              </div>
                            ),
                          },
                          {
                            element: (
                              <div className='flex items-center'>
                                <div className='text-sm font-medium text-gray-900'>
                                  {invoice.invoice_no}
                                </div>
                              </div>
                            ),
                          },
                          {
                            element: (
                              <div className='flex items-center'>
                                <div className='text-sm font-medium text-gray-900'>
                                  {invoice.total_amount +
                                    ' ' +
                                    (Structures.types.currency_opt as any)[invoice.currency]}
                                </div>
                              </div>
                            ),
                          },
                          {
                            element: (
                              <>
                                <Link
                                  to={`/bill/action/rebate/${invoice.id}/edit`}
                                  className='text-indigo-600 hover:text-indigo-900'
                                >
                                  Düzenle
                                </Link>
                                <button
                                  onClick={setInvoice.bind(null, invoice)}
                                  className='text-red-600 hover:text-red-900'
                                >
                                  Sil
                                </button>
                              </>
                            ),
                            props: {
                              className: 'text-right text-sm font-medium space-x-6',
                            },
                          },
                        ],
                      })),
                  }}
                />
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </React.Fragment>
  );
}
