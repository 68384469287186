import TableRow, { TableRowProps } from '@mui/material/TableRow';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { green, red, orange } from '@mui/material/colors';

interface StyledTableRowProps extends TableRowProps {
  isPaid: boolean;
}

interface CheckTableRowProps extends TableRowProps {
  isBounced: boolean;
  isDone: boolean;
}

export const InvoiceTableRow = styled(TableRow)<StyledTableRowProps>(({ isPaid, theme }) => ({
  backgroundColor: isPaid ? green[900] : red[900],
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: isPaid ? green[700] : red[700],
  },
  '.MuiTableCell-root': {
    color: '#fff',
    fontWeight: 'bold',
  },
}));

export const CheckTableRow = styled(TableRow)<CheckTableRowProps>(({ isBounced, isDone }) => ({
  backgroundColor: isBounced ? red[900] : isDone ? green[900] : orange[900],
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: isBounced ? red[700] : isDone ? green[700] : orange[700],
  },
  '.MuiTableCell-root': {
    color: '#fff',
    fontWeight: 'bold',
  },
}));

export const BoldTableCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1vw',
}));

export const ResponsiveTableCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  fontSize: '1vw',
}));

export const ResponsiveTableRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  height: '4vw',
  width: '100%',
}));
