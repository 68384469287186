import { useEffect, useContext, useState, useRef } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Select,
  Stack,
  Switch,
  Typography,
  MenuItem,
} from '@mui/material';
import { ResponsiveTypography } from 'assets/data-display';
import { Tab } from '@headlessui/react';

import Http from 'services/http';
import Recipe from 'services/recipe';
import Ingredient from 'services/ingradient';
import Warehouse from 'services/warehouse';

import Response from 'services/response';
import Loading from 'components/loading';
import Spinner from 'components/spinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import clone from 'clone';

export default function posSettings() {
  const page = useRef(1);
  const isActive = useRef('all');
  const [recipeData, setRecipeData] = useState<any[]>([]);
  const [recipeCount, setRecipeCount] = useState(0);

  const RService = new Recipe(useContext(Http.Context)!);
  const RFetch = new Response();

  const IService = new Ingredient(useContext(Http.Context)!);
  const IFetch = new Response();

  const WService = new Warehouse(useContext(Http.Context)!);
  const WFetch = new Response();

  useEffect(() => (IFetch.handle(IService.get()), undefined), []);
  useEffect(() => (WFetch.handle(WService.get()), undefined), []);

  const fetchMoreData = () => {
    RFetch.handle(RService.getWithPagination(page.current - 1, 9, isActive.current));
  };

  const loadMoreData = async () => {
    page.current++;
    let data: any = await RService.getWithPagination(page.current - 1, 9, isActive.current);
    setRecipeData([...recipeData, ...data?.data?.data]);
    setRecipeCount(data?.data?.totalCount);
  };

  useEffect(() => fetchMoreData(), []);

  useEffect(() => {
    if (RFetch.data) {
      setRecipeData([...recipeData, ...RFetch?.data?.data]);
      setRecipeCount(RFetch?.data?.totalCount);
    }
  }, [RFetch?.data]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  const handleStockControlChange = (recipeId: string, value: any) => {
    RService.changeStockControl(recipeId, Number(value));
    let recipes = clone(recipeData);
    let findIndex = recipes.findIndex((r: any) => r._id == recipeId);
    if (findIndex > -1) {
      recipes[findIndex].isActive = value;
      setRecipeData(recipes);
    }
  };

  const handleWarehouseChange = (stock: string, warehouse: string) => {
    let find = IFetch.data?.find((i: any) => i.id == stock);
    if (!find) return;
    IFetch.data
      ?.filter((i: any) => i.title == find.title)
      .map((i: any) => (i.mainWarehouse = warehouse));

    IService.changeMainWarehouse(stock, warehouse);
  };

  return (
    <Box sx={{ overflowY: 'scroll' }}>
      <Box sx={{ padding: '1vw' }}>
        <ResponsiveTypography fontWeight='bold' variant='h4' sx={{ fontSize: '2.1vw' }}>
          Pos Ayarları
        </ResponsiveTypography>
      </Box>
      <hr />
      <Tab.Group>
        <Box sx={{ margin: 'auto', marginTop: '1.5vw', width: '50%' }}>
          <Tab.List className='flex space-x-2 p-4 rounded-xl bg-white max-w-md'>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-green-600 border-2 border-green-600'
                    : 'text-green-600 hover:bg-green-600 hover:text-white border-2 border-green-600',
                )
              }
            >
              Stok Ayarları
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-blue-600 border-2 border-blue-600'
                    : 'text-blue-600 hover:bg-blue-600 hover:text-white border-2 border-blue-600',
                )
              }
            >
              Depo Ayarları
            </Tab>
          </Tab.List>
        </Box>
        <Tab.Panels className='mt-2'>
          <Tab.Panel>
            <InfiniteScroll
              dataLength={recipeData.length || 0}
              next={loadMoreData}
              loader={<Box sx={{ display: 'flex', flexDirection: 'column' }}>Yükleniyor...</Box>}
              hasMore={recipeData?.length < recipeCount}
              style={{
                padding: '1vw',
              }}
            >
              <Grid
                container
                spacing={{ xs: 1, md: 1 }}
                columns={{ xs: 1, sm: 1, md: 12 }}
                sx={{ height: '28vw', overflowY: 'scroll' }}
              >
                {RFetch.data &&
                  recipeData?.map((recipe: any, index: number) => (
                    <Grid sm={1} xs={1} md={4} key={index}>
                      <Paper elevation={4} sx={{ width: '25vw', margin: '0.2vw' }}>
                        <Stack
                          direction='column'
                          spacing={1}
                          sx={{ padding: '1vw' }}
                          alignItems='center'
                        >
                          <Typography fontSize='1.3vw'>
                            <strong>Reçete: </strong>
                            {recipe.title}
                          </Typography>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={recipe.isActive}
                                onChange={(e: any, checked: boolean) =>
                                  handleStockControlChange(recipe._id, checked)
                                }
                              />
                            }
                            label={
                              <Typography sx={{ fontWeight: 'bold', fontSize: '1.1vw' }}>
                                Stok Kontrolü:
                              </Typography>
                            }
                            labelPlacement='start'
                          />
                        </Stack>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            </InfiniteScroll>
          </Tab.Panel>
          <Tab.Panel>
            <Grid container spacing={{ cs: 1, md: 1 }} columns={{ xs: 1, sm: 1, md: 12 }}>
              {IFetch.data &&
                IFetch.data?.map((stock: any, index: number) => (
                  <Grid sm={1} xs={1} md={4} key={index}>
                    <Paper elevation={4} sx={{ width: '25vw', margin: '0.2vw' }}>
                      <Stack
                        direction='column'
                        spacing={1}
                        sx={{ padding: '1vw' }}
                        alignItems='center'
                      >
                        <Typography fontSize='1.1vw'>
                          <strong>Stok: </strong>
                          {stock.title}
                        </Typography>
                        <FormControlLabel
                          control={
                            <Select
                              value={stock.mainWarehouse}
                              onChange={(e: any) => handleWarehouseChange(stock.id, e.target.value)}
                              size='small'
                              sx={{ width: '12vw', fontSize: '1.1vw' }}
                            >
                              {WFetch.data &&
                                WFetch.data?.map((warehouse: any) => (
                                  <MenuItem value={warehouse.id}>{warehouse.title}</MenuItem>
                                ))}
                            </Select>
                          }
                          label={
                            <Typography
                              sx={{ fontSize: '1.1vw', fontWeight: 'bold', marginRight: '0.4vw' }}
                            >
                              Depo:
                            </Typography>
                          }
                          labelPlacement='start'
                        />
                      </Stack>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Box>
  );
}
