import Structures from 'services/structures';

export default function ShowSum({ name, data, type }: { name: string; data: any; type?: string }) {
  return (
    <div className='bg-white p-4 border border-gray-200 rounded-lg col-span-3 xl:col-span-1'>
      <div>
        <span className='text-xs font-medium text-gray-500 uppercase'>{name}</span>
        {!type ? (
          (data?.every((i: any) => i == 0) ? [0] : data)?.map((d: any, i: number) => {
            return (
              ((data?.every((i: any) => i == 0) ? [0] : data).length == 1 || d != 0) && (
                <div key={i} className='text-xl flex items-center space-x-2'>
                  <h3 className='font-medium text-gray-900'>{d?.toFixed(2)}</h3>
                  <span className='font-medium text-gray-900'>
                    {(Structures.types.currency_opt as any)[i + 1]}
                  </span>
                </div>
              )
            );
          })
        ) : (
          <div className='text-xl flex items-center space-x-2'>
            <h3 className='font-medium text-gray-900'>{data?.toFixed(2)}</h3>
            <span className='font-medium text-gray-900'>{type}</span>
          </div>
        )}
      </div>
    </div>
  );
}
