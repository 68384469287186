import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Listbox } from '@headlessui/react';
import Http from 'services/http';
import Ingredient from 'services/ingradient';
import Warehouse from 'services/warehouse';
import Response from 'services/response';
import Product from 'services/product';

import Loading from 'components/loading';
import Spinner from 'components/spinner';
import UString from 'utilities/string';
import Ingradient from 'services/ingradient';
import { IngredientValidation } from 'validations';

import { toast } from 'react-toastify';
import { FormHelperText, MenuItem, Select, Stack, TextField } from '@mui/material';

export default function () {
  const Service = new Ingredient(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();

  const CService = new Warehouse(useContext(Http.Context)!);
  const CFetch = new Response<any[]>();

  const OService = new Ingradient(useContext(Http.Context)!);
  const OFetch = new Response();

  const PService = new Product(useContext(Http.Context)!);
  const PFetch = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();

  const { id } = useParams();
  const [products, setProducts]: any[] = useState(null);
  const [page, setPage] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedProduct, setSelectedProduct]: any = useState(null);
  const [selectedProductId, setSelectedProductId] = useState('');

  useEffect(() => (id && OFetch.handle(Service.getById(id!)), undefined), [id]);

  useEffect(() => (!CFetch.data && CFetch.handle(CService.get()), undefined), [CFetch.data]);
  useEffect(
    () => (!PFetch.data && PFetch.handle(PService.getByFilter(0, 10, undefined)), undefined),
    [PFetch.data],
  );

  useEffect(() => PFetch.data && setProducts(PFetch.data?.data), [PFetch.data]);
  useEffect(() => PFetch.data && setTotalProduct(PFetch.data?.totalCount), [PFetch.data]);
  useEffect(() => (!OFetch.data && OFetch.handle(OService.get()), undefined), [OFetch.data]);

  useEffect(() => (id && Fetch.handle(Service.getById(id!)), undefined), [id]);

  useEffect(() => Fetch.data && setSelectedProductId(Fetch.data?.product), [Fetch.data]);

  useEffect(
    () =>
      Fetch.data &&
      PFetch.data &&
      setSelectedProduct(
        PFetch.data?.data?.find((p: any) => p._id?.toString() == selectedProductId),
      ),
    [Fetch.data && PFetch.data],
  );

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(`Stok ${id ? 'düzenleme' : 'ekleme'} başarılı.`);
      navigate('/stock');
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(`Stok ${id ? 'düzenleme' : 'ekleme'} başarısız.`);
      navigate('/stock');
    }
  }, [Save.error]);

  const fetchMoreProduct = async () => {
    let p = await PService.getByFilter(page + 1, 10, search);
    setPage(page + 1);
    setProducts(products.concat(p?.data?.data));
    setTotalProduct(p?.data?.totalCount || 0);
  };

  const fetchProductByFilter = async () => {
    setSelectedProduct(null);
    setSelectedProductId('');
    setProducts(null);
    let p = await PService.getByFilter(0, 10, search);
    setPage(0);
    setProducts(p?.data?.data);
    setTotalProduct(p?.data?.totalCount);
  };

  enum ShelfLifeTypeEnum {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
  }

  const ingredient_unit_types: any = {
    1: 'Kilogram',
    2: 'Gram',
    3: 'Litre',
    4: 'Mililitre',
    5: 'Adet',
    6: 'Bağ',
    7: 'Çuval',
    8: 'Deste',
    9: 'Düzine',
    10: 'Dilim',
    11: 'Kasa',
    12: 'Koli',
    13: 'Metre',
  };

  const stock_types: any = {
    1: 'Ticari Mal',
    2: 'Yarı Mamül',
    3: 'Hizmet',
    4: 'Demirbaş',
  };

  const getStringForShelfLife = (type: string) => {
    switch (type) {
      case 'DAY':
        return 'Gün';
      case 'WEEK':
        return 'Hafta';
      case 'MONTH':
        return 'Ay';
      case 'YEAR':
        return 'Yıl';
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Loading done={!Fetch.loading || !CFetch.loading || !OFetch.loading} />
      <div className='header-top'>
        <h2 className='text-xl font-medium pl-2'>Stok {id ? 'Düzenle' : 'Ekle'}</h2>
      </div>

      <div id='content'>
        <Formik
          innerRef={Form}
          enableReinitialize={true}
          initialValues={{
            title: Fetch.data?.title || String(),
            stock_code: Fetch.data?.stock_code || String(),
            stock_group: Fetch.data?.stock_group || 1,
            product_amount: Fetch.data?.product_amount || Number(),
            unit_1: Fetch.data?.unit_1 || String(),
            unit_2: Fetch.data?.unit_2
              ? {
                  unit: Fetch.data?.unit_2?.unit,
                  value: Fetch.data?.unit_2?.value,
                }
              : {
                  unit: String(),
                  value: Number(),
                },
            vat_rate: Fetch.data?.vat_rate || Number(),
            critical_stock_quantity: Fetch.data?.critical_stock_quantity || Number(),
            optimal_stock_quantity: Fetch.data?.optimal_stock_quantity || Number(),
            purchase_discount: Fetch.data?.purchase_discount || Number(),
            sale_discount: Fetch.data?.sale_discount || Number(),
            shelf_life: Fetch.data?.shelf_life || Number(),
            shelf_life_type: Fetch.data?.shelf_life_type || 'DAY',
            warehouse: Fetch.data?.warehouse || CFetch.data?.[0]?.id || String(),
          }}
          validationSchema={IngredientValidation}
          onSubmit={(values) =>
            Save.handle(
              id && Fetch.data
                ? Service.update(id, { ...Fetch.data, ...values })
                : Service.create(values),
            )
          }
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form className='action' onSubmit={handleSubmit}>
              <Stack direction='row' justifyContent='space-around'>
                <div className='item'>
                  <div className='w-full'>
                    <label htmlFor='title'>Başlık</label>
                    <input
                      type='text'
                      name='title'
                      className={UString.concat_class_name(
                        errors.title && touched.title ? 'has-error' : undefined,
                        'my-1',
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Başlık'
                      value={values.title}
                    />
                    {errors.title && touched.title ? (
                      <span className='error'>* {errors.title}</span>
                    ) : null}
                  </div>
                  <div className='w-full'>
                    <label htmlFor='title'>Satış Fiyatı</label>
                    <input
                      type='text'
                      name='product_amount'
                      className={UString.concat_class_name(
                        errors.product_amount && touched.product_amount ? 'has-error' : undefined,
                        'my-1',
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Satış Fiyatı'
                      value={values.product_amount}
                    />
                    {errors.product_amount && touched.product_amount ? (
                      <span className='error'>* {errors.product_amount}</span>
                    ) : null}
                  </div>
                  <div className='w-full'>
                    <Stack spacing={0.5}>
                      <label htmlFor='title'>Stok Tipi</label>
                      <Select
                        value={values.stock_group}
                        onChange={handleChange}
                        name='stock_group'
                        size='small'
                      >
                        {(Object.values(stock_types) as (keyof typeof stock_types)[]).map(
                          (type: any, index: number) => (
                            <MenuItem value={index + 1}>{type}</MenuItem>
                          ),
                        )}
                      </Select>
                    </Stack>
                    {errors.stock_group && touched.stock_group ? (
                      <span className='error'>* {errors.stock_group}</span>
                    ) : null}
                  </div>
                  <div className='w-full'>
                    <Stack direction='column' spacing={0.5}>
                      <label htmlFor='stock'>Birim 1</label>
                      <Select
                        size='small'
                        value={values.unit_1}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name='unit_1'
                      >
                        {(
                          Object.values(
                            ingredient_unit_types,
                          ) as (keyof typeof ingredient_unit_types)[]
                        ).map((type: any, index: number) => (
                          <MenuItem value={index + 1}>{type}</MenuItem>
                        ))}
                      </Select>
                      {errors.unit_1 && touched.unit_1 ? (
                        <span className='error'>{errors.unit_1}</span>
                      ) : null}
                    </Stack>
                  </div>
                  <div className='w-full'>
                    <Stack spacing={0.5}>
                      <label htmlFor='title'>Birim 2</label>
                      <Stack direction='row' spacing={1}>
                        <Select
                          sx={{ width: '8vw' }}
                          size='small'
                          name='unit_2.unit'
                          value={values.unit_2.unit}
                          onChange={(e: any) =>
                            setFieldValue('unit_2.unit', parseInt(e.target.value))
                          }
                        >
                          {(
                            Object.values(
                              ingredient_unit_types,
                            ) as (keyof typeof ingredient_unit_types)[]
                          ).map((type: any, index: number) => (
                            <MenuItem value={index + 1}>{type}</MenuItem>
                          ))}
                        </Select>
                        <TextField
                          type='number'
                          name='unit_2.value'
                          label='Değer'
                          size='small'
                          value={values.unit_2.value}
                          onChange={(e: any) =>
                            setFieldValue('unit_2.value', parseInt(e.target.value))
                          }
                        />
                        <FormHelperText>
                          Birim 2'nin birim 1 bazında değeri. <br /> Örneğin 1 adet = 200gr
                        </FormHelperText>
                      </Stack>
                      {errors.unit_2 && touched.unit_2 ? (
                        <span className='error'>{errors.unit_2}</span>
                      ) : null}
                    </Stack>
                  </div>
                  <div className='w-full'>
                    <label htmlFor='stock'>Raf Ömrü</label>
                    <input
                      type='number'
                      name='shelf_life'
                      className={UString.concat_class_name(
                        errors.shelf_life && touched.shelf_life ? 'has-error' : undefined,
                        'my-1',
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Raf Ömrü'
                      value={values.shelf_life}
                    />
                    {errors.shelf_life && touched.shelf_life ? (
                      <span className='error'>{errors.shelf_life}</span>
                    ) : null}
                  </div>
                  <div className='w-full'>
                    <Stack spacing={0.5}>
                      <label htmlFor='shelf_life_type' id='shelflifetype'>
                        Raf Ömrü Tipi
                      </label>
                      <Select
                        labelId='shelflifetype'
                        name='shelf_life_type'
                        value={values.shelf_life_type}
                        size='small'
                        onChange={handleChange}
                      >
                        {(Object.keys(ShelfLifeTypeEnum) as (keyof typeof ShelfLifeTypeEnum)[]).map(
                          (type: string) => (
                            <MenuItem value={type}>{getStringForShelfLife(type)}</MenuItem>
                          ),
                        )}
                      </Select>
                    </Stack>
                    {errors.shelf_life_type && touched.shelf_life_type ? (
                      <span className='error'>{errors.shelf_life_type}</span>
                    ) : null}
                  </div>
                  <div className='relative w-full'>
                    <label>Depolar</label>
                    {CFetch.data ? (
                      <Listbox
                        value={values.warehouse}
                        onChange={(value: any) => setFieldValue('warehouse', value)}
                      >
                        <div className='relative mt-1'>
                          <Listbox.Button className='listbox-btn'>
                            <span className='listbox-title'>
                              {
                                CFetch.data.find((warehouse) => warehouse.id === values.warehouse)
                                  ?.title
                              }
                            </span>
                            <span className='listbox-selector-icon'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='w-5 h-5 text-gray-400'
                                viewBox='0 0 20 20'
                                fill='currentColor'
                              >
                                <path
                                  fillRule='evenodd'
                                  d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                  clipRule='evenodd'
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                          <Listbox.Options className='listbox-options'>
                            {CFetch?.data &&
                              CFetch?.data?.map((warehouse, index) => (
                                <Listbox.Option
                                  className={UString.concat_class_name(
                                    values.warehouse === warehouse.id
                                      ? 'bg-ebony text-white'
                                      : 'hover:bg-ebony hover:text-white',
                                    'flex items-center cursor-pointer px-6 py-2',
                                  )}
                                  key={index}
                                  value={warehouse.id}
                                >
                                  <span>{warehouse.title}</span>
                                </Listbox.Option>
                              ))}
                          </Listbox.Options>
                        </div>
                      </Listbox>
                    ) : (
                      <div className='classic-btn w-full min-h-[38px] mt-1'>
                        <Spinner className='w-5 m-auto' />
                      </div>
                    )}
                  </div>
                </div>
                <div className='item'>
                  <div className='w-full'>
                    <label htmlFor='purchase_amount'>Stok Kodu</label>
                    <input
                      type='text'
                      name='stock_code'
                      className={UString.concat_class_name(
                        errors.stock_code && touched.stock_code ? 'has-error' : undefined,
                        'my-1',
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Stok Kodu'
                      value={values.stock_code}
                    />
                    {errors.stock_code && touched.stock_code ? (
                      <span className='error'>{errors.stock_code}</span>
                    ) : null}
                  </div>
                  <div className='w-full'>
                    <label htmlFor='title'>KDV Oranı</label>
                    <input
                      type='text'
                      name='vat_rate'
                      className={UString.concat_class_name(
                        errors.vat_rate && touched.vat_rate ? 'has-error' : undefined,
                        'my-1',
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='KDV Oranı'
                      value={values.vat_rate}
                    />
                    {errors.vat_rate && touched.vat_rate ? (
                      <span className='error'>{errors.vat_rate}</span>
                    ) : null}
                  </div>
                  <div className='w-full'>
                    <label htmlFor='title'>Kritik Stok Miktarı</label>
                    <input
                      type='text'
                      name='critical_stock_quantity'
                      className={UString.concat_class_name(
                        errors.critical_stock_quantity && touched.critical_stock_quantity
                          ? 'has-error'
                          : undefined,
                        'my-1',
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Kritik Stok Miktarı'
                      value={values.critical_stock_quantity}
                    />
                    {errors.critical_stock_quantity && touched.critical_stock_quantity ? (
                      <span className='error'>{errors.critical_stock_quantity}</span>
                    ) : null}
                  </div>
                  <div className='w-full'>
                    <label htmlFor='title'>Optimum Stok Miktarı</label>
                    <input
                      type='text'
                      name='optimal_stock_quantity'
                      className={UString.concat_class_name(
                        errors.optimal_stock_quantity && touched.optimal_stock_quantity
                          ? 'has-error'
                          : undefined,
                        'my-1',
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Optimal Stok Miktarı'
                      value={values.optimal_stock_quantity}
                    />
                    {errors.optimal_stock_quantity && touched.optimal_stock_quantity ? (
                      <span className='error'>{errors.optimal_stock_quantity}</span>
                    ) : null}
                  </div>
                  <div className='w-full'>
                    <label htmlFor='title'>Alış İskontosu</label>
                    <input
                      type='text'
                      name='purchase_discount'
                      className={UString.concat_class_name(
                        errors.purchase_discount && touched.purchase_discount
                          ? 'has-error'
                          : undefined,
                        'my-1',
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Alış İskontosu'
                      value={values.purchase_discount}
                    />
                    {errors.purchase_discount && touched.purchase_discount ? (
                      <span className='error'>{errors.purchase_discount}</span>
                    ) : null}
                  </div>
                  <div className='w-full'>
                    <label htmlFor='title'>Satış İskontusu</label>
                    <input
                      type='text'
                      name='sale_discount'
                      className={UString.concat_class_name(
                        errors.sale_discount && touched.sale_discount ? 'has-error' : undefined,
                        'my-1',
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Satış İskontosu'
                      value={values.sale_discount}
                    />
                    {errors.sale_discount && touched.sale_discount ? (
                      <span className='error'>{errors.sale_discount}</span>
                    ) : null}
                  </div>
                </div>
              </Stack>
            </form>
          )}
        </Formik>
      </div>
      <div className='header-bottom'>
        <button
          type='submit'
          disabled={Save.loading}
          className='ml-auto classic-btn'
          onClick={() => Form.current.handleSubmit()}
        >
          {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
        </button>
      </div>
    </React.Fragment>
  );
  return <React.Fragment></React.Fragment>;
}
