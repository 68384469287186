import { object, string, number, date, boolean, array } from 'yup';

export const InvoiceValidation = object({
  invoice_code: string().required("'invoice_code' is required"),
  invoice_no: string(),
  invoice_type: boolean().required("'invoice_type' is required"),
  invoice_types: number().required("'invoice_types' is required"),
  customer: string().required('Müşteri zorunludur'),
  custotmerType: string(),
  currency: number().required('Para birimi zorunludur'),
  pay_type: number().required('Ödeme yöntemi zorunludur'),
  total_amount: number().when('customerType', {
    is: 2,
    then: number().required('Toplam tutar zorunludur'),
  }),
  invoice_time: date().required('Tarih zorunludur'),
  expiry_date: date().required('Son ödeme tarihi zorunludur'),
  description: string(),
  detail: array().of(
    object({
      product_service: string().required('Ürün zorunludur'),
      amount: number().required('Tutar zorunludur'),
      quantity: number().min(0, "Miktar 0'dan küçük olamaz").required('Miktar zorunludur'),
      vat: number(),
      discount: number(),
      total: number().required('Toplam zorunludur'),
      quantity_type: number().required('Tip zorunludur'),
      warehouse: string().required('Depo zorunludur'),
      productType: number(),
    }),
  ),
});

export const RebateInvoiceValidation = object({
  invoice_code: string().required("'invoice_code' is required"),
  invoice_no: string(),
  rebatedInvoiceCode: string().required('İade edilecek fatura numarası zorunludur'),
  invoice_type: boolean().required("'invoice_type' is required"),
  invoice_types: number().required("'invoice_types' is required"),
  customer: string().required('Müşteri zorunludur'),
  custotmerType: string(),
  currency: number().required('Para birimi zorunludur'),
  pay_type: number().required('Ödeme yöntemi zorunludur'),
  total_amount: number().when('customerType', {
    is: 2,
    then: number().required('Toplam tutar zorunludur'),
  }),
  invoice_time: date().required('Tarih zorunludur'),
  expiry_date: date().required('Son ödeme tarihi zorunludur'),
  description: string(),
  detail: array().of(
    object({
      product_service: string().required('Ürün zorunludur'),
      amount: number().required('Tutar zorunludur'),
      quantity: number().min(0, "Miktar 0'dan küçük olamaz").required('Miktar zorunludur'),
      vat: number(),
      discount: number(),
      total: number().required('Toplam zorunludur'),
      quantity_type: number().required('Tip zorunludur'),
      warehouse: string().required('Depo zorunludur'),
      productType: number(),
    }),
  ),
});

export const CaseValidation = object({
  account_name: string().required("'account_name' is required"),
  balance: array()
    .of(
      object({
        amount: number().required("'amount' is required"),
        currency: number().required("'currency' is required"),
      }).required(),
    )
    .required(),
});

export const MoneyInValidation = object({
  in_time: date().required("'tarih' zorunludur"),
  amount: number().required("'tutar' zorunludur"),
  description: string().required("'açıklama' zorunludur"),
  senderType: number().default(1),
  sender: string().required("'gönderici' zorunludur"),
});

export const MoneyOutValidation = object({
  out_time: date().required("'tarih' zorunludur"),
  amount: number().required("'tutar' zorunludur"),
  description: string().required("'açıklama' zorunludur"),
  senderType: number().default(1),
  receiver: string().required("'alıcı' zorunludur"),
});

export const BankValidation = object({
  account_name: string().required("'account_name' is required"),
  bank_name: string(),
  iban_no: string().min(26, 'iban_no is too short - should be 26 chars minimum.'),
  bank_branch: string(),
  account_number: number(),
  balance: array()
    .of(
      object({
        type: number().required('Type is required'),
        amount: number().required('Amount is required'),
        currency: number().required("'currency' is required"),
      }).required(),
    )
    .required(),
});

export const CustomerValidation = object({
  title: string().required("'title' is required"),
  card_type: number().required("'card_type' is required"),
  company_type: number().required("'company_type' is required"),
  address: string().min(10, 'addres is too short - should be 10 chars minimum.'),
  number: number(),
  country: string(),
  city: string(),
  district: string(),
  code: string().required("'code' is required"),
  iban_no: string().min(26, 'iban_no is too short - should be 26 chars minimum.'),
  company: string(),
  delivery_address: string().min(10, 'addres is too short - should be 10 chars minimum.'),
  e_mail: string().email('Must be a valid email'),
  maturity: number(),
  tax_office: string(),
  identity_no: number(),
  open_account_risk_limit: number(),
  fix_discount: number(),
  currency: number(),
  opening_balance: number(),
  description: string(),
  salary: number(),
  payday: date(),
});

export const CheckInValidation = object({
  description: string().required("'description' is required"),
  amount: number().required("'amount' is required"),
  checkDate: date().required("'check_time' is required"),
  expiryDate: date().required("'expiry_date' is required"),
  checkNo: number().typeError('Sayı olmak zorunda').required("'check_no' is required"),
  receiver: string().required("'receiver' is required"),
  receiverModel: string().required("'receiver model' is required"),
  documentType: number().required("'document type' is required"),
});

export const CheckOutValidation = object({
  description: string().required("'description' is required"),
  amount: number().required("'amount' is required"),
  checkDate: date().required("'check date' is required"),
  expiryDate: date().required("'expiry_date' is required"),
  checkNo: number().typeError('Sayı olmak zorunda').required("'check_no' is required"),
  user: string().required("'user' is required"),
  userModel: string().required("'user model' is required"),
  documentType: number().required("'document type' is required"),
});

export const IngredientValidation = object({
  title: string().required('Başlık gereklidir'),
  stock_code: string().required('Stok kodu gereklidir'),
  stock_group: string().required('Stok grubu gereklidir'),
  product_amount: number().required('Satış fiyatı gereklidir'),
  unit_1: number().required('Birim gereklidir'),
  unit_2: object({
    unit: number().required('Birim 2 nin tipi gereklidir'),
    value: number()
      .typeError('Değer sayı olmalıdır')
      .required('Birim 2 nin değeri gereklidir')
      .min(1, "Değer 1'den küçük olamaz"),
  }).required('Birim 2 gereklidir'),
  vat_rate: number().typeError('KDV oranı sayı olmalıdır').required('KDV oranı gereklidir'),
  critical_stock_quantity: number().typeError('Kritik stok miktarı sayı olmalıdır'),
  optimal_stock_quantity: number().typeError('Optimum stok miktarı sayı olmalıdır'),
  purchase_discount: number().typeError('Alış indirimi sayı olmalıdır'),
  sale_discount: number().typeError('Satış indirimi sayı olmalıdır'),
  shelf_life: number().typeError('Raf ömrü sayı olmalıdır'),
  shelf_life_type: string(),
  warehouse: string().required('warehouse is required'),
});

export const SemiIngredientRecipeValidation = object({
  title: string().required("'title' is required"),
  purchase_amount: number().required("'purchase_amount' is required"),
  product_amount: number().required("'product amount' is required"),
  quantity_type: number().required("'quantity type' is required"),
  detail: array()
    .of(
      object({
        product: string().required("'product_service' is required"),
        productId: string().required("'product id' is required"),
        productType: string().required("'product type' is required"),
        amount: number().required("'amount' is required"),
        quantity: number().required("'quantity' is required"),
      }).required(),
    )
    .required(),
});

export const RecipeValidation = object({
  title: string().required('Başlık zorunludur'),
  purchase_amount: number().required('Maliyet zorunludur'),
  product_amount: number().required('Satış fiyatı zorunludur'),
  detail: array()
    .of(
      object({
        product: string().required('Ürün zorunludur'),
        productId: string().required("'product id' is required"),
        productType: string().required("'product type' is required"),
        amount: number().required('Tutar zorunludur'),
        quantity: number().required('Miktar zorunludur'),
      }).required(),
    )
    .required(),
});

export const BankReceiptValidation = object({
  description: string().required('Açıklama zorunludur'),
  receiptType: number().required('Dekont tipi zorunludur'),
  actions: array().of(
    object({
      actionId: string().required(),
      actionModel: string().required(),
    }),
  ),
  receiptFor: array().of(
    object({
      userId: string().required(),
      userModel: string().required(),
    }),
  ),
});
