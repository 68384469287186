import {
  Box,
  ButtonGroup,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';

import BankReceipt from 'services/bankReceipt';
import Response from 'services/response';
import Http from 'services/http';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'components/spinner';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'components/modal';
import DeleteIcon from '@mui/icons-material/Delete';

export default function index() {
  const page = useRef(1);
  const perPage = useRef(6);
  const sort = useRef('DATE_DESC');

  const [receiptData, setReceiptData]: any = useState([]);
  const [totalCount, setReceiptTotalCount] = useState(0);
  const [modal, setModal] = useState<boolean>(false);
  const [receipt, setReceipt] = useState<any | null>(null);

  const navigate = useNavigate();

  const IService = new BankReceipt(useContext(Http.Context)!);
  const IFetch = new Response();

  const Delete = new Response();

  const fetchData = (reset: boolean = false) => {
    if (reset) setReceiptData([]);

    IFetch.handle(IService.get(page.current - 1, perPage.current, sort.current));
  };

  useEffect(() => fetchData(), []);

  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading)
      setTimeout(() => (fetchData(true), setModal(false)), 500);
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(receipt)), [receipt]);
  useEffect(
    () => (!modal && setTimeout(() => (setReceipt(null), (Delete.data = null)), 200), undefined),
    [modal],
  );

  useEffect(() => {
    if (IFetch.data) {
      setReceiptData((prevData: any) => [...prevData, ...IFetch.data?.data]);
      setReceiptTotalCount(IFetch?.data?.totalCount);
    }
  }, [IFetch?.data]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  const loadMoreData = () => {
    page.current++;
    fetchData();
  };

  const getReceiptType = (type: string) => {
    switch (type) {
      case 'Bank Operations':
        return 'Banka İşlemleri';
      case 'Stock Operations':
        return 'Stok İşlemleri';
      case 'Money Operations':
        return 'Para İşlemleri';
      case 'General':
        return 'Genel';
      default:
        break;
    }
  };

  return (
    <Box>
      <Modal onClose={() => !Delete.loading && setModal(false)} state={[modal, setModal]}>
        <React.Fragment>
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='sm:flex sm:items-start'>
              <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-red-600'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
              </div>
              <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>
                  {getReceiptType(receipt?.receiptType)}
                </h3>
                <div className='mt-2'>
                  <p className='text-sm text-gray-500'>
                    Bu dekontu silmek istediğinize emin misiniz? Bu işlem geri alınamaz.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-actions'>
            <button
              disabled={Delete.data || Delete.loading}
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={() => Delete.handle(IService.delete(receipt._id))}
            >
              {Delete.data ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : Delete.loading ? (
                <Spinner className='h-5 m-auto' />
              ) : (
                <span>Sil</span>
              )}
            </button>
            <button
              disabled={Delete.loading}
              type='button'
              className='mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto'
              onClick={setModal.bind(null, false)}
            >
              İptal
            </button>
          </div>
        </React.Fragment>
      </Modal>
      <Box>
        <Stack direction='row' justifyContent='space-around' padding='1vw'>
          <Typography variant='h4' fontSize='2.2vw' fontWeight='bold'>
            Dekontlar
          </Typography>
          <div className='flex flex-col ml-auto relative z-10 shadow-md'>
            <Link
              to='/bank-receipts/action'
              className='flex shadow-md items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium ml-auto'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5 mr-2'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path
                  fillRule='evenodd'
                  d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                  clipRule='evenodd'
                />
              </svg>
              <span>Yeni Dekont Oluştur</span>
            </Link>
          </div>
        </Stack>
        <Divider />
      </Box>
      {IFetch.data ? (
        <>
          {totalCount == 0 ? (
            <Typography variant='h4' fontWeight='bold' textAlign='center' marginTop='2vw'>
              Filtrelere uygun dekont bulunamadı.
            </Typography>
          ) : (
            <>
              <InfiniteScroll
                dataLength={receiptData.length || 0}
                next={loadMoreData}
                loader={
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Spinner />
                  </Box>
                }
                hasMore={receiptData?.length < totalCount}
              >
                <Box
                  sx={{
                    height: '32vw',
                    marginTop: '2vw',
                    overflowY: 'scroll',
                  }}
                >
                  <Paper elevation={3}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography fontSize='1.2vw' fontWeight='bold'>
                              Dekont Tipi
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize='1.2vw' fontWeight='bold'>
                              Dekont Açıklaması
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize='1.2vw' fontWeight='bold'>
                              Oluşturulma Tarihi
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {receiptData &&
                          receiptData.map((receipt: any) => (
                            <TableRow>
                              <TableCell>
                                <Typography fontSize='1.1vw'>
                                  {getReceiptType(receipt.receiptType)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontSize='1.1vw'>{receipt.description}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontSize='1.1vw'>
                                  {new Date(receipt.createdAt)?.toLocaleString()}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <ButtonGroup orientation='horizontal'>
                                  <Tooltip title='Faturayı Sil'>
                                    <IconButton onClick={setReceipt.bind(null, receipt)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Box>
              </InfiniteScroll>
            </>
          )}
        </>
      ) : (
        <Box sx={{ width: '10%', height: '10%', margin: 'auto', marginTop: '12vw' }}>
          <Spinner />
        </Box>
      )}
    </Box>
  );
}
