import { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import Http from 'services/http';
import Response from 'services/response';

import ShowSum from 'components/showSum';
import ShowGraph from 'components/showGraph';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

import Case from 'services/cases';
import Bank from 'services/bank';
import Customer from 'services/customer';
import Invoice from 'services/invoice';
import Ingradient from 'services/ingradient';

export default function () {
  const CService = new Case(useContext(Http.Context)!);
  const Cases = new Response();

  const BService = new Bank(useContext(Http.Context)!);
  const Banks = new Response();

  const CuService = new Customer(useContext(Http.Context)!);
  const Customers = new Response();

  const IService = new Invoice(useContext(Http.Context)!);
  const Invoices = new Response();

  const IngService = new Ingradient(useContext(Http.Context)!);
  const Ingredients = new Response();

  useEffect(() => (!Cases.data && Cases.handle(CService.get()), undefined), [Cases.data]);
  useEffect(() => (!Banks.data && Banks.handle(BService.get()), undefined), [Banks.data]);
  useEffect(
    () => (!Customers.data && Customers.handle(CuService.get()), undefined),
    [Customers.data],
  );
  useEffect(() => (!Invoices.data && Invoices.handle(IService.get()), undefined), [Invoices.data]);
  useEffect(
    () => (!Ingredients.data && Ingredients.handle(IngService.get()), undefined),
    [Ingredients.data],
  );

  const getTotal = (data: {}[]) => {
    if (data) {
      let total: any = { 1: 0, 2: 0, 3: 0, 4: 0 };
      data.forEach((d: any) => {
        total[d.balance[0].currency] += +d.balance[0].amount;
      });
      return Object.values(total);
    }
    return false;
  };

  const customerTotal = (data: {}[]) => {
    if (data) {
      let total: any = { 1: 0, 2: 0, 3: 0, 4: 0 };
      data.forEach((d: any) => {
        total[d.currency] += +d.opening_balance;
      });
      return Object.values(total);
    }
    return false;
  };

  const invoiceTotal = (data: {}[]) => {
    if (data?.length) {
      let total: any = { 1: 0, 2: 0, 3: 0, 4: 0 };
      data.forEach((d: any) => {
        total[d.currency] += +d.total_amount;
      });
      return Object.values(total);
    }
    return false;
  };

  return (
    <div id='content' className='p-6 space-y-4 overflow-x-hidden'>
      <div className='grid grid-cols-4 gap-4'>
        <ShowSum name='Kasalar Toplamı' data={getTotal(Cases.data) || [0]} />
        <ShowSum name='Bankalar Toplamı' data={getTotal(Banks.data) || [0]} />
        <ShowSum name='Müşteri ve Tedarikçi Bakiye' data={customerTotal(Customers.data) || [0]} />
        <ShowSum
          name='Stoktaki Ürün Sayısı'
          type='adet'
          data={Ingredients?.data?.filter((i: any) => i.quantity > 0).length || 0}
        />
        <ShowSum
          name='Alış Faturaları Toplamı'
          data={invoiceTotal(Invoices.data?.filter((i: any) => i.invoice_types == 1)) || [0]}
        />
        <ShowSum
          name='Alış İade Faturaları Toplamı'
          data={invoiceTotal(Invoices.data?.filter((i: any) => i.invoice_types == 2)) || [0]}
        />
        <ShowSum
          name='Satış Faturaları Toplamı'
          data={invoiceTotal(Invoices.data?.filter((i: any) => i.invoice_types == 3)) || [0]}
        />
        <ShowSum
          name='Satış İade Faturaları Toplamı'
          data={invoiceTotal(Invoices.data?.filter((i: any) => i.invoice_types == 4)) || [0]}
        />
      </div>
      <ShowGraph
        name='Aylık Satış Raporu'
        data={{
          labels: ['a'],
          datasets: [
            {
              label: '(₺)',
              data: [0],
              borderColor: '#16a34a',
              backgroundColor: '#22c55e',
            },
          ],
        }}
      />
    </div>
  );
}
