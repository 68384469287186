import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Formik, FieldArray, FormikErrors, FormikTouched } from 'formik';
import { Listbox, Tab, Transition } from '@headlessui/react';
import './rebate.css';
interface IDetail {
  product_service: string;
  amount: number;
  quantity: number;
  vat: number;
  discount: number;
  total: number;
  process: number;
  quantity_type: number;
  warehouse: string;
  productType?: number;
}

import Http from 'services/http';
import Invoice from 'services/invoice';
import Response from 'services/response';
import Structures from 'services/structures';

import Spinner from 'components/spinner';

import UString from 'utilities/string';
import Customer from 'services/customer';
import Ingradient from 'services/ingradient';
import Warehouse from 'services/warehouse';
import Bank from 'services/bank';
import SemiIngredient from 'services/semiingradient';
import Recipe from 'services/recipe';

import { InvoiceValidation } from 'validations';

import { toast } from 'react-toastify';
import {
  Autocomplete,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import clone from 'clone';

export default function () {
  const Service = new Invoice(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();

  const CService = new Customer(useContext(Http.Context)!);
  const CFetch = new Response();

  const OService = new Ingradient(useContext(Http.Context)!);
  const OFetch = new Response();

  const WService = new Warehouse(useContext(Http.Context)!);
  const WFetch = new Response();

  const BService = new Bank(useContext(Http.Context)!);
  const BFetch = new Response();

  const RService = new Recipe(useContext(Http.Context)!);
  const RFetch = new Response();

  const SemiService = new SemiIngredient(useContext(Http.Context)!);
  const SemiFetch = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();

  const { id, action } = useParams();
  const [temp, setTemp] = useState<any>(); //temp for fetch data

  const someFunctionWithLogic = (
    setFieldValue: any,
    index: any,
    value: number,
    section: any,
    item: any,
  ) => {
    value = Number(value);
    if (section == 'quantity') {
      item.quantity = value;
    }
    if (section == 'amount') {
      item.amount = value;
    }
    if (section == 'vat') {
      item.vat = value;
    }
    if (section == 'discount') {
      item.discount = value;
    }
    if (item.quantity && item.amount != 0) {
      item.total =
        item.quantity * item.amount +
        (item.vat == 0 ? 0 : item.quantity * item.amount * (item.vat / 100)) -
        (item.discount == 0
          ? 0
          : (item.quantity * item.amount +
              (item.vat == 0 ? 0 : item.quantity * item.amount * (item.vat / 100))) *
            (item.discount / 100));
    }
    setFieldValue(`detail[${index}]`, item);
  };

  useEffect(() => (!OFetch.data && OFetch.handle(OService.get()), undefined), [OFetch.data]);
  useEffect(() => (!id && !Fetch.data && Fetch.handle(Service.get()), undefined), [Fetch.data]);
  useEffect(() => (id && Fetch.handle(Service.getById(id!)), undefined), [id]);
  useEffect(() => (!CFetch.data && CFetch.handle(CService.get()), undefined), [CFetch.data]);
  useEffect(() => (!RFetch.data && RFetch.handle(RService.get()), undefined), [RFetch.data]);
  useEffect(
    () => (!SemiFetch.data && SemiFetch.handle(SemiService.get()), undefined),
    [SemiFetch.data],
  );

  useEffect(() => (!WFetch.data && WFetch.handle(WService.get()), undefined), [WFetch.data]);

  useEffect(() => (!BFetch.data && BFetch.handle(BService.get()), undefined), [BFetch.data]);

  useEffect(() => {
    if (Fetch.data) {
      setTemp(clone(Fetch.data));
      Fetch.data?.detail?.map((d: any) => {
        d.quantity = 0;
        d.total = 0;
      });
    }
  }, [Fetch.data]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(`Satış İade Faturası ${action != 'create' ? 'düzenleme' : 'ekleme'} başarılı.`);
      navigate('/sellBill');
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(`Satış İade Faturası ${action != 'create' ? 'düzenleme' : 'ekleme'} başarısız.`);
      navigate('/sellBill');
    }
  }, [Save.error]);

  return (
    <React.Fragment>
      <div className='header-top'>
        <h2 className='text-xl font-medium pl-2'>
          Satış İade Faturası {action == 'create' ? 'Ekle' : 'Düzenle'}
        </h2>
      </div>
      <div id='content'>
        <Formik
          innerRef={Form}
          enableReinitialize={true}
          initialValues={{
            invoice_time: Fetch.data?.invoice_time?.slice(0, -8) || String(),
            invoice_code: Fetch.data?.invoice_code || String(),
            invoice_no: Fetch.data?.invoice_no || Number(),
            customer: Fetch.data?.customer || CFetch.data?.[0]?.id || String(),
            currency: Fetch.data?.currency || Number(Object.keys(Structures.types.currency_opt)[0]),
            pay_type: Fetch.data?.pay_type || Number(Object.keys(Structures.types.payment_type)[0]),
            expiry_date: Fetch.data?.expiry_date?.slice(0, -8) || String(),
            invoice_types: 4,
            invoice_type: false,
            description: Fetch.data?.description || String(),
            customerType: Fetch.data?.customerType || 1,
            detail:
              (Fetch.data?.detail as Array<IDetail>) ||
              Array({
                product_service: Fetch.data?.product_service || String(),
                amount: Fetch.data?.amount || Number(),
                quantity: Fetch.data?.quantity || Number(),
                vat: Fetch.data?.vat || Number(),
                discount: Fetch.data?.discount || Number(),
                total: Fetch.data?.total || Number(),
                quantity_type:
                  Fetch.data?.quantity_type || Number(Object.keys(Structures.types.quantity)[0]),
              }),
            warehouse: Fetch.data?.warehouse || WFetch?.data?.[0]?.id || String(),
          }}
          validationSchema={InvoiceValidation}
          onSubmit={(values) => {
            let totalRefund = values.detail?.reduce((acc: any, curr: any) => {
              acc += curr.total;
              return acc;
            }, 0);
            let newTotalAmount = temp.total_amount - totalRefund;
            if (temp.paidAmount > newTotalAmount) {
              toast.warning(
                `Ödenen tutar toplam tutardan küçük olacak şekilde iade yapılamaz. Yanlış ödeme aldıysanız kasa sayfasından ödemeyi iptal edebilirsiniz. \n Ödenen Tutar: ${temp.paidAmount} ₺`,
                { hideProgressBar: false, autoClose: 8000 },
              );
              return;
            }
            Save.handle(
              action != 'create' && id && Fetch.data
                ? Service.update(id, {
                    ...Fetch.data,
                    ...values,
                  })
                : Service.createRebateInvoice(Fetch.data?._id, values),
            );
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form className='action' onSubmit={handleSubmit}>
              <Tab.Group>
                <Tab.List className='my-4'>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        'mr-3 ',
                        '',
                        selected
                          ? 'border-b border-red-600 text-red-600 font-bold'
                          : 'border-b border-black',
                      )
                    }
                  >
                    Temel Bilgiler
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        'mr-3 ',
                        '',
                        selected
                          ? 'border-b border-red-600 text-red-600 font-bold'
                          : 'border-b border-black',
                      )
                    }
                  >
                    Ödeme Bilgileri
                  </Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel>
                    <div className='wrapper'>
                      <div className='item'>
                        <div className='w-full'>
                          <label htmlFor='invoice_time'>Tarih ve Saat</label>
                          <input
                            type='datetime-local'
                            name='invoice_time'
                            className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                              errors.invoice_time && touched.invoice_time ? 'has-error' : undefined,
                              'my-1',
                            )}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.invoice_time}
                          />
                          {errors.invoice_time && touched.invoice_time ? (
                            <span className='error'>* {errors.invoice_time}</span>
                          ) : null}
                        </div>
                        <div className='w-full'>
                          <label htmlFor='invoice_no'>Fatura No Seri</label>
                          <input
                            disabled
                            type='number'
                            name='invoice_no'
                            className={UString.concat_class_name(
                              errors.invoice_no && touched.invoice_no ? 'has-error' : undefined,
                              'my-1',
                            )}
                            placeholder='Fatura No Seri'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.invoice_no}
                            style={{ cursor: 'not-allowed' }}
                          />
                          {errors.invoice_no && touched.invoice_no ? (
                            <span className='error'>* {errors.invoice_no}</span>
                          ) : null}
                        </div>
                        <div className='w-full'>
                          <label htmlFor='invoice_code'>Fatura No Sıra</label>
                          <input
                            disabled
                            type='text'
                            name='invoice_code'
                            className={UString.concat_class_name(
                              errors.invoice_code && touched.invoice_code ? 'has-error' : undefined,
                              'my-1',
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder='Fatura No Sıra'
                            value={values.invoice_code}
                            style={{ cursor: 'not-allowed' }}
                          />
                          {errors.invoice_code && touched.invoice_code ? (
                            <span className='error'>* {errors.invoice_code}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className='item'>
                        <div className='w-full'>
                          <FormLabel id='customer-select-label'>Müşteri Tipi</FormLabel>
                          <RadioGroup
                            value={values.customerType}
                            aria-labeledBy='customer-select-label'
                            name='customerType'
                            onChange={(e: any) => {
                              handleChange(e);
                              switch (e.target.value) {
                                case '1':
                                  setFieldValue('customer', CFetch?.data[0]?.id || '');
                                  break;
                                case '2':
                                  setFieldValue('customer', BFetch?.data[0]?._id || '');
                                  break;
                                default:
                                  break;
                              }
                            }}
                          >
                            <Stack direction='row'>
                              <FormControlLabel
                                disabled
                                value={1}
                                control={<Radio />}
                                label='Müşteri'
                              />
                              <FormControlLabel
                                disabled
                                value={2}
                                control={<Radio />}
                                label='Banka'
                              />
                            </Stack>
                          </RadioGroup>
                        </div>
                        <div className='relative w-full'>
                          {CFetch.data ? (
                            <>
                              {values.customerType == 1 ? (
                                <Stack direction='column'>
                                  <Autocomplete
                                    disabled
                                    sx={{ cursor: 'not-allowed' }}
                                    defaultValue={
                                      values.customer?.toString() || CFetch?.data[0]?.id
                                    }
                                    componentName='customer'
                                    options={CFetch?.data
                                      ?.filter((customer: any) => customer.card_type != 4)
                                      ?.map((customer: any) => customer.id)}
                                    getOptionLabel={(option: any) =>
                                      CFetch?.data.find((customer: any) => customer.id == option)
                                        ?.title
                                    }
                                    onChange={(e: any, value: any) => {
                                      setFieldValue('customer', value || '');
                                    }}
                                    clearOnEscape
                                    renderInput={({ inputProps, ...params }) => (
                                      <TextField variant='outlined' {...params} label='Müşteri' />
                                    )}
                                  />
                                  {errors.customer && touched.customer ? (
                                    <span className='error'>* {errors.customer}</span>
                                  ) : null}
                                </Stack>
                              ) : null}
                              {values.customerType == 2 ? (
                                <Stack direction='column'>
                                  <Autocomplete
                                    disabled
                                    sx={{ cursor: 'not-allowed' }}
                                    defaultValue={
                                      values.customer?.toString() || BFetch?.data[0]?._id
                                    }
                                    componentName='customer'
                                    options={BFetch?.data?.map((bank: any) => bank._id)}
                                    getOptionLabel={(option: any) =>
                                      BFetch?.data?.find((bank: any) => bank._id == option)
                                        ?.account_name
                                    }
                                    onChange={(e: any, value: any) => {
                                      setFieldValue('customer', value || '');
                                    }}
                                    clearOnEscape
                                    renderInput={(params) => (
                                      <TextField
                                        variant='outlined'
                                        {...params}
                                        label='Banka Hesabı'
                                      />
                                    )}
                                  />
                                  {errors.customer && touched.customer ? (
                                    <span className='error'>* {errors.customer}</span>
                                  ) : null}
                                </Stack>
                              ) : null}
                            </>
                          ) : (
                            <div className='classic-btn w-full min-h-[38px] mt-1'>
                              <Spinner className='w-5 m-auto' />
                            </div>
                          )}
                        </div>
                        <div className='relative w-full'>
                          <label>Döviz Cinsi</label>
                          <Listbox
                            disabled
                            value={values.currency}
                            onChange={(value) => setFieldValue('currency', Number(value))}
                          >
                            <div className='relative mt-1'>
                              <Listbox.Button
                                className='listbox-btn'
                                style={{ cursor: 'not-allowed' }}
                              >
                                <span className='listbox-title'>
                                  {(Structures.types.currency_opt as any)[values.currency]}
                                </span>
                                <span className='listbox-selector-icon'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='w-5 h-5 text-gray-400'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                  >
                                    <path
                                      fillRule='evenodd'
                                      d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                      clipRule='evenodd'
                                    />
                                  </svg>
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={React.Fragment}
                                leave='transition ease-in duration-100'
                                leaveFrom='opacity-100'
                                leaveTo='opacity-0'
                              >
                                <Listbox.Options className='listbox-options'>
                                  {Object.keys(Structures.types.currency_opt).map((type, index) => (
                                    <Listbox.Option
                                      className={UString.concat_class_name(
                                        values.currency === Number(type)
                                          ? 'bg-ebony text-white'
                                          : 'hover:bg-ebony hover:text-white',
                                        'flex items-center cursor-pointer px-6 py-2',
                                      )}
                                      key={index}
                                      value={Number(type)}
                                    >
                                      <span>{(Structures.types.currency_opt as any)[type]}</span>
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      </div>
                    </div>
                    <hr className='my-3' />
                    <div className='text-lg'>
                      {
                        <FieldArray
                          name='detail'
                          render={({ insert, remove }) => (
                            <>
                              <div className='flex flex-col space-y-4 divide-y divide-gray-200 xl:divide-y-0'>
                                {values.detail.map((item, index) => {
                                  const touch = touched.detail?.[index] as
                                    | FormikTouched<IDetail>
                                    | undefined;
                                  const error = errors.detail?.[index] as
                                    | FormikErrors<IDetail>
                                    | undefined;

                                  return (
                                    <div
                                      className='flex flex-col xl:flex-row gap-4 w-full py-4 xl:py-0'
                                      key={index}
                                    >
                                      <div className='relative w-full'>
                                        <label htmlFor={`detail.[${index}].warehouse`}>Depo</label>
                                        <Listbox
                                          disabled
                                          value={values.detail[index].warehouse}
                                          onChange={(value) => {
                                            let pType =
                                              value == 'Reçeteler'
                                                ? 3
                                                : value == 'Yarı Mamüller'
                                                ? 2
                                                : 1;
                                            setFieldValue(`detail[${index}].productType`, pType);
                                            setFieldValue(
                                              `detail[${index}].warehouse`,
                                              String(value),
                                            );
                                            setFieldValue(`detail[${index}].product_service`, '');
                                          }}
                                        >
                                          <div className='relative mt-1'>
                                            <Listbox.Button
                                              className='listbox-btn'
                                              style={{ cursor: 'not-allowed' }}
                                            >
                                              <span className='listbox-title'>
                                                {WFetch?.data?.find(
                                                  (warehouse: any) =>
                                                    warehouse.id == item.warehouse,
                                                )?.title ||
                                                  item.warehouse ||
                                                  'Depo Seç'}
                                              </span>
                                              <span className='listbox-selector-icon'>
                                                <svg
                                                  xmlns='http://www.w3.org/2000/svg'
                                                  className='w-5 h-5 text-gray-400'
                                                  viewBox='0 0 20 20'
                                                  fill='currentColor'
                                                >
                                                  <path
                                                    fillRule='evenodd'
                                                    d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                                    clipRule='evenodd'
                                                  />
                                                </svg>
                                              </span>
                                            </Listbox.Button>
                                            <Transition
                                              as={React.Fragment}
                                              leave='transition ease-in duration-100'
                                              leaveFrom='opacity-100'
                                              leaveTo='opacity-0'
                                            >
                                              <Listbox.Options className='listbox-options'>
                                                {WFetch?.data?.map((warehouse: any) => (
                                                  <Listbox.Option
                                                    className={UString.concat_class_name(
                                                      values.detail[index]?.warehouse ===
                                                        warehouse.id
                                                        ? 'bg-ebony text-white'
                                                        : 'hover:bg-ebony hover:text-white',
                                                      'flex items-center cursor-pointer px-6 py-2',
                                                    )}
                                                    key={warehouse.id}
                                                    value={warehouse.id}
                                                  >
                                                    <span>{warehouse.title}</span>
                                                  </Listbox.Option>
                                                ))}
                                                <Listbox.Option
                                                  className={UString.concat_class_name(
                                                    values.detail[index]?.warehouse ===
                                                      'Yarı Mamüller'
                                                      ? 'bg-ebony text-white'
                                                      : 'hover:bg-ebony hover:text-white',
                                                    'flex items-center cursor-pointer px-6 py-2',
                                                  )}
                                                  key={1}
                                                  value='Yarı Mamüller'
                                                >
                                                  <span>Yarı Mamüller</span>
                                                </Listbox.Option>
                                                <Listbox.Option
                                                  className={UString.concat_class_name(
                                                    values.detail[index]?.warehouse === 'Reçeteler'
                                                      ? 'bg-ebony text-white'
                                                      : 'hover:bg-ebony hover:text-white',
                                                    'flex items-center cursor-pointer px-6 py-2',
                                                  )}
                                                  key={2}
                                                  value='Reçeteler'
                                                >
                                                  <span>Reçeteler</span>
                                                </Listbox.Option>
                                              </Listbox.Options>
                                            </Transition>
                                          </div>
                                        </Listbox>
                                      </div>
                                      <div className='relative w-full xl:w-3/4'>
                                        <label>Ürün</label>
                                        {OFetch?.data ? (
                                          <Listbox
                                            disabled
                                            value={`detail.[${index}].product_service`}
                                            onChange={(value) =>
                                              setFieldValue(
                                                `detail.[${index}].product_service`,
                                                value,
                                              )
                                            }
                                          >
                                            <div className='relative mt-1'>
                                              <Listbox.Button
                                                className='listbox-btn'
                                                style={{ cursor: 'not-allowed' }}
                                              >
                                                <span className='listbox-title'>
                                                  {OFetch?.data?.find(
                                                    (ingrediant: any) =>
                                                      ingrediant.id === item.product_service,
                                                  )?.title || 'Ürün Seç'}
                                                </span>
                                                <span className='listbox-selector-icon'>
                                                  <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    className='w-5 h-5 text-gray-400'
                                                    viewBox='0 0 20 20'
                                                    fill='currentColor'
                                                  >
                                                    <path
                                                      fillRule='evenodd'
                                                      d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                                      clipRule='evenodd'
                                                    />
                                                  </svg>
                                                </span>
                                              </Listbox.Button>
                                              <Listbox.Options className='listbox-options'>
                                                {OFetch?.data &&
                                                  OFetch?.data
                                                    ?.filter(
                                                      (product: any) =>
                                                        product.warehouse?._id == item.warehouse,
                                                    )
                                                    ?.map((warehouse: any, index: any) => (
                                                      <Listbox.Option
                                                        className={UString.concat_class_name(
                                                          item.product_service === warehouse.id
                                                            ? 'bg-ebony text-white'
                                                            : 'hover:bg-ebony hover:text-white',
                                                          'flex items-center cursor-pointer px-6 py-2',
                                                        )}
                                                        key={index}
                                                        value={warehouse.id}
                                                      >
                                                        <span>{warehouse.title}</span>
                                                      </Listbox.Option>
                                                    ))}
                                              </Listbox.Options>
                                            </div>
                                          </Listbox>
                                        ) : (
                                          <div className='classic-btn w-full min-h-[38px] mt-1'>
                                            <Spinner className='w-5 m-auto' />
                                          </div>
                                        )}
                                      </div>
                                      <div className='w-full xl:w-1/2'>
                                        <label htmlFor={`detail.[${index}].totalQuantity`}>
                                          Miktar
                                        </label>
                                        <input
                                          disabled
                                          style={{ cursor: 'not-allowed' }}
                                          type='number'
                                          name={`detail.[${index}].totalQuantity`}
                                          value={temp?.detail?.[index].quantity}
                                        />
                                      </div>
                                      <div className='w-full xl:w-3/4'>
                                        <label htmlFor={`detail.[${index}].quantity`}>
                                          İade Edilecek Miktar
                                        </label>
                                        <input
                                          type='number'
                                          min={0}
                                          name={`detail.[${index}].quantity`}
                                          className={UString.concat_class_name(
                                            error?.amount && touch?.amount
                                              ? 'has-error'
                                              : undefined,
                                            'mt-1',
                                          )}
                                          onChange={(e) => {
                                            handleChange(e);
                                            let quantity = Number(e.target.value);

                                            setFieldValue(
                                              `detail.[${index}].total`,
                                              quantity * temp?.detail[index].amount +
                                                temp?.detail[index].amount *
                                                  quantity *
                                                  (temp?.detail[index].vat / 100),
                                            );
                                            if (
                                              temp?.detail[index].quantity -
                                                Number(e.target.value) <
                                              0
                                            ) {
                                              setFieldValue(
                                                `detail.[${index}].total`,
                                                temp?.detail[index].total,
                                              );
                                              setFieldValue(
                                                `detail.[${index}].quantity`,
                                                temp?.detail[index].quantity,
                                              );
                                            }
                                          }}
                                          onBlur={handleBlur}
                                          value={item.quantity}
                                        />
                                        {error?.quantity && touch?.quantity ? (
                                          <span className='error'>* {error?.quantity}</span>
                                        ) : null}
                                      </div>
                                      <div className='w-full xl:w-3/4'>
                                        <label htmlFor={`detail.[${index}].totalQuantity`}>
                                          İade Edilecek Tutar
                                        </label>
                                        <input
                                          disabled
                                          style={{ cursor: 'not-allowed' }}
                                          type='number'
                                          name={`detail.[${index}].total`}
                                          className={UString.concat_class_name(
                                            error?.total && touch?.total ? 'has-error' : undefined,
                                            'mt-1',
                                          )}
                                          value={item.total}
                                        />
                                      </div>
                                      <div className='w-full xl:w-1/2'>
                                        <label htmlFor={`detail.[${index}].totalQuantity`}>
                                          Kalan Miktar
                                        </label>
                                        <input
                                          disabled
                                          style={{ cursor: 'not-allowed' }}
                                          type='number'
                                          name={`detail.[${index}].amount`}
                                          className={UString.concat_class_name(
                                            error?.amount && touch?.amount
                                              ? 'has-error'
                                              : undefined,
                                            'mt-1',
                                          )}
                                          value={
                                            temp?.detail[index]?.quantity -
                                            values.detail[index].quantity
                                          }
                                        />
                                      </div>
                                      <div className='w-full xl:w-1/2'>
                                        <label htmlFor={`detail.[${index}].totalQuantity`}>
                                          Kalan Tutar
                                        </label>
                                        <input
                                          disabled
                                          style={{ cursor: 'not-allowed' }}
                                          type='number'
                                          name={`detail.[${index}].amount`}
                                          className={UString.concat_class_name(
                                            error?.amount && touch?.amount
                                              ? 'has-error'
                                              : undefined,
                                            'mt-1',
                                          )}
                                          value={
                                            temp?.detail[index]?.total - values.detail[index].total
                                          }
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        />
                      }
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className='wrapper'>
                      <div className='item'>
                        <div className='relative w-full'>
                          <label>Ödeme Şekli</label>
                          <Listbox
                            value={values.pay_type}
                            onChange={(value) => setFieldValue('pay_type', Number(value))}
                          >
                            <div className='relative mt-1'>
                              <Listbox.Button className='listbox-btn'>
                                <span className='listbox-title'>
                                  {(Structures.types.payment_type as any)[values.pay_type]}
                                </span>
                                <span className='listbox-selector-icon'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='w-5 h-5 text-gray-400'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                  >
                                    <path
                                      fillRule='evenodd'
                                      d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                      clipRule='evenodd'
                                    />
                                  </svg>
                                </span>
                              </Listbox.Button>
                              <Listbox.Options className='listbox-options'>
                                {Object.keys(Structures.types.payment_type).map((type, index) => (
                                  <Listbox.Option
                                    className={UString.concat_class_name(
                                      values.pay_type === Number(type)
                                        ? 'bg-ebony text-white'
                                        : 'hover:bg-ebony hover:text-white',
                                      'flex items-center cursor-pointer px-6 py-2',
                                    )}
                                    key={index}
                                    value={Number(type)}
                                  >
                                    <span>{(Structures.types.payment_type as any)[type]}</span>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </div>
                          </Listbox>
                        </div>
                        <div className='w-full'>
                          <label htmlFor='expiry_date'>Son Ödeme Tarihi</label>
                          <input
                            type='datetime-local'
                            name='expiry_date'
                            className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                              errors.expiry_date && touched.expiry_date ? 'has-error' : undefined,
                              'my-1',
                            )}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.expiry_date}
                          />
                          {errors.expiry_date && touched.expiry_date ? (
                            <span className='error'>* {errors.expiry_date}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className='item'>
                        <div className='w-full'>
                          <label htmlFor='description'>Ödeme Notu</label>
                          <textarea
                            name='description'
                            className={UString.concat_class_name(
                              errors.description && touched.description ? 'has-error' : undefined,
                              'my-1',
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder='Ödeme Notu'
                            value={values.description}
                          />
                          {errors.description && touched.description ? (
                            <span className='error'>* {errors.description}</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </form>
          )}
        </Formik>
      </div>
      <div className='header-bottom'>
        <button
          type='submit'
          disabled={Save.loading}
          className='ml-auto classic-btn'
          onClick={() => Form.current.handleSubmit()}
        >
          {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
        </button>
      </div>
    </React.Fragment>
  );
}
