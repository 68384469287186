import { useEffect, useContext } from 'react';
import { Outlet, BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';

import Http from 'services/http';
import Authenticate, { Service } from 'services/authenticate';

import Auth from 'components/middleware/auth';
import Layout from 'components/layout';

import Index from 'pages/index';

import Customers from 'pages/customers';
import CustomersAction from 'pages/customers/action';

import Bill from 'pages/bill';
import BillAction from 'pages/bill/action';
import BillRebate from 'pages/bill/rebate';

import SellBill from 'pages/sellBill';
import SellBillAction from 'pages/sellBill/action';
import SellBillRebate from 'pages/sellBill/rebate';

import Bills from 'pages/bills/index';
import BillsRebate from 'pages/bills/rebateAction';

import BankReceipts from 'pages/bank-receipts/index';
import BankReceiptsAction from 'pages/bank-receipts/action';

import Storage from 'pages/storage';
import StorageAction from 'pages/storage/action';
import EditStock from 'pages/storage/edit-stock';

import Stock from 'pages/stock';
import StockAction from 'pages/stock/action';
import StockDetail from 'pages/stock/detail';

import Cases from 'pages/cases';
import CasesAction from 'pages/cases/action';

import Bank from 'pages/bank';
import BankAction from 'pages/bank/action';

import Recipes from 'pages/recipe';
import RecipesAction from 'pages/recipe/action';

import SignIn from 'pages/sign-in';

import PosSettings from 'pages/posSettings';

import Checks from 'pages/check';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className='text-center'>
      <h2 className='text-6xl font-bold'>404</h2>
      <h3 className='text-xl font-medium'>Sayfa bulunamadı</h3>
      <button className='text-sm' onClick={() => navigate('/')}>
        Ana Sayfa
      </button>
    </div>
  );
};

export default function () {
  const AuthContext = useContext(Authenticate.Context);
  const HttpContext = useContext(Http.Context);

  useEffect(() => {
    AuthContext?.Context?.tokens.access && AuthContext?.Context?.tokens.refresh
      ? AuthContext.storeTokens()
      : Service.removeStoredTokens();

    AuthContext &&
      (AuthContext.User = Service.parseUserFromToken(AuthContext?.Context?.tokens.access));
    AuthContext &&
      AuthContext.User &&
      (AuthContext.IsAuthenticated = AuthContext.verifyExpireTime());

    AuthContext &&
      AuthContext.IsAuthenticated &&
      HttpContext?.addHeader('Authorization', AuthContext.bearerToken());
  }, [AuthContext?.Context?.tokens]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Auth />}>
          <Route element={<Layout />}>
            <Route index element={<Index />} />
            <Route path='/panel' element={<Index />} />
            <Route path='/dashboard' element={<Index />} />

            <Route path='customers' element={<Outlet />}>
              <Route index element={<Customers />} />
              <Route path='action/:path' element={<CustomersAction />}>
                <Route path=':id' element={<CustomersAction />} />
              </Route>
            </Route>

            <Route path='bill' element={<Outlet />}>
              <Route index element={<Bill />} />
              <Route path='action' element={<BillAction />}>
                <Route path=':id' element={<BillAction />} />
              </Route>
              <Route path='action/rebate/:id/:action' element={<BillRebate />} />
            </Route>
            <Route path='bills' element={<Outlet />}>
              <Route index element={<Bills />} />
              <Route path='action' element={<BillsRebate />}>
                <Route path=':id' element={<BillRebate />} />
              </Route>
            </Route>
            <Route path='sellBill' element={<Outlet />}>
              <Route index element={<SellBill />} />
              <Route path='action' element={<SellBillAction />}>
                <Route path=':id' element={<SellBillAction />} />
              </Route>
              <Route path='action/rebate/:id/:action' element={<SellBillRebate />} />
            </Route>

            <Route path='storage' element={<Outlet />}>
              <Route index element={<Storage />} />
              <Route path='action' element={<StorageAction />}>
                <Route path=':id' element={<StorageAction />} />
              </Route>
              <Route path='edit' element={<EditStock />} />
            </Route>

            <Route path='stock' element={<Outlet />}>
              <Route index element={<Stock />} />
              <Route path='detail/:id' element={<StockDetail />} />
              <Route path='action' element={<StockAction />}>
                <Route path=':id' element={<StockAction />} />
              </Route>
            </Route>

            <Route path='cases' element={<Outlet />}>
              <Route index element={<Cases />} />
              <Route path='action/:path' element={<CasesAction />}>
                <Route path=':id' element={<CasesAction />} />
              </Route>
            </Route>

            <Route path='bank' element={<Outlet />}>
              <Route index element={<Bank />} />
              <Route path='action/:path' element={<BankAction />}>
                <Route path=':id' element={<BankAction />} />
              </Route>
            </Route>

            <Route path='recipe' element={<Outlet />}>
              <Route index element={<Recipes />} />
              <Route path='action/:path' element={<RecipesAction />}>
                <Route path=':id' element={<RecipesAction />} />
              </Route>
            </Route>
            <Route path='checks' element={<Outlet />}>
              <Route index element={<Checks />} />
            </Route>
            <Route path='pos' element={<Outlet />}>
              <Route index element={<PosSettings />} />
            </Route>
            <Route path='bank-receipts' element={<Outlet />}>
              <Route index element={<BankReceipts />} />
              <Route path='action' element={<BankReceiptsAction />}>
                <Route path=':id' element={<BankReceiptsAction />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path='sign-in' element={<SignIn />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
