import { Listbox, Transition } from '@headlessui/react';
import Loading from 'components/loading';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef } from 'react';
import UString from 'utilities/string';
import Response from 'services/response';
import BankReceipt from 'services/bankReceipt';
import Http from 'services/http';
import { useNavigate, useParams } from 'react-router-dom';
import { BankReceiptValidation } from 'validations';
import Spinner from 'components/spinner';
import structures from 'services/structures';
import { toast } from 'react-toastify';

export default function action() {
  const Service = new BankReceipt(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => (id && Fetch.handle(Service.getById(id!)), undefined), [id]);

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(`Dekont ${id ? 'düzenleme' : 'ekleme'} başarılı.`);
      navigate('/bank-receipts');
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(`Dekont ${id ? 'düzenleme' : 'ekleme'} başarısız.`);
      navigate('/bank-receipts');
    }
  }, [Save.error]);

  useEffect(() => {
    if (id && Fetch?.error?.request?.status) {
      navigate('/404');
    }
  }, [Fetch.error || Fetch.data]);

  return (
    <React.Fragment>
      <Loading done={!Fetch.loading} />
      <div className='header-top'>
        <h2 className='text-xl font-medium pl-2'>Dekont {id ? 'Düzenle' : 'Oluştur'}</h2>
      </div>
      <div id='content'>
        <Formik
          innerRef={Form}
          enableReinitialize={true}
          initialValues={{
            description: Fetch.data?.description || String(),
            receiptType: Fetch.data?.receiptType || 1,
            actions: Fetch.data?.actions || Array(),
            receiptFor: Fetch.data?.receiptFor || Array(),
          }}
          validationSchema={BankReceiptValidation}
          onSubmit={(values) =>
            Save.handle(
              id && Fetch.data
                ? Service.update(id, {
                    ...Fetch.data,
                    ...values,
                  })
                : Service.create(values),
            )
          }
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form className='action' onSubmit={handleSubmit}>
              <div className='wrapper'>
                <div className='item'>
                  <div className='w-full'>
                    <label>Dekont Tipi</label>
                    <Listbox
                      value={values.receiptType}
                      onChange={(value) => setFieldValue('receiptType', Number(value))}
                    >
                      <div className='relative mt-1'>
                        <Listbox.Button className='listbox-btn'>
                          <span className='listbox-title'>
                            {(structures.types.receiptTypes as any)[values.receiptType]}
                          </span>
                          <span className='listbox-selector-icon'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className='w-5 h-5 text-gray-400'
                              viewBox='0 0 20 20'
                              fill='currentColor'
                            >
                              <path
                                fillRule='evenodd'
                                d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                clipRule='evenodd'
                              />
                            </svg>
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={React.Fragment}
                          leave='transition ease-in duration-100'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <Listbox.Options className='listbox-options'>
                            {Object.keys(structures.types.receiptTypes).map((type, index) => (
                              <Listbox.Option
                                className={UString.concat_class_name(
                                  values.receiptType === Number(type)
                                    ? 'bg-ebony text-white'
                                    : 'hover:bg-ebony hover:text-white',
                                  'flex items-center cursor-pointer px-6 py-2',
                                )}
                                key={index}
                                value={Number(type)}
                              >
                                <span>{(structures.types.receiptTypes as any)[type]}</span>
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>
                <div className='item'>
                  <div className='w-full'>
                    <label htmlFor='description'>Açıklama</label>
                    <input
                      type='text'
                      name='description'
                      className={UString.concat_class_name(
                        errors.description && touched.description ? 'has-error' : undefined,
                        'my-1',
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Açıklama'
                      value={values.description}
                    />
                    {errors.description && touched.description ? (
                      <span className='error'>* {errors.description}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className='header-bottom'>
        <button
          type='submit'
          disabled={Save.loading}
          className='ml-auto classic-btn'
          onClick={() => Form.current.handleSubmit()}
        >
          {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
        </button>
      </div>
    </React.Fragment>
  );
}
