import { Service as HService } from "services/http"

export default class Service {
    private Http: HService | null = null

    constructor(http: HService) {
        this.Http = http
    }

    public async get(start: number | undefined = undefined, end: number | undefined = undefined) {
        const base: URL = new URL(`${this.Http?.baseURL}/products`);

        if (start !== undefined && !Number.isNaN(start))
            base.searchParams.append("_start", String(start))

        if (end !== undefined && !Number.isNaN(end))
            base.searchParams.append("_end", String(end))

        return await this.Http?.instance.get(base.href);
    }

    public async getById(id: string) {
        return await this.Http?.instance.get(`/product/getbyid/${id}`); // TODO
    }

    public async getByFilter(page: number, perPage: number, search: string | undefined) {
        const base: URL = new URL(`${this.Http?.baseURL}/products/search`);
        if(search !== undefined) {
            base.searchParams.append('search', search);
        }
        base.searchParams.append('page', String(page));
        base.searchParams.append('perPage', String(perPage));
        return await this.Http?.instance.get(base.href);
    }

    public async create(data: any) {
        return await this.Http?.instance.post("/product/create", data) // TODO
    }

    public async update(id: string, data: any) {
        return await this.Http?.instance.put(`/product/update/${id}`, data);// TODO
    }

    public async delete(id: string) {
        return await this.Http?.instance.delete(`/product/delete/${id}`)// TODO
    }
}
