import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Http from "services/http";
import Case from "services/cases";
import Response from "services/response";

import Table from "components/table";
import Modal from "components/modal";
import Loading from "components/loading";
import NetworkError from "components/network-error";
import Spinner from "components/spinner";
import { number } from "yup";

interface IBalance {
  type: number;
  amount: number;
  currency: number;
}

export default function () {
  const Service = new Case(useContext(Http.Context)!);
  const Cases = new Response();
  const Delete = new Response();

  const [modal, setModal] = useState<boolean>(false);
  const [delCase, setDelCase] = useState<any | null>(null);

  useEffect(
    () => (!Cases.data && Cases.handle(Service.get()), undefined),
    [Cases.data]
  );
  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading)
      setTimeout(
        () => (
          (Cases.data = Cases.data.filter(
            (data: any) => data._id !== delCase._id
          )),
          setModal(false)
        ),
        500
      );
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(delCase)), [delCase]);
  useEffect(
    () => (
      !modal && setTimeout(() => (setDelCase(null), (Delete.data = null)), 200),
      undefined
    ),
    [modal]
  );
  return (
    <React.Fragment>
      <div className="header-top">
        <button className="to-gray-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 xl:mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
          <span className="hidden xl:block">İçeri aktar</span>
        </button>
        <button className="to-gray-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 xl:mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          <span className="hidden xl:block">Dışarı aktar</span>
        </button>
        <Link
          to="/cases/action/new"
          className="flex shadow-md items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium ml-auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          <span>Yeni Kasa Oluştur</span>
        </Link>
      </div>
      <div id="content">
        <Modal
          onClose={() => !Delete.loading && setModal(false)}
          state={[modal, setModal]}
        >
          <React.Fragment>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {delCase?.account_name}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Bu Kasayı silmek istediğinize emin misiniz? Bu işlem geri
                      alınamaz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-actions">
              <button
                disabled={Delete.data || Delete.loading}
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => Delete.handle(Service.delete(delCase._id))}
              >
                {Delete.data ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : Delete.loading ? (
                  <Spinner className="h-5 m-auto" />
                ) : (
                  <span>Sil</span>
                )}
              </button>
              <button
                disabled={Delete.loading}
                type="button"
                className="mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto"
                onClick={setModal.bind(null, false)}
              >
                İptal
              </button>
            </div>
          </React.Fragment>
        </Modal>
        <Loading done={!Cases.loading} />
        {!Cases.loading ? (
          <NetworkError
            error={Cases.error}
            retry={() => Cases.handle(Service.get())}
          />
        ) : null}
        {Cases.data && !Cases.loading && !Cases.error && (
          <Table
            data={{
              head: [
                {
                  element: "Kasa Adı",
                  props: { scope: "col" },
                },
                {
                  element: "Bakiye",
                  props: { scope: "col" },
                },
                {
                  element: "Döviz Türü",
                  props: { scope: "col" },
                },
                {
                  element: "Durum",
                  props: { scope: "col" },
                },
                {
                  element: "Oluşturulma Tarihi",
                  props: { scope: "col" },
                },
                {
                  element: "Aksiyonlar",
                  props: { scope: "col" },
                  screenReader: true,
                },
              ],
              body: Cases.data?.sort((a: any, b: any) => new Date(b.createdAt)?.getTime() - new Date(a.createdAt)?.getTime())?.map((cases: any) => ({
                id: cases._id,
                elements: [
                  {
                    element: (
                      <div className="flex items-center">
                        <div className="text-sm font-medium text-gray-900">
                          {cases.account_name}
                        </div>
                      </div>
                    ),
                  },
                  {
                    element: `${cases?.balance[0]?.amount}`,
                    props: {
                      className: "text-left text-sm text-gray-900",
                    },
                  },
                  {
                    element: `${
                      cases?.balance[0]?.currency == 1
                        ? "TL"
                        : null || cases?.balance[0]?.currency == 2
                        ? "USD"
                        : null || cases?.balance[0]?.currency == 3
                        ? "EURO"
                        : null || cases?.balance[0]?.currency == 4
                        ? "GBP"
                        : null
                    }`,
                    props: {
                      className: "text-left text-sm text-gray-900",
                    },
                    
                  },
                  {
                    element: `${cases?.is_open ? "Açık" : "Kapalı"}`,
                    props: {
                      className: `text-left text-sm font-bold ${cases?.is_open ? "text-green-900" : "text-red-900"}`,
                    },
                  },
                  {
                    element: `${new Date(cases?.createdAt)?.toLocaleString()}`,
                    props: {
                      className: "text-left text-sm text-gray-900",
                    },
                  },
                  {
                    element: (
                      <div className="space-x-4">
                        <Link
                          to={`/cases/action/detail/${cases._id}`}
                          className="text-yellow-600 hover:text-yellow-800"
                        >
                          Detay
                        </Link>
                        <Link
                          to={`/cases/action/edit/${cases._id}`}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Düzenle
                        </Link>
                        <button
                          onClick={setDelCase.bind(null, cases)}
                          className="text-red-600 hover:text-red-900"
                        >
                          Sil
                        </button>
                      </div>
                    ),
                    props: {
                      className: "text-right text-sm font-medium space-x-1",
                    },
                  },
                ],
              })),
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
