import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Http from "services/http";
import Recipe from "services/recipe";
import SemiIngradient from "services/semiingradient";
import Response from "services/response";

import Table from "components/table";
import Modal from "components/modal";
import Loading from "components/loading";
import NetworkError from "components/network-error";
import Spinner from "components/spinner";
import { Menu, Transition, Tab } from "@headlessui/react";

export default function () {
  const [data, setData] = useState<any[]>([]);
  const [data2, setData2] = useState<any[]>([]);
  const [allData, setAllData] = useState<any[]>([]);

  const Service = new Recipe(useContext(Http.Context)!);
  const Fetch = new Response();

  const CService = new SemiIngradient(useContext(Http.Context)!);
  const CFetch = new Response();

  const Delete = new Response();

  const [modal, setModal] = useState<boolean>(false);
  const [recipe, setRecipe] = useState<any | null>(null);

  useEffect(
    () => (!Fetch.data && Fetch.handle(Service.get()), undefined),
    [Fetch.data]
  );
  useEffect(
    () => (!CFetch.data && CFetch.handle(CService.get()), undefined),
    [CFetch.data]
  );

  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading)
      setTimeout(
        () => (
          (Fetch.data = Fetch.data.filter(
            (data: any) => data.id !== recipe.id
          )),
          setModal(false)
        ),
        500
      );
  }, [Delete.data, Delete.loading]);

  useEffect(() => {
    Fetch?.data && setData(Fetch.data);
  }, [Fetch.data]);
  useEffect(() => {
    CFetch?.data && setData2(CFetch.data);
  }, [CFetch.data]);
  useEffect(() => (setAllData(data.concat(data2)), undefined), [data, data2]);

  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading)
      setTimeout(() => {
        if (recipe.isSemi) {
          CFetch.data = CFetch.data.filter(
            (data: any) => data.id !== recipe.id
          );
        } else {
          Fetch.data = Fetch.data.filter(
            (data: any) => data._id !== recipe._id
          );
        }
        setModal(false);
      }, 500);
  }, [Delete.data, Delete.loading]);
  useEffect(() => setModal(Boolean(recipe)), [recipe]);
  useEffect(
    () => (
      !modal && setTimeout(() => (setRecipe(null), (Delete.data = null)), 200),
      undefined
    ),
    [modal]
  );

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <React.Fragment>
      <Modal
        onClose={() => !Delete.loading && setModal(false)}
        state={[modal, setModal]}
      >
        <React.Fragment>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {recipe?.title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Bu {recipe?.isSemi ? "yarı mamülü" : "reçeteyi"} silmek
                    istediğinize emin misiniz? Bu işlem geri alınamaz.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-actions">
            <button
              disabled={Delete.data || Delete.loading}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => {
                Delete.handle(
                  recipe.isSemi
                    ? CService.delete(recipe.id)
                    : Service.delete(recipe._id)
                );
              }}
            >
              {Delete.data ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : Delete.loading ? (
                <Spinner className="h-5 m-auto" />
              ) : (
                <span>Sil</span>
              )}
            </button>
            <button
              disabled={Delete.loading}
              type="button"
              className="mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto"
              onClick={setModal.bind(null, false)}
            >
              İptal
            </button>
          </div>
        </React.Fragment>
      </Modal>
      <div className="header-top">
        <button className="to-gray-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 xl:mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
          <span className="hidden xl:block">İçeri aktar</span>
        </button>
        <button className="to-gray-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 xl:mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          <span className="hidden xl:block">Dışarı aktar</span>
        </button>
        <div className="flex flex-col ml-auto relative z-10 shadow-md">
          <Menu>
            <Menu.Button className="flex flex-row font-medium bg-ebony text-white py-3 px-6 rounded-lg text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Yeni Oluştur</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Menu.Button>
            <Menu.Items className="flex w-full shadow-md flex-col absolute right-15 top-12 bg-white rounded-lg text-black">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    className="py-2 px-4 m-2 hover:bg-emerald-500 hover:text-white rounded-lg"
                    to="/recipe/action/recipe"
                  >
                    Reçete
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    className="py-2 px-4 m-2 hover:bg-sky-500 hover:text-white rounded-lg"
                    to="/recipe/action/semi"
                  >
                    Yarım Mamül
                  </Link>
                )}
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>
      <div id="content">
        <Loading done={!Fetch.loading} />
        {!Fetch.loading ? (
          <NetworkError
            error={Fetch.error}
            retry={() => Fetch.handle(Service.get())}
          />
        ) : null}
        <Tab.Group>
          <Tab.List className="flex p-4 space-x-2 rounded-xl bg-white max-w-md">
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium outline-none",
                  selected
                    ? "text-white shadow bg-emerald-500 border-2 border-emerald-500"
                    : "text-emerald-500 hover:bg-emerald-500 hover:text-white border-2 border-emerald-500"
                )
              }
            >
              Reçeteler
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium outline-none",
                  selected
                    ? "text-white shadow bg-sky-500 border-2 border-sky-500"
                    : "text-sky-500 hover:bg-sky-500 hover:text-white border-2 border-sky-500"
                )
              }
            >
              Yarı Mamüller
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel
              className={classNames(
                "rounded-xl bg-white",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none"
              )}
            >
              <Loading done={!Fetch.loading} />
              {!Fetch.loading ? (
                <NetworkError
                  error={Fetch.error}
                  retry={() => Fetch.handle(Service.get())}
                />
              ) : null}
              {Fetch.data && !Fetch.loading && !Fetch.error && (
                <Table
                  data={{
                    head: [
                      {
                        element: "Adı",
                        props: { scope: "col" },
                      },
                      {
                        element: "Aksiyonlar",
                        props: { scope: "col" },
                        screenReader: true,
                      },
                    ],
                    body: Fetch.data.map((recipe: any) => ({
                      id: recipe._id,
                      elements: [
                        {
                          element: (
                            <div className="flex items-center">
                              <div className="text-sm font-medium text-gray-900">
                                {recipe.title}
                              </div>
                            </div>
                          ),
                        },
                        {
                          element: (
                            <>
                              <Link
                                to={`/recipe/action/recipe/${recipe._id}`}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Düzenle
                              </Link>
                              <button
                                onClick={setRecipe.bind(null, recipe)}
                                className="text-red-600 hover:text-red-900"
                              >
                                Sil
                              </button>
                            </>
                          ),
                          props: {
                            className:
                              "text-right text-sm font-medium space-x-6",
                          },
                        },
                      ],
                    })),
                  }}
                />
              )}
            </Tab.Panel>
            <Tab.Panel
              className={classNames(
                "rounded-xl bg-white",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none"
              )}
            >
              <Loading done={!CFetch.loading} />
              {!CFetch.loading ? (
                <NetworkError
                  error={CFetch.error}
                  retry={() => CFetch.handle(Service.get())}
                />
              ) : null}
              {CFetch.data && !CFetch.loading && !CFetch.error && (
                <Table
                  data={{
                    head: [
                      {
                        element: "Adı",
                        props: { scope: "col" },
                      },
                      {
                        element: "Aksiyonlar",
                        props: { scope: "col" },
                        screenReader: true,
                      },
                    ],
                    body: CFetch.data.map((recipe: any) => ({
                      id: recipe.id,
                      elements: [
                        {
                          element: (
                            <div className="flex items-center">
                              <div className="text-sm font-medium text-gray-900">
                                {recipe.title}
                              </div>
                            </div>
                          ),
                        },
                        {
                          element: (
                            <>
                              <Link
                                to={`/recipe/action/semi/${recipe.id}`}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Düzenle
                              </Link>
                              <button
                                onClick={setRecipe.bind(null, {
                                  ...recipe,
                                  isSemi: true,
                                })}
                                className="text-red-600 hover:text-red-900"
                              >
                                Sil
                              </button>
                            </>
                          ),
                          props: {
                            className:
                              "text-right text-sm font-medium space-x-6",
                          },
                        },
                      ],
                    })),
                  }}
                />
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </React.Fragment>
  );
}
