import { Service as HService } from 'services/http';

export default class Service {
  private Http: HService | null = null;

  constructor(http: HService) {
    this.Http = http;
  }

  public async get(start: number | undefined = undefined, end: number | undefined = undefined) {
    const base: URL = new URL(`${this.Http?.baseURL}/invoices`);

    if (start !== undefined && !Number.isNaN(start))
      base.searchParams.append('_start', String(start));

    if (end !== undefined && !Number.isNaN(end)) base.searchParams.append('_end', String(end));

    return await this.Http?.instance.get(base.href);
  }

  public async getById(id: string) {
    return await this.Http?.instance.get(`/invoices/${id}`);
  }

  public async create(data: any) {
    return await this.Http?.instance.post('/invoices', data);
  }

  public async update(id: string, data: any) {
    return await this.Http?.instance.put(`/invoices/${id}`, data);
  }

  public async delete(id: string) {
    return await this.Http?.instance.delete(`/invoices/${id}`);
  }

  public async getByFilter(
    page: number | undefined,
    dataPerPage: number | undefined,
    userId: string,
    userType: string,
  ) {
    const base: URL = new URL(`${this.Http?.baseURL}/invoices/${userId}/${userType}`);

    if (page !== undefined && !Number.isNaN(page)) base.searchParams.append('page', String(page));

    if (dataPerPage !== undefined && !Number.isNaN(dataPerPage))
      base.searchParams.append('perPage', String(dataPerPage));

    return await this.Http?.instance.get(base.href);
  }

  public async getByInvoiceType(
    page: number,
    dataPerPage: number,
    invoiceType: any,
    sort: string,
    paid: number,
  ) {
    const base: URL = new URL(`${this.Http?.baseURL}/invoice/get`);

    if (page !== undefined && !Number.isNaN(page)) base.searchParams.append('page', String(page));

    if (dataPerPage !== undefined && !Number.isNaN(dataPerPage))
      base.searchParams.append('perPage', String(dataPerPage));

    if (invoiceType !== undefined) base.searchParams.append('type', String(invoiceType));

    if (dataPerPage !== undefined && !Number.isNaN(dataPerPage))
      base.searchParams.append('sort', String(sort));

    if (dataPerPage !== undefined && !Number.isNaN(dataPerPage))
      base.searchParams.append('paid', String(paid));

    return await this.Http?.instance.get(base.href);
  }

  public async payInvoice(invoiceId: string, caseId: string, totalAmount: number) {
    return await this.Http?.instance.post('/invoice/pay', {
      invoiceId,
      caseId,
      totalAmount,
    });
  }

  public async cancelPayment(invoiceId: string, paymentId: string) {
    return await this.Http?.instance.delete(`/invoice/payment/${invoiceId}/${paymentId}`);
  }

  public async undoCancelledPayment(invoiceId: string, paymentId: string) {
    return await this.Http?.instance.post(`/invoice/payment/${invoiceId}/${paymentId}`);
  }

  public async createRebateInvoice(rebateId: string, data: any) {
    return await this.Http?.instance.post(`/invoices/${rebateId}`, data);
  }
}
