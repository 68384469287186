export default {
  types: {
    currency_opt: {
      1: 'TL',
      2: 'USD',
      3: 'EURO',
      4: 'GBP',
    },
    quantity: {
      1: 'Adet',
      2: 'Kilo',
      3: 'Gram',
      4: 'Litre',
    },
    payment_type: {
      1: 'Nakit',
      2: 'Çek',
      3: 'Havale / Eft',
      4: 'Kredi Kartı / Banka Kartı',
      5: 'Diğer',
    },
    card_opt: {
      1: 'Müşteri',
      2: 'Tedarikçi',
      3: 'Müşteri + Tedarikçi',
      4: 'Çalışan',
    },
    company_opt: {
      1: 'Tüzel Kişi',
      2: 'Gerçek Kişi',
    },
    invoice_types: {
      1: 'Yeni alış',
      2: 'Yeni alış iade',
      3: 'Yeni satış',
      4: 'Yeni satış iade',
    },
    receiptTypes: {
      1: 'Banka İşlemleri',
      2: 'Stok İşlemleri',
      3: 'Para İşlemleri',
      4: 'Genel',
    },
    roles: [
      { id: 'pos', label: 'Pos kullanıcısı' },
      { id: 'waiter', label: 'Garson' },
      { id: 'kitchen', label: 'Mutfak' },
      { id: 'accounting', label: 'Muhasebe' },
      { id: 'delivery', label: 'Kurye' },
    ],
    permissions: {
      pos: [
        { id: 501, label: 'Sipariş oluştur' },
        { id: 502, label: 'Siparişe ürün ekle' },
        { id: 503, label: 'Sipariş güncelle' },
        { id: 504, label: 'Sipariş sil' },
        { id: 506, label: 'Kuver oluştur' },
        { id: 507, label: 'Ödeme al' },
        { id: 508, label: 'Masa taşı' },
      ],
    },
  },
};
