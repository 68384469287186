import { useEffect, useState, useContext, useRef } from 'react';
import {
  Box,
  Button,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Select,
  Skeleton,
  Autocomplete,
  TextField,
  IconButton,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { ResponsiveTypography } from 'assets/data-display';
import { Tab } from '@headlessui/react';
import FilterListIcon from '@mui/icons-material/FilterList';

import Http from 'services/http';
import Response from 'services/response';

import Check from 'services/analysisCheck';
import Customer from 'services/customer';

import {
  BoldTableCell,
  CheckTableRow,
  ResponsiveTableCell,
  ResponsiveTableRow,
} from 'assets/table';
import { toast } from 'react-toastify';
import clone from 'clone';
import { blue } from '@mui/material/colors';

export default function () {
  const [checkData, setCheckData] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [tab, setTab] = useState(0);

  //query params
  const sort = useRef('DATE_DESC');
  const documentType = useRef('3');
  const section = useRef('all');
  const receiver = useRef('all');
  const doneStatus = useRef('all');
  const min = useRef(undefined);
  const max = useRef(undefined);

  const [detailDialog, setDetailDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [selectedCheck, setSelectedCheck]: any = useState(null);

  const CheckService = new Check(useContext(Http.Context)!);
  const CheckFetch = new Response();

  const CustomerService = new Customer(useContext(Http.Context)!);
  const UserFetch = new Response();

  useEffect(
    () => (
      CheckFetch.handle(CheckService.get(0, 8, 'all', 'DATE_DESC', 'all', 'all', '3')), undefined
    ),
    [],
  );

  useEffect(() => (UserFetch.handle(CustomerService.getAll()), undefined), []);

  useEffect(() => {
    if (CheckFetch.data) {
      setCheckData(CheckFetch?.data?.data);
      setTotalPage(Math.ceil(CheckFetch?.data?.totalCount / 8));
    }
  }, [CheckFetch.data]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  const handlePageChange = async (val: any) => {
    setPage(val);
    CheckFetch.handle(
      CheckService.get(
        val - 1,
        8,
        doneStatus.current,
        sort.current,
        section.current,
        receiver.current,
        documentType.current,
        min.current,
        max.current,
      ),
    );
  };

  const handleTabChange = async (val: any) => {
    setCheckData([]);
    setTotalPage(0);
    setTab(Number(val));
    switch (val) {
      case 0:
      case '0':
        documentType.current = '3';
        CheckFetch.handle(
          CheckService.get(
            0,
            8,
            doneStatus.current,
            sort.current,
            section.current,
            receiver.current,
            '3',
            min.current,
            max.current,
          ),
        );
        break;
      case 1:
      case '1':
        documentType.current = '1';
        CheckFetch.handle(
          CheckService.get(
            0,
            8,
            doneStatus.current,
            sort.current,
            section.current,
            receiver.current,
            '1',
            min.current,
            max.current,
          ),
        );
        break;
      case 2:
      case '2':
        documentType.current = '2';
        CheckFetch.handle(
          CheckService.get(
            0,
            8,
            doneStatus.current,
            sort.current,
            section.current,
            receiver.current,
            '2',
            min.current,
            max.current,
          ),
        );
        break;
    }
  };

  const DetailDialog = () => {
    const [userModel, setUserModel] = useState('AnalysisCustomer');
    const [action, setAction] = useState('done');
    const [selectedUser, setSelectedUser]: any = useState('');

    useEffect(() => {
      if (UserFetch.data?.length == 0) {
        setSelectedUser(null);
        return;
      }
      let selectedUser = UserFetch?.data?.filter(
        (user: any) => user.userModel == 'AnalysisCustomer',
      )[0]._id;
      setSelectedUser(selectedUser);
    }, []);

    const handleSubmit = async () => {
      if (action == 'delete') {
        try {
          let res = await CheckService.delete(selectedCheck._id);
          if (res?.status == 200) {
            let temp = clone(checkData);
            let index = temp.findIndex((c: any) => c._id == selectedCheck?._id);
            if (index > -1) {
              temp.splice(index, 1);
              setCheckData(temp);
            }
            toast.success('Çek/Senet başarıyla silindi');
            setDetailDialog(false);
          }
        } catch (e: any) {
          toast.error('Bir hata oluştu');
        }
        return;
      }
      try {
        let res = await CheckService.complete(selectedCheck._id, {
          bounced: action == 'bounced',
          user: selectedUser,
          userModel: userModel,
        });
        if (res?.status == 200) {
          if (action == 'bounced') toast.success('Çek başarıyla karşılıksız olarak işaretlendi');
          if (action == 'done') toast.success('Çek başarıyla tamamlandı');
          let temp: any = clone(checkData);
          let index = temp.findIndex((c: any) => c._id == selectedCheck?._id);
          if (index > -1) {
            temp[index] = res.data;
            setCheckData(temp);
          }
          setDetailDialog(false);
        }
      } catch (e: any) {
        toast.error('Bir hata oluştu');
      }
    };

    return (
      <Dialog open={detailDialog} onClose={() => setDetailDialog(false)} fullWidth>
        <DialogTitle>
          <Box
            sx={{
              padding: '1vw',
              borderRadius: '8vw',
              backgroundColor: 'orange',
              width: '60%',
              margin: 'auto',
            }}
          >
            <ResponsiveTypography
              variant='h4'
              sx={{ color: '#fff', fontWeight: 'bold', fontSize: '1.8vw' }}
              textAlign='center'
            >
              {selectedCheck?.documentType == 1 ? 'Çek' : 'Senet'} İşlemleri
            </ResponsiveTypography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Stack direction='row' justifyContent='space-around'>
            <FormControl>
              <FormLabel>İşlem Seçiniz</FormLabel>
              <RadioGroup value={action} onChange={(e: any, value: any) => setAction(value)}>
                <FormControlLabel value='done' control={<Radio />} label='Tamamlandı' />
                <FormControlLabel value='bounced' control={<Radio />} label='Karşılıksız' />
                <FormControlLabel value='delete' control={<Radio />} label='Sil' />
              </RadioGroup>
            </FormControl>
            <Stack direction='column' spacing={2}>
              <FormControl disabled={action == 'delete'}>
                <FormLabel>
                  {selectedCheck?.userModel ? 'Alıcı' : 'Gönderen'} Tipi Seçiniz
                </FormLabel>
                <RadioGroup
                  row
                  value={userModel}
                  onChange={(e: any, value: any) => {
                    switch (value) {
                      case 'AnalysisCustomer':
                        if (
                          UserFetch.data?.filter(
                            (user: any) => user.userModel == 'AnalysisCustomer',
                          ).length == 0
                        ) {
                          setSelectedUser(null);
                        } else {
                          var selectedUser = UserFetch?.data?.filter(
                            (user: any) => user.userModel == 'AnalysisCustomer',
                          )[0]._id;
                          setSelectedUser(selectedUser);
                        }
                        break;
                      case 'Bank':
                        if (
                          UserFetch.data?.filter((user: any) => user.userModel == 'Bank').length ==
                          0
                        ) {
                          setSelectedUser(null);
                        } else {
                          var selectedUser = UserFetch?.data?.filter(
                            (user: any) => user.userModel == 'Bank',
                          )[0]._id;
                          setSelectedUser(selectedUser);
                        }
                        break;
                      case 'AnalysisCase':
                        if (
                          UserFetch.data?.filter((user: any) => user.userModel == 'AnalysisCase')
                            .length == 0
                        ) {
                          setSelectedUser(null);
                        } else {
                          var selectedUser = UserFetch?.data?.filter(
                            (user: any) => user.userModel == 'AnalysisCase',
                          )[0]._id;
                          setSelectedUser(selectedUser);
                        }
                        break;
                      default:
                        break;
                    }
                    setUserModel(value);
                  }}
                >
                  <FormControlLabel value='AnalysisCustomer' control={<Radio />} label='Müşteri' />
                  <FormControlLabel value='Bank' control={<Radio />} label='Banka' />
                  <FormControlLabel value='AnalysisCase' control={<Radio />} label='Kasa' />
                </RadioGroup>
              </FormControl>
              <FormControl disabled={action == 'delete'}>
                <FormLabel>{selectedCheck?.userModel ? 'Alıcı' : 'Gönderen'} Seçiniz</FormLabel>
                {UserFetch?.data ? (
                  <Box>
                    {userModel == 'AnalysisCustomer' ? (
                      <Box>
                        <Autocomplete
                          disabled={action == 'delete'}
                          value={selectedUser}
                          size='small'
                          options={UserFetch?.data
                            ?.filter((user: any) => user.userModel == 'AnalysisCustomer')
                            ?.map((customer: any) => customer._id)}
                          getOptionLabel={(option: any) =>
                            UserFetch?.data?.find((customer: any) => customer._id == option)?.title
                          }
                          onChange={(e: any, value: any) => {
                            setSelectedUser(value);
                          }}
                          clearOnEscape
                          renderInput={(params) => <TextField variant='outlined' {...params} />}
                        />
                      </Box>
                    ) : userModel == 'Bank' ? (
                      <Box>
                        <Autocomplete
                          disabled={action == 'delete'}
                          value={selectedUser}
                          size='small'
                          options={UserFetch?.data
                            ?.filter((user: any) => user.userModel == 'Bank')
                            ?.map((customer: any) => customer._id)}
                          getOptionLabel={(option: any) =>
                            UserFetch?.data?.find((customer: any) => customer._id == option)
                              ?.account_name
                          }
                          onChange={(e: any, value: any) => {
                            setSelectedUser(value);
                          }}
                          clearOnEscape
                          renderInput={(params) => <TextField variant='outlined' {...params} />}
                        />
                      </Box>
                    ) : userModel == 'AnalysisCase' ? (
                      <Box>
                        <Autocomplete
                          disabled={action == 'delete'}
                          value={selectedUser}
                          size='small'
                          options={UserFetch?.data
                            ?.filter((user: any) => user.userModel == 'AnalysisCase')
                            ?.map((customer: any) => customer._id)}
                          getOptionLabel={(option: any) =>
                            UserFetch?.data?.find((customer: any) => customer._id == option)
                              ?.account_name
                          }
                          onChange={(e: any, value: any) => {
                            setSelectedUser(value);
                          }}
                          clearOnEscape
                          renderInput={(params) => <TextField variant='outlined' {...params} />}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ) : null}
              </FormControl>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='success' onClick={handleSubmit}>
            Onayla
          </Button>
          <Button variant='outlined' color='error' onClick={() => setDetailDialog(false)}>
            İptal
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const FilterDialog = () => {
    const [rec, setReceiver] = useState(receiver.current);
    const [user, setUser] = useState(section.current);
    const [dStatus, setDStatus] = useState(doneStatus.current);
    const [sorting, setSorting] = useState(sort.current);
    const [minAmount, setMin] = useState(min.current);
    const [maxAmount, setMax] = useState(max.current);

    const handleChange = (action: string, value: any) => {
      switch (action) {
        case 'receiver':
          setReceiver(value);
          break;
        case 'section':
          setUser(value);
          break;
        case 'min':
          setMin(value);
          break;
        case 'max':
          setMax(value);
          break;
        case 'sort':
          setSorting(value);
          break;
        case 'done':
          setDStatus(value);
          break;
        default:
          break;
      }
    };

    const handleSubmit = () => {
      receiver.current = rec;
      section.current = user;
      doneStatus.current = dStatus;
      sort.current = sorting;
      min.current = minAmount;
      max.current = maxAmount;
      setPage(1);
      CheckFetch.handle(
        CheckService.get(
          0,
          8,
          doneStatus.current,
          sort.current,
          section.current,
          receiver.current,
          documentType.current,
          min.current,
          max.current,
        ),
      );
      setFilterDialog(false);
    };

    return (
      <Dialog open={filterDialog} onClose={() => setFilterDialog(false)} fullWidth>
        <DialogTitle>
          <Box
            sx={{
              padding: '1vw',
              borderRadius: '8vw',
              backgroundColor: blue[700],
              width: '60%',
              margin: 'auto',
            }}
          >
            <ResponsiveTypography
              variant='h4'
              sx={{ color: '#fff', fontWeight: 'bold', fontSize: '1.8vw' }}
              textAlign='center'
            >
              Filtreler
            </ResponsiveTypography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Stack direction='row' justifyContent='space-around' sx={{ marginTop: '1vw' }}>
            <Stack direction='column' spacing={2}>
              <FormControl>
                <InputLabel id='sortLbl'>Sırala</InputLabel>
                <Select
                  label='Sırala'
                  labelId='sortLbl'
                  sx={{ width: '12vw', fontSize: '1vw' }}
                  size='small'
                  value={sorting}
                  onChange={(e: any) => handleChange('sort', e.target.value)}
                >
                  <MenuItem value='DATE_DESC'>Önce en yeniler</MenuItem>
                  <MenuItem value='DATE_ASC'>Önce en eskiler</MenuItem>
                  <MenuItem value='PRICE_DESC'>Fiyata göre azalan</MenuItem>
                  <MenuItem value='PRICE_ASC'>Fiyata göre artan</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id='senderLbl'>Gönderen Tipi</InputLabel>
                <Select
                  label='Gönderen Tipi'
                  labelId='senderLbl'
                  sx={{ width: '8vw', fontSize: '1vw' }}
                  size='small'
                  value={user}
                  onChange={(e: any) => handleChange('section', e.target.value)}
                >
                  <MenuItem value='all'>Hepsi</MenuItem>
                  <MenuItem value='customer'>Müşteri</MenuItem>
                  <MenuItem value='bank'>Banka</MenuItem>
                  <MenuItem value='case'>Kasa</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id='receiverLbl'>Alıcı Tipi</InputLabel>
                <Select
                  label='Alıcı Tipi'
                  labelId='receiverLbl'
                  sx={{ width: '8vw', fontSize: '1vw' }}
                  size='small'
                  value={rec}
                  onChange={(e: any) => handleChange('receiver', e.target.value)}
                >
                  <MenuItem value='all'>Hepsi</MenuItem>
                  <MenuItem value='customer'>Müşteri</MenuItem>
                  <MenuItem value='bank'>Banka</MenuItem>
                  <MenuItem value='case'>Kasa</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack direction='column' spacing={2}>
              <FormControl>
                <InputLabel id='selectLbl'>Durum</InputLabel>
                <Select
                  label='Durum'
                  labelId='selectLbl'
                  sx={{ width: '12vw', fontSize: '1vw' }}
                  size='small'
                  value={dStatus}
                  onChange={(e: any) => handleChange('done', e.target.value)}
                >
                  <MenuItem value='all'>Hepsi</MenuItem>
                  <MenuItem value='done'>Tamamlanan</MenuItem>
                  <MenuItem value='undone'>Tamamlanmayan</MenuItem>
                  <MenuItem value='bounced'>Karşılıksız</MenuItem>
                </Select>
              </FormControl>
              <Stack direction='row' spacing={1}>
                <TextField
                  type='number'
                  label='Minumum'
                  sx={{ width: '8vw', fontSize: '1vw' }}
                  size='small'
                  value={minAmount}
                  onChange={(e: any) => handleChange('min', e.target.value)}
                />
                <TextField
                  type='number'
                  label='Maksimum'
                  sx={{ width: '8vw', fontSize: '1vw' }}
                  size='small'
                  value={maxAmount}
                  onChange={(e: any) => handleChange('max', e.target.value)}
                />
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='success' onClick={handleSubmit}>
            Filtreleri Uygula
          </Button>
          <Button variant='outlined' color='error' onClick={() => setFilterDialog(false)}>
            İptal
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCheckClick = (check: any) => {
    setSelectedCheck(check);
    setDetailDialog(true);
  };

  return (
    <Box sx={{ overflowY: 'scroll' }}>
      <DetailDialog />
      <FilterDialog />
      <Box sx={{ marginLeft: '1vw', marginBottom: '1vw', marginTop: '1vw' }}>
        <ResponsiveTypography sx={{ fontSize: '2vw', fontWeight: 'bold' }} variant='h4'>
          Çekler ve Senetler
        </ResponsiveTypography>
      </Box>
      <hr />
      <Box>
        <Tab.Group onChange={handleTabChange}>
          <Tab.List
            style={{ margin: 'auto' }}
            className='flex space-x-2 p-4 rounded-xl bg-white max-w-md'
          >
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-green-600 border-2 border-green-600'
                    : 'text-green-600 hover:bg-green-600 hover:text-white border-2 border-green-600',
                )
              }
            >
              Tümü
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-blue-600 border-2 border-blue-600'
                    : 'text-blue-600 hover:bg-blue-600 hover:text-white border-2 border-blue-600',
                )
              }
            >
              Çekler
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-orange-600 border-2 border-orange-600'
                    : 'text-orange-600 hover:bg-orange-600 hover:text-white border-2 border-orange-600',
                )
              }
            >
              Senetler
            </Tab>
            <IconButton size='large' onClick={() => setFilterDialog(true)}>
              <FilterListIcon fontSize='inherit' />
            </IconButton>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              {CheckFetch.loading ? (
                <Stack
                  direction='column'
                  spacing={1}
                  sx={{ padding: '1vw', marginTop: '1.5vw', height: '35vw' }}
                >
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                </Stack>
              ) : checkData?.length == 0 ? (
                <ResponsiveTypography
                  sx={{ fontSize: '1.5vw' }}
                  textAlign='center'
                  fontWeight='bold'
                  marginTop='1.5vw'
                  variant='h4'
                >
                  Çek ve Senet işlemi bulunamadı.
                </ResponsiveTypography>
              ) : (
                <Box>
                  <Box>
                    <TableContainer sx={{ padding: '1vw', marginTop: '1.5vw', height: '35vw' }}>
                      <Table>
                        <TableHead>
                          <ResponsiveTableRow
                            sx={{
                              border: '1px solid white',
                              borderRadius: '5vw',
                              backgroundColor: 'green',
                            }}
                          >
                            <BoldTableCell>Tip</BoldTableCell>
                            <BoldTableCell>Durum</BoldTableCell>
                            <BoldTableCell>Toplam Tutar</BoldTableCell>
                            <BoldTableCell>Belge Tarihi</BoldTableCell>
                            <BoldTableCell>Vade Tarihi</BoldTableCell>
                            <BoldTableCell align='center'>Gönderen</BoldTableCell>
                            <BoldTableCell align='center'>Alıcı</BoldTableCell>
                            <BoldTableCell>Oluşturan</BoldTableCell>
                          </ResponsiveTableRow>
                        </TableHead>
                        {checkData ? (
                          <TableBody>
                            {checkData?.map((check: any) => (
                              <CheckTableRow
                                isBounced={check.bounced}
                                isDone={check.isDone}
                                onClick={() => handleCheckClick(check)}
                              >
                                <ResponsiveTableCell>
                                  {check.documentType == 1 ? 'Çek' : 'Senet'}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {check.bounced
                                    ? 'Karşılıksız'
                                    : check.isDone
                                    ? 'Tamamlandı'
                                    : 'Ödeme Bekliyor'}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell>{check.amount} ₺</ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {new Date(check.checkDate)?.toLocaleString()}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {new Date(check.expiryDate)?.toLocaleDateString()}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell align='center'>
                                  {check.user ? check.user?.title || check.user?.account_name : '-'}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell align='center'>
                                  {check.receiver
                                    ? check.receiver?.title || check.receiver?.account_name
                                    : '-'}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {check.creator?.name} {check.creator?.lastname}
                                </ResponsiveTableCell>
                              </CheckTableRow>
                            ))}
                          </TableBody>
                        ) : null}
                      </Table>
                    </TableContainer>
                  </Box>
                  {CheckFetch.data?.totalCount != 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        width: '90%',
                        margin: 'auto',
                        marginTop: '1.3vw',
                      }}
                    >
                      <Pagination
                        sx={{ margin: 'auto' }}
                        onChange={(e, value) => handlePageChange(value)}
                        color='primary'
                        page={page}
                        count={totalPage}
                        size='large'
                      />
                    </div>
                  ) : null}
                </Box>
              )}
            </Tab.Panel>
            <Tab.Panel>
              {CheckFetch.loading ? (
                <Stack
                  direction='column'
                  spacing={1}
                  sx={{ padding: '1vw', marginTop: '1.5vw', height: '35vw' }}
                >
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                </Stack>
              ) : checkData?.length == 0 ? (
                <ResponsiveTypography
                  sx={{ fontSize: '1.5vw' }}
                  textAlign='center'
                  fontWeight='bold'
                  marginTop='1.5vw'
                  variant='h4'
                >
                  Çek işlemi bulunamadı.
                </ResponsiveTypography>
              ) : (
                <Box>
                  <Box>
                    <TableContainer sx={{ padding: '1vw', marginTop: '1.5vw', height: '35vw' }}>
                      <Table>
                        <TableHead>
                          <ResponsiveTableRow
                            sx={{
                              border: '1px solid white',
                              borderRadius: '5vw',
                              backgroundColor: '#3352FF',
                            }}
                          >
                            <BoldTableCell>Açıklama</BoldTableCell>
                            <BoldTableCell>Durum</BoldTableCell>
                            <BoldTableCell>Toplam Tutar</BoldTableCell>
                            <BoldTableCell>Belge Tarihi</BoldTableCell>
                            <BoldTableCell>Vade Tarihi</BoldTableCell>
                            <BoldTableCell align='center'>Gönderen</BoldTableCell>
                            <BoldTableCell align='center'>Alıcı</BoldTableCell>
                            <BoldTableCell>Oluşturan</BoldTableCell>
                          </ResponsiveTableRow>
                        </TableHead>
                        {checkData ? (
                          <TableBody>
                            {checkData?.map((check: any) => (
                              <CheckTableRow
                                isBounced={check.bounced}
                                isDone={check.isDone}
                                onClick={() => handleCheckClick(check)}
                              >
                                <ResponsiveTableCell>{check.description}</ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {check.bounced
                                    ? 'Karşılıksız'
                                    : check.isDone
                                    ? 'Tamamlandı'
                                    : 'Ödeme Bekliyor'}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell>{check.amount} ₺</ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {new Date(check.checkDate)?.toLocaleString()}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {new Date(check.expiryDate)?.toLocaleDateString()}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell align='center'>
                                  {check.user ? check.user?.title || check.user?.account_name : '-'}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell align='center'>
                                  {check.receiver
                                    ? check.receiver?.title || check.receiver?.account_name
                                    : '-'}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {check.creator?.name} {check.creator?.lastname}
                                </ResponsiveTableCell>
                              </CheckTableRow>
                            ))}
                          </TableBody>
                        ) : null}
                      </Table>
                    </TableContainer>
                  </Box>
                  {CheckFetch.data?.totalCount != 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        width: '90%',
                        margin: 'auto',
                        marginTop: '1.3vw',
                      }}
                    >
                      <Pagination
                        sx={{ margin: 'auto' }}
                        onChange={(e, value) => handlePageChange(value)}
                        color='primary'
                        page={page}
                        count={totalPage}
                        size='large'
                      />
                    </div>
                  ) : null}
                </Box>
              )}
            </Tab.Panel>
            <Tab.Panel>
              {CheckFetch.loading ? (
                <Stack
                  direction='column'
                  spacing={1}
                  sx={{ padding: '1vw', marginTop: '1.5vw', height: '35vw' }}
                >
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                </Stack>
              ) : checkData?.length == 0 ? (
                <ResponsiveTypography
                  sx={{ fontSize: '1.5vw' }}
                  textAlign='center'
                  fontWeight='bold'
                  marginTop='1.5vw'
                  variant='h4'
                >
                  Senet işlemi bulunamadı.
                </ResponsiveTypography>
              ) : (
                <Box>
                  <Box>
                    <TableContainer sx={{ padding: '1vw', marginTop: '1.5vw', height: '35vw' }}>
                      <Table>
                        <TableHead>
                          <ResponsiveTableRow
                            sx={{
                              border: '1px solid white',
                              borderRadius: '5vw',
                              backgroundColor: 'orange',
                            }}
                          >
                            <BoldTableCell>Açıklama</BoldTableCell>
                            <BoldTableCell>Durum</BoldTableCell>
                            <BoldTableCell>Toplam Tutar</BoldTableCell>
                            <BoldTableCell>Belge Tarihi</BoldTableCell>
                            <BoldTableCell>Vade Tarihi</BoldTableCell>
                            <BoldTableCell align='center'>Gönderen</BoldTableCell>
                            <BoldTableCell align='center'>Alıcı</BoldTableCell>
                            <BoldTableCell>Oluşturan</BoldTableCell>
                          </ResponsiveTableRow>
                        </TableHead>
                        {checkData ? (
                          <TableBody>
                            {checkData?.map((check: any) => (
                              <CheckTableRow
                                isBounced={check.bounced}
                                isDone={check.isDone}
                                onClick={() => handleCheckClick(check)}
                              >
                                <ResponsiveTableCell>{check.description}</ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {check.bounced
                                    ? 'Karşılıksız'
                                    : check.isDone
                                    ? 'Tamamlandı'
                                    : 'Ödeme Bekliyor'}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell>{check.amount} ₺</ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {new Date(check.checkDate)?.toLocaleString()}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {new Date(check.expiryDate)?.toLocaleDateString()}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell align='center'>
                                  {check.user ? check.user?.title || check.user?.account_name : '-'}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell align='center'>
                                  {check.receiver
                                    ? check.receiver?.title || check.receiver?.account_name
                                    : '-'}
                                </ResponsiveTableCell>
                                <ResponsiveTableCell>
                                  {check.creator?.name} {check.creator?.lastname}
                                </ResponsiveTableCell>
                              </CheckTableRow>
                            ))}
                          </TableBody>
                        ) : null}
                      </Table>
                    </TableContainer>
                  </Box>
                  {CheckFetch.data?.totalCount != 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        width: '90%',
                        margin: 'auto',
                        marginTop: '1.3vw',
                      }}
                    >
                      <Pagination
                        sx={{ margin: 'auto' }}
                        onChange={(e, value) => handlePageChange(value)}
                        color='primary'
                        page={page}
                        count={totalPage}
                        size='large'
                      />
                    </div>
                  ) : null}
                </Box>
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Box>
    </Box>
  );
}
