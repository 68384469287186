import { Service as HService } from 'services/http';

export default class Service {
  private Http: HService | null = null;

  constructor(http: HService) {
    this.Http = http;
  }

  public async get(
    page: number | undefined = undefined,
    perPage: number | undefined = undefined,
    doneStatus: string,
    sort: string,
    section: string,
    receiver: string,
    documentType: string,
    min?: number,
    max?: number,
  ) {
    const base: URL = new URL(`${this.Http?.baseURL}/checks`);

    if (page !== undefined && !Number.isNaN(page)) base.searchParams.append('page', String(page));
    if (doneStatus !== undefined) base.searchParams.append('doneStatus', String(doneStatus));
    if (sort !== undefined) base.searchParams.append('sort', String(sort));
    if (section !== undefined) base.searchParams.append('section', String(section));
    if (receiver !== undefined) base.searchParams.append('receiver', String(receiver));
    if (documentType !== undefined) base.searchParams.append('documentType', String(documentType));
    if (min !== undefined && !Number.isNaN(min)) base.searchParams.append('min', String(min));
    if (max !== undefined && !Number.isNaN(max)) base.searchParams.append('max', String(max));
    if (perPage !== undefined && !Number.isNaN(perPage))
      base.searchParams.append('perPage', String(perPage));

    return await this.Http?.instance.get(base.href);
  }

  public async create(data: any) {
    return await this.Http?.instance.post('/checks', data);
  }

  public async delete(checkId: string) {
    return await this.Http?.instance.delete(`/checks/${checkId}`);
  }

  public async complete(
    checkId: string,
    data: { bounced: boolean; user: string; userModel: string },
  ) {
    return await this.Http?.instance.post(`/checks/${checkId}`, data);
  }
}
