import { Line } from 'react-chartjs-2';

export default function ShowGraph({data,name,}:{
    data:{labels:any,datasets:any};
    name:string;
}) {
    return <div className="flex flex-col border border-gray-200 rounded-lg col-span-4 xl:col-span-2">
        <div className="border-b border-gray-200 p-4">
            <h3 className="text-xl font-medium">{name}</h3>
        </div>
        <div className="p-4">
            <Line options={{
                animation: false,
                responsive: true,
                scales: {
                    x: {
                        ticks: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }} data={{
                labels: data.labels,
                datasets: data.datasets
            }} />
        </div>
    </div>;
}
