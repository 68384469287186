import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Http from 'services/http';
import Warehouse from 'services/warehouse';
import Response from 'services/response';

import Table from 'components/table';
import Modal from 'components/modal';
import Loading from 'components/loading';
import NetworkError from 'components/network-error';
import Spinner from 'components/spinner';
import { Stack } from '@mui/material';

export default function () {
  const Service = new Warehouse(useContext(Http.Context)!);
  const Warehouses = new Response();
  const Delete = new Response();

  const [modal, setModal] = useState<boolean>(false);
  const [section, setSection] = useState<any | null>(null);

  useEffect(
    () => (!Warehouses.data && Warehouses.handle(Service.get()), undefined),
    [Warehouses.data],
  );
  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading)
      setTimeout(
        () => (
          (Warehouses.data = Warehouses.data.filter((data: any) => data.id !== section.id)),
          setModal(false)
        ),
        500,
      );
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(section)), [section]);
  useEffect(
    () => (!modal && setTimeout(() => (setSection(null), (Delete.data = null)), 200), undefined),
    [modal],
  );

  return (
    <React.Fragment>
      <Modal onClose={() => !Delete.loading && setModal(false)} state={[modal, setModal]}>
        <React.Fragment>
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='sm:flex sm:items-start'>
              <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-red-600'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
              </div>
              <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>{section?.title}</h3>
                <div className='mt-2'>
                  <p className='text-sm text-gray-500'>
                    Bu Depoyu silmek istediğinize emin misiniz? Bu işlem geri alınamaz.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-actions'>
            <button
              disabled={Delete.data || Delete.loading}
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={() => Delete.handle(Service.delete(section.id))}
            >
              {Delete.data ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : Delete.loading ? (
                <Spinner className='h-5 m-auto' />
              ) : (
                <span>Sil</span>
              )}
            </button>
            <button
              disabled={Delete.loading}
              type='button'
              className='mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto'
              onClick={setModal.bind(null, false)}
            >
              İptal
            </button>
          </div>
        </React.Fragment>
      </Modal>
      <div className='header-top justify-between'>
        <div className='flex flex-row'>
          <button className='to-gray-btn'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5 xl:mr-2'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z'
                clipRule='evenodd'
              />
            </svg>
            <span className='hidden xl:block'>İçeri aktar</span>
          </button>
          <button className='to-gray-btn'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5 xl:mr-2'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
            <span className='hidden xl:block'>Dışarı aktar</span>
          </button>
        </div>
        <Stack direction='row' spacing={1}>
          {Warehouses?.data?.length == 0 ? null : (
            <Link
              to='/storage/edit'
              className='flex shadow-md items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium'
            >
              <svg
                className='w-[16px] h-[16px] text-gray-800 dark:text-white h-5 w-5 mr-2'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 20 18'
              >
                <path d='M12.687 14.408a3.01 3.01 0 0 1-1.533.821l-3.566.713a3 3 0 0 1-3.53-3.53l.713-3.566a3.01 3.01 0 0 1 .821-1.533L10.905 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V11.1l-3.313 3.308Zm5.53-9.065.546-.546a2.518 2.518 0 0 0 0-3.56 2.576 2.576 0 0 0-3.559 0l-.547.547 3.56 3.56Z' />
                <path d='M13.243 3.2 7.359 9.081a.5.5 0 0 0-.136.256L6.51 12.9a.5.5 0 0 0 .59.59l3.566-.713a.5.5 0 0 0 .255-.136L16.8 6.757 13.243 3.2Z' />
              </svg>
              <span>Stokları Düzenle</span>
            </Link>
          )}
          <Link
            to='/storage/action'
            className='flex shadow-md items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5 mr-2'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                clipRule='evenodd'
              />
            </svg>
            <span>Depo Ekle</span>
          </Link>
        </Stack>
      </div>
      <div id='content'>
        <Loading done={!Warehouses.loading} />
        {!Warehouses.loading ? (
          <NetworkError error={Warehouses.error} retry={() => Warehouses.handle(Service.get())} />
        ) : null}
        {Warehouses.data && !Warehouses.loading && !Warehouses.error && (
          <Table
            data={{
              head: [
                { element: 'Depo', props: { scope: 'col' } },
                { element: 'Oluşturma tarihi', props: { scope: 'col' } },
                {
                  element: 'Aksiyonlar',
                  props: { scope: 'col' },
                  screenReader: true,
                },
              ],
              body: Warehouses.data.map((warehouse: any) => ({
                id: warehouse.id,
                elements: [
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>{warehouse.title}</div>
                      </div>
                    ),
                  },
                  {
                    element: new Date(warehouse.createdAt).toLocaleDateString('tr-TR', {
                      minute: 'numeric',
                      hour: 'numeric',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }),
                    props: { className: 'text-left text-sm text-gray-900' },
                  },
                  {
                    element: (
                      <>
                        <Link
                          to={`/storage/action/${warehouse.id}`}
                          className='text-indigo-600 hover:text-indigo-900'
                        >
                          Düzenle
                        </Link>
                        <button
                          onClick={setSection.bind(null, warehouse)}
                          className='text-red-600 hover:text-red-900'
                        >
                          Sil
                        </button>
                      </>
                    ),
                    props: {
                      className: 'text-right text-sm font-medium space-x-6',
                    },
                  },
                ],
              })),
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
