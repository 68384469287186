import { NavLink } from "react-router-dom"

export interface IItem {
    label: string,
    url: string,
    icon: JSX.Element
}

export default function ({ data }: { data: IItem }) {
    return <NavLink className="menu-item" to={data.url}>
        {data.icon} <span>{data.label}</span>
    </NavLink>
}