import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Transition, Listbox, Tab } from '@headlessui/react';

import Http from 'services/http';
import Bank from 'services/bank';
import Response from 'services/response';
import Structures from 'services/structures';

import Loading from 'components/loading';
import Spinner from 'components/spinner';

import UString from 'utilities/string';
import MoneyIn from 'services/moneyIn';
import MoneyOut from 'services/moneyOut';
import Customer from 'services/customer';
import MoneyTransfers from 'services/money-transfers';
import Case from 'services/cases';
import Invoice from 'services/invoice';

import { BankValidation, MoneyInValidation, MoneyOutValidation } from 'validations';

import {
  Stack,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  TextField,
  Autocomplete,
  Pagination,
  Skeleton,
  Typography,
  Table as MTable,
  TableContainer,
  TableBody,
  TableHead,
} from '@mui/material';

import { toast } from 'react-toastify';
import { InvoiceTableRow, ResponsiveTableCell, ResponsiveTableRow } from 'assets/table';

export default function () {
  const [moneyData, setMoneyData] = useState<any>([]);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [transfersMoneyData, setTransfersMoneyData] = useState<any>([]);

  const Service = new Bank(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();

  const CService = new MoneyIn(useContext(Http.Context)!);
  const CFetch = new Response();

  const OService = new MoneyOut(useContext(Http.Context)!);
  const OFetch = new Response();

  const CustomerService = new Customer(useContext(Http.Context)!);
  const Customers = new Response();
  const CustomerFetch = new Response();

  const BankService = new Bank(useContext(Http.Context)!);
  const BankFetch = new Response();

  const IService = new Invoice(useContext(Http.Context)!);
  const Invoices = new Response();

  const CaseService = new Case(useContext(Http.Context)!);
  const CaseFetch = new Response();

  const MoneyService = new MoneyTransfers(useContext(Http.Context)!);
  const MoneyFetch = new Response();
  const MoneyInvoiceFetch = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();

  const { id, path } = useParams();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => (id && Fetch.handle(Service.getById(id!)), undefined), [id]);

  useEffect(() => (id && CFetch.handle(CService.getById(id!)), undefined), [id]);

  useEffect(() => (id && OFetch.handle(OService.getById(id!)), undefined), [id]);

  useEffect(() => (id && Customers.handle(CustomerService.getAll()), undefined), [id]);

  useEffect(
    () => (!CustomerFetch.data && CustomerFetch.handle(CustomerService.get()), undefined),
    [CustomerFetch.data],
  );

  useEffect(() => (id && MoneyFetch.handle(MoneyService.getAll(0, 8, id, '2')), undefined), [id]);

  useEffect(
    () => (
      id && MoneyInvoiceFetch.handle(MoneyService.getAllTransfersAndInvoices(0, 8, id, '2')),
      undefined
    ),
    [id],
  );

  useEffect(() => (id && Invoices.handle(IService.getByFilter(0, 8, id, '2')), undefined), [id]);

  useEffect(
    () => (!CaseFetch.data && CaseFetch.handle(CaseService.getAllOpen()), undefined),
    [CaseFetch.data],
  );

  useEffect(
    () => (!BankFetch.data && BankFetch.handle(BankService.get()), undefined),
    [BankFetch.data],
  );

  useEffect(() => {
    if (MoneyFetch.data) {
      setMoneyData(MoneyFetch?.data?.data);
    }
  }, [MoneyFetch.data]);

  useEffect(() => {
    if (Invoices.data) {
      setInvoiceData(Invoices?.data?.data);
    }
  }, [Invoices.data]);

  useEffect(() => {
    if (MoneyInvoiceFetch.data) {
      setTransfersMoneyData(MoneyInvoiceFetch?.data?.data);
      setTotalPage(getPages(0));
    }
  }, [MoneyInvoiceFetch.data]);

  useEffect(() => {
    if (Save.data && !Save.error) {
      if (path == 'new' || path == 'edit') {
        toast.success(`Banka ${id ? 'düzenleme' : 'ekleme'} başarılı.`);
        navigate('/bank');
      } else {
        toast.success(`Para ${path == 'addMoney' ? 'ekleme' : 'çekme'} başarılı.`);
        navigate('/bank');
      }
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      if (path == 'new' || path == 'edit') {
        toast.error(`Banka ${id ? 'düzenleme' : 'ekleme'} başarısız.`);
        navigate('/bank');
      } else {
        toast.error(`Para ${path == 'addMoney' ? 'ekleme' : 'çekme'} başarısız. (${Save.error})`);
        navigate('/bank');
      }
    }
  }, [Save.error]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  const getPages = (type: number) => {
    var pageCount: number = 0;
    switch (type) {
      case 0:
        var count = MoneyInvoiceFetch?.data?.totalCount;
        var pageCount = Math.ceil(count / 8);
        break;
      case 1:
        var count = Invoices?.data?.totalCount;
        var pageCount = Math.ceil(count / 8);
        break;
      case 2:
        var count = MoneyFetch?.data?.totalCount;
        var pageCount = Math.ceil(count / 8);
        break;
      default:
        break;
    }
    return pageCount;
  };

  const handlePageChange = (e: any, value: any, type: number) => {
    switch (type) {
      case 0: //Tüm işlemler
        MoneyInvoiceFetch.handle(MoneyService.getAllTransfersAndInvoices(value - 1, 8, id!, '1'));
        if (totalPage != getPages(0)) {
          setTotalPage(getPages(0));
        }
        setPage(value);
        break;
      case 1: //Fatura işlemleri
        Invoices.handle(IService.getByFilter(value - 1, 8, id!, '1'));
        if (totalPage != getPages(1)) {
          setTotalPage(getPages(1));
        }
        setPage(value);
        break;
      case 2: //Para işlemleri
        MoneyFetch.handle(MoneyService.getAll(value - 1, 8, id!, '1'));
        if (totalPage != getPages(2)) {
          setTotalPage(getPages(2));
        }
        setPage(value);
        break;
      case 3:
        break;
      default:
        break;
    }
  };

  const getCustomer = (data: any) => {
    if (data.process_type == 1) {
      if (data.section_id == id) {
        let customer = Customers?.data?.find((customer: any) => customer._id == data.sender);
        return customer?.account_name || customer?.company;
      }
      let customer = Customers?.data?.find((customer: any) => customer._id == data.section_id);
      return customer?.account_name || customer?.company;
    }
    if (data.section_id == id) {
      let customer = Customers?.data?.find((customer: any) => customer._id == data.receiver);
      return customer?.account_name || customer?.company;
    }
    let customer = Customers?.data?.find((customer: any) => customer._id == data.section_id);
    return customer?.account_name || customer?.company;
  };

  if (path == 'detail') {
    return (
      <React.Fragment>
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Banka Detayı</h2>
          <div className='ml-auto flex space-x-2'>
            <Link
              className='flex items-center text-white bg-blue-600 py-3 px-6 rounded-lg text-sm font-medium ml-auto'
              to={`/bank/action/withdrawMoney/${id}`}
            >
              <span>Ödeme Al</span>
            </Link>
            <Link
              className='flex items-center text-white bg-red-600 py-3 px-6 rounded-lg text-sm font-medium ml-auto'
              to={`/bank/action/addMoney/${id}`}
            >
              <span>Ödeme Yap</span>
            </Link>
          </div>
        </div>
        <div id='content' className='flex flex-row'>
          <div className='flex-1 space-y-8'>
            <div>
              <ul className='flex flex-col divide divide-y'>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Hesap Adı:</span>
                  <span>{Fetch.data?.account_name}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Banka Adı: </span>
                  <span>{Fetch.data?.bank_name}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>IBAN: </span>
                  <span>{Fetch.data?.iban_no}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Açılış Bakiyesi:</span>
                  <span>{Fetch.data?.opening_balance || 0}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Alınan Para:</span>
                  <span>{Fetch.data?.amount_given || 0}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Verilen Para:</span>
                  <span>{Fetch.data?.amount_received || 0}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Mevcut Bakiye: </span>
                  <span>{Number(Fetch.data?.balance[0]?.amount || 0)}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Durum:</span>
                  {Fetch.data?.balance[0]?.amount > 0 ? (
                    <span style={{ fontWeight: 'bold', color: 'red' }}>
                      Bu kişinin alacağı var.
                    </span>
                  ) : Fetch.data?.balance[0]?.amount < 0 ? (
                    <span style={{ fontWeight: 'bold', color: 'green' }}>
                      Bu kişinin borcu var.
                    </span>
                  ) : (
                    <span>Bu kişinin borcu yok.</span>
                  )}
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Döviz Türü: </span>
                  <span>
                    {(Structures.types.currency_opt as any)[Fetch.data?.balance[0].currency]}
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <div className='p-4'>
                <Tab.Group>
                  <Tab.List className='flex space-x-2 p-4 rounded-xl bg-white max-w-md'>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                          selected
                            ? 'text-white shadow bg-green-600 border-2 border-green-600'
                            : 'text-green-600 hover:bg-green-600 hover:text-white border-2 border-green-600',
                        )
                      }
                    >
                      Tümü
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                          selected
                            ? 'text-white shadow bg-blue-600 border-2 border-blue-600'
                            : 'text-blue-600 hover:bg-blue-600 hover:text-white border-2 border-blue-600',
                        )
                      }
                    >
                      Fatura İşlemleri
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                          selected
                            ? 'text-white shadow bg-red-600 border-2 border-red-600'
                            : 'text-red-600 hover:bg-red-600 hover:text-white border-2 border-red-600',
                        )
                      }
                    >
                      Para İşlemleri
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className='mt-2'>
                    <Tab.Panel>
                      {MoneyInvoiceFetch?.data ? (
                        <>
                          {MoneyInvoiceFetch?.data?.totalCount == 0 ? (
                            <div style={{ height: '32vw' }}>
                              <Typography textAlign='center' variant='h5' fontWeight='bold'>
                                Müşterinin hiç işlemi bulunamadı.
                              </Typography>
                            </div>
                          ) : (
                            <>
                              <div style={{ height: '32vw' }}>
                                {transfersMoneyData && (
                                  <TableContainer>
                                    <MTable>
                                      <TableHead>
                                        <ResponsiveTableRow>
                                          <ResponsiveTableCell>Açıklama</ResponsiveTableCell>
                                          <ResponsiveTableCell>İşlem Tipi</ResponsiveTableCell>
                                          <ResponsiveTableCell>Tutar</ResponsiveTableCell>
                                          <ResponsiveTableCell>Oluşturan</ResponsiveTableCell>
                                          <ResponsiveTableCell>
                                            Oluşturulma Tarihi
                                          </ResponsiveTableCell>
                                        </ResponsiveTableRow>
                                      </TableHead>
                                      <TableBody>
                                        {transfersMoneyData?.map((invoice: any) => (
                                          <InvoiceTableRow
                                            isPaid={
                                              invoice.total_amount
                                                ? invoice.invoice_types == 1
                                                  ? true
                                                  : false
                                                : (invoice?.process_type == 1 &&
                                                    invoice?.section_id == id) ||
                                                  (invoice?.process_type == 0 &&
                                                    invoice?.receiver?.toString() == id)
                                                ? true
                                                : false
                                            }
                                          >
                                            <ResponsiveTableCell>
                                              {invoice.description}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.invoice_types
                                                ? 'Fatura İşlemi'
                                                : 'Para İşlemi'}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {isNaN(invoice.total_amount)
                                                ? invoice.amount?.toFixed(2)
                                                : invoice.total_amount?.toFixed(2)}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.user?.name} {invoice.user?.lastname}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {new Date(invoice.createdAt)?.toLocaleString()}
                                            </ResponsiveTableCell>
                                          </InvoiceTableRow>
                                        ))}
                                      </TableBody>
                                    </MTable>
                                  </TableContainer>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Stack
                            direction='column'
                            spacing={1}
                            alignItems='center'
                            sx={{ height: '32vw' }}
                          >
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={30}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                          </Stack>
                        </>
                      )}
                      {MoneyInvoiceFetch?.data?.totalCount != 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            width: '90%',
                            margin: 'auto',
                            marginTop: '1.3vw',
                          }}
                        >
                          <Pagination
                            sx={{ margin: 'auto' }}
                            onChange={(e, value) => handlePageChange(e, value, 0)}
                            color='primary'
                            page={page}
                            count={totalPage}
                            size='large'
                          />
                        </div>
                      ) : null}
                      <div style={{ height: '0.6vw' }}></div>
                    </Tab.Panel>
                    <Tab.Panel>
                      {Invoices?.data ? (
                        <>
                          {Invoices?.data?.totalCount == 0 ? (
                            <div style={{ height: '32vw' }}>
                              <Typography textAlign='center' variant='h5' fontWeight='bold'>
                                Müşterinin hiç fatura işlemi bulunamadı.
                              </Typography>
                            </div>
                          ) : (
                            <>
                              <div style={{ height: '32vw' }}>
                                {invoiceData && (
                                  <TableContainer>
                                    <MTable>
                                      <TableHead>
                                        <ResponsiveTableRow>
                                          <ResponsiveTableCell>Açıklama</ResponsiveTableCell>
                                          <ResponsiveTableCell>
                                            Fatura Sıra Numarası
                                          </ResponsiveTableCell>
                                          <ResponsiveTableCell>
                                            Fatura Seri Kodu
                                          </ResponsiveTableCell>
                                          <ResponsiveTableCell>Toplam Tutar</ResponsiveTableCell>
                                          <ResponsiveTableCell>Oluşturan</ResponsiveTableCell>
                                          <ResponsiveTableCell>
                                            Oluşturulma Tarihi
                                          </ResponsiveTableCell>
                                        </ResponsiveTableRow>
                                      </TableHead>
                                      <TableBody>
                                        {invoiceData?.map((invoice: any) => (
                                          <InvoiceTableRow isPaid={invoice.invoice_types == 1}>
                                            <ResponsiveTableCell>
                                              {invoice.description}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.invoice_code}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.invoice_no}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.total_amount}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {invoice.user?.name} {invoice.user?.lastname}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {new Date(invoice?.createdAt)?.toLocaleString()}
                                            </ResponsiveTableCell>
                                          </InvoiceTableRow>
                                        ))}
                                      </TableBody>
                                    </MTable>
                                  </TableContainer>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Stack
                            direction='column'
                            spacing={1}
                            alignItems='center'
                            sx={{ height: '32vw' }}
                          >
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={30}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                            <Skeleton
                              variant='rectangular'
                              animation='wave'
                              width='100%'
                              height={50}
                            />
                          </Stack>
                        </>
                      )}
                      {Invoices?.data?.totalCount != 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            width: '90%',
                            margin: 'auto',
                            marginTop: '1.3vw',
                          }}
                        >
                          <Pagination
                            sx={{ margin: 'auto' }}
                            onChange={(e, value) => handlePageChange(e, value, 1)}
                            color='primary'
                            page={page}
                            count={totalPage}
                            size='large'
                          />
                        </div>
                      ) : null}
                      <div style={{ height: '0.6vw' }}></div>
                    </Tab.Panel>
                    <Tab.Panel>
                      {MoneyFetch?.data ? (
                        <>
                          {MoneyFetch?.data?.totalCount == 0 ? (
                            <>
                              <div style={{ height: '32vw' }}>
                                <Typography textAlign='center' variant='h5' fontWeight='bold'>
                                  Müşterinin hiç para işlemi bulunamadı.
                                </Typography>
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ height: '32vw' }}>
                                {moneyData && (
                                  <TableContainer>
                                    <MTable>
                                      <TableHead>
                                        <ResponsiveTableRow>
                                          <ResponsiveTableCell>Açıklama</ResponsiveTableCell>
                                          <ResponsiveTableCell>Tarih</ResponsiveTableCell>
                                          <ResponsiveTableCell>Tutar</ResponsiveTableCell>
                                          <ResponsiveTableCell>Alıcı/Gönderen</ResponsiveTableCell>
                                          <ResponsiveTableCell>
                                            Oluşturulma Tarihi
                                          </ResponsiveTableCell>
                                        </ResponsiveTableRow>
                                      </TableHead>
                                      <TableBody>
                                        {moneyData?.map((moneys: any) => (
                                          <InvoiceTableRow
                                            isPaid={
                                              (moneys?.process_type == 1 &&
                                                moneys?.section_id == id) ||
                                              (moneys?.process_type == 0 &&
                                                moneys?.receiver?.toString() == id)
                                                ? true
                                                : false
                                            }
                                          >
                                            <ResponsiveTableCell>
                                              {moneys.description}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {moneys?.in_time
                                                ? new Date(moneys?.in_time)?.toLocaleDateString()
                                                : new Date(moneys?.out_time)?.toLocaleDateString()}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {moneys.amount?.toFixed(2)}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {getCustomer(moneys)}
                                            </ResponsiveTableCell>
                                            <ResponsiveTableCell>
                                              {new Date(moneys?.createdAt)?.toLocaleString()}
                                            </ResponsiveTableCell>
                                          </InvoiceTableRow>
                                        ))}
                                      </TableBody>
                                    </MTable>
                                  </TableContainer>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <>
                            <Stack
                              direction='column'
                              spacing={1}
                              alignItems='center'
                              sx={{ height: '32vw' }}
                            >
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={30}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                animation='wave'
                                width='100%'
                                height={50}
                              />
                            </Stack>
                          </>
                        </>
                      )}
                      {MoneyFetch?.data?.totalCount != 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            width: '90%',
                            margin: 'auto',
                            marginTop: '1.3vw',
                          }}
                        >
                          <Pagination
                            sx={{ margin: 'auto' }}
                            onChange={(e, value) => handlePageChange(e, value, 2)}
                            color='primary'
                            page={page}
                            count={totalPage}
                            size='large'
                          />
                        </div>
                      ) : null}
                      <div style={{ height: '0.6vw' }}></div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  if (path == 'new' || path == 'edit') {
    return (
      <React.Fragment>
        <Loading done={!Fetch.loading} />
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Banka {id ? 'Düzenle' : 'Ekle'}</h2>
        </div>
        <div id='content'>
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              account_name: Fetch.data?.account_name || String(),
              bank_name: Fetch.data?.bank_name || String(),
              bank_branch: Fetch.data?.bank_branch || String(),
              account_number: Fetch.data?.account_number || Number(),
              iban_no: Fetch.data?.iban_no || String(),
              balance:
                Fetch.data?.balance ||
                Array({
                  amount: Number(),
                  currency: Number(Object.keys(Structures.types.currency_opt)[0]),
                  type: 1,
                }),
            }}
            validationSchema={BankValidation}
            onSubmit={(values) =>
              Save.handle(
                id && Fetch.data
                  ? Service.update(id, {
                      ...Fetch.data,
                      ...values,
                    })
                  : Service.create(values),
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className='action' onSubmit={handleSubmit}>
                <div className='wrapper'>
                  <div className='item'>
                    <div className='w-full'>
                      <label htmlFor='account_name'>Hesap İsmi</label>
                      <input
                        type='text'
                        name='account_name'
                        className={UString.concat_class_name(
                          errors.account_name && touched.account_name ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Hesap İsmi'
                        value={values.account_name}
                      />
                      {errors.account_name && touched.account_name ? (
                        <span className='error'>* {errors.account_name}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='bank_branch'>Banka Şubesi</label>
                      <input
                        type='text'
                        name='bank_branch'
                        className={UString.concat_class_name(
                          errors.bank_branch && touched.bank_branch ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Banka Şubesi'
                        value={values.bank_branch}
                      />
                      {errors.bank_branch && touched.bank_branch ? (
                        <span className='error'>* {errors.bank_branch}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='account_number'>Hesap Numarası</label>
                      <input
                        type='number'
                        name='account_number'
                        className={UString.concat_class_name(
                          errors.account_number && touched.account_number ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Hesap Numarası'
                        value={values.account_number}
                      />
                      {errors.account_number && touched.account_number ? (
                        <span className='error'>* {errors.account_number}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='balance[0].amount'>
                        {id ? <>Bakiye</> : <>Açılış Bakiyesi</>}
                      </label>
                      <input
                        type='number'
                        name='balance[0].amount'
                        className={UString.concat_class_name(
                          errors.balance && touched.balance ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={id ? 'Bakiye' : 'Açılış Bakiyesi'}
                        value={values.balance[0].amount}
                      />
                      {errors.balance && touched.balance ? (
                        <span className='error'>* {errors.balance}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className='item'>
                    <div className='w-full'>
                      <label htmlFor='bank_name'>Banka Adı</label>
                      <input
                        type='text'
                        name='bank_name'
                        className={UString.concat_class_name(
                          errors.bank_name && touched.bank_name ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Banka Adı'
                        value={values.bank_name}
                      />
                      {errors.bank_name && touched.bank_name ? (
                        <span className='error'>* {errors.bank_name}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='iban_no'>IBAN Numarası</label>
                      <input
                        type='text'
                        name='iban_no'
                        className={UString.concat_class_name(
                          errors.iban_no && touched.iban_no ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='IBAN Numarası'
                        value={values.iban_no}
                      />
                      {errors.iban_no && touched.iban_no ? (
                        <span className='error'>* {errors.iban_no}</span>
                      ) : null}
                    </div>
                    <div className='relative w-full'>
                      <label>Döviz Türü</label>
                      <Listbox
                        value={values.balance[0].currency}
                        onChange={(value) => setFieldValue('balance[0].currency', Number(value))}
                      >
                        <div className='relative mt-1'>
                          <Listbox.Button className='listbox-btn'>
                            <span className='listbox-title'>
                              {(Structures.types.currency_opt as any)[values.balance[0].currency]}
                            </span>
                            <span className='listbox-selector-icon'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='w-5 h-5 text-gray-400'
                                viewBox='0 0 20 20'
                                fill='currentColor'
                              >
                                <path
                                  fillRule='evenodd'
                                  d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                  clipRule='evenodd'
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={React.Fragment}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Listbox.Options className='listbox-options'>
                              {Object.keys(Structures.types.currency_opt).map((type, index) => (
                                <Listbox.Option
                                  className={UString.concat_class_name(
                                    values.balance[0].currency === Number(type)
                                      ? 'bg-ebony text-white'
                                      : 'hover:bg-ebony hover:text-white',
                                    'flex items-center cursor-pointer px-6 py-2',
                                  )}
                                  key={index}
                                  value={Number(type)}
                                >
                                  <span>{(Structures.types.currency_opt as any)[type]}</span>
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className='header-bottom'>
          <button
            type='submit'
            disabled={Save.loading}
            className='ml-auto classic-btn'
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
          </button>
        </div>
      </React.Fragment>
    );
  }
  if (path == 'addMoney') {
    return (
      <React.Fragment>
        <Loading done={!CFetch.loading || !CFetch.loading || !CFetch.loading} />
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Ödeme Yap</h2>
        </div>
        <div id='content'>
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              in_time: CFetch.data?.in_time || String(),
              amount: CFetch.data?.amount || Number(),
              description: CFetch.data?.description || String(),
              sender: CFetch.data?.sender || String(),
              senderType: CFetch.data?.senderType || 1,
              process_type: 1,
              section_id: id,
            }}
            validationSchema={MoneyInValidation}
            onSubmit={(values) => Save.handle(BankService.deposit(values))}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className='action' onSubmit={handleSubmit}>
                <div className='wrapper'>
                  <div className='item'>
                    <div className='w-full'>
                      <label htmlFor='in_time'>Tarih ve Saat</label>

                      <input
                        type='datetime-local'
                        name='in_time'
                        className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                          errors.in_time && touched.in_time ? 'has-error' : undefined,
                          'my-1',
                        )}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.in_time}
                      />
                      {errors.in_time && touched.in_time ? (
                        <span className='error'>* {errors.in_time}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='amount'>Tutar</label>
                      <input
                        type='number'
                        name='amount'
                        className={UString.concat_class_name(
                          errors.amount && touched.amount ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Tutar'
                        value={values.amount}
                      />
                      {errors.amount && touched.amount ? (
                        <span className='error'>* {errors.amount}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <FormLabel id='sender-select-label'>Gönderen Tipi</FormLabel>
                      <RadioGroup
                        value={values.senderType}
                        aria-labeledBy='sender-select-label'
                        name='senderType'
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue('sender', '');
                        }}
                      >
                        <Stack direction='row'>
                          <FormControlLabel value={1} control={<Radio />} label='Müşteri' />
                          <FormControlLabel value={2} control={<Radio />} label='Banka' />
                          <FormControlLabel value={3} control={<Radio />} label='Kasa' />
                        </Stack>
                      </RadioGroup>
                    </div>
                    <div className='w-full'>
                      {values.senderType == 1 ? (
                        <Stack direction='column'>
                          <Autocomplete
                            componentName='sender'
                            options={CustomerFetch?.data?.map((customer: any) => ({
                              id: customer.id,
                              label: customer.company || customer.title,
                            }))}
                            getOptionLabel={(option: any) => option.label}
                            onChange={(e: any, value: any) => {
                              setFieldValue('sender', value?.id || '');
                            }}
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField variant='outlined' {...params} label='Müşteri / Çalışan' />
                            )}
                          />
                          {errors.sender && touched.sender ? (
                            <span className='error'>* {errors.sender}</span>
                          ) : null}
                        </Stack>
                      ) : null}
                      {values.senderType == 2 ? (
                        <Stack direction='column'>
                          <Autocomplete
                            componentName='sender'
                            options={BankFetch?.data
                              ?.filter((bank: any) => bank._id != id)
                              ?.map((bank: any) => ({ id: bank._id, label: bank.account_name }))}
                            getOptionLabel={(option: any) => option.label}
                            onChange={(e: any, value: any) => {
                              setFieldValue('sender', value?.id || '');
                            }}
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField variant='outlined' {...params} label='Banka Hesabı' />
                            )}
                          />
                          {errors.sender && touched.sender ? (
                            <span className='error'>* {errors.sender}</span>
                          ) : null}
                        </Stack>
                      ) : null}
                      {values.senderType == 3 ? (
                        <Stack direction='column'>
                          <Autocomplete
                            componentName='sender'
                            options={CaseFetch?.data?.map((_case: any) => _case._id)}
                            getOptionLabel={(option: any) =>
                              CaseFetch?.data?.find((c: any) => c._id == option)?.account_name
                            }
                            onChange={(e: any, value: any) => {
                              setFieldValue('sender', value || '');
                            }}
                            clearOnEscape
                            noOptionsText='Açık kasa bulunamadı'
                            renderInput={(params) => (
                              <TextField variant='outlined' {...params} label='Kasa' />
                            )}
                          />
                          {errors.sender && touched.sender ? (
                            <span className='error'>* {errors.sender}</span>
                          ) : null}
                        </Stack>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='description'>Açıklama</label>
                      <textarea
                        name='description'
                        className={UString.concat_class_name(
                          errors.description && touched.description ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Açıklama'
                        value={values.description}
                      />
                      {errors.description && touched.description ? (
                        <span className='error'>* {errors.description}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className='header-bottom'>
          <button
            type='submit'
            disabled={Save.loading}
            className='ml-auto classic-btn'
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
          </button>
        </div>
      </React.Fragment>
    );
  }
  if (path == 'withdrawMoney') {
    return (
      <React.Fragment>
        <Loading done={!OFetch.loading || !OFetch.loading || !OFetch.loading} />
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Ödeme Al</h2>
        </div>
        <div id='content'>
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              out_time: OFetch.data?.out_time || String(),
              amount: OFetch.data?.amount || String(),
              description: OFetch.data?.description || String(),
              receiver: CFetch.data?.sender || String(),
              receiverType: CFetch.data?.senderType || 1,
              process_type: 0,
              section_id: id,
            }}
            validationSchema={MoneyOutValidation}
            onSubmit={(values) => Save.handle(BankService.withdraw(values))}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className='action' onSubmit={handleSubmit}>
                <div className='wrapper'>
                  <div className='item'>
                    <div className='w-full'>
                      <label htmlFor='out_time'>Tarih ve Saat</label>
                      <input
                        type='datetime-local'
                        name='out_time'
                        className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                          errors.out_time && touched.out_time ? 'has-error' : undefined,
                          'my-1',
                        )}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.out_time}
                      />
                      {errors.out_time && touched.out_time ? (
                        <span className='error'>* {errors.out_time}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='amount'>Tutar</label>
                      <input
                        type='number'
                        name='amount'
                        className={UString.concat_class_name(
                          errors.amount && touched.amount ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Tutar'
                        value={values.amount}
                      />
                      {errors.amount && touched.amount ? (
                        <span className='error'>* {errors.amount}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <FormLabel id='receiver-select-label'>Alıcı Tipi</FormLabel>
                      <RadioGroup
                        value={values.receiverType}
                        aria-labeledBy='receiver-select-label'
                        name='receiverType'
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue('receiver', '');
                        }}
                      >
                        <Stack direction='row'>
                          <FormControlLabel value={1} control={<Radio />} label='Müşteri' />
                          <FormControlLabel value={2} control={<Radio />} label='Banka' />
                          <FormControlLabel value={3} control={<Radio />} label='Kasa' />
                        </Stack>
                      </RadioGroup>
                    </div>
                    <div className='w-full'>
                      {values.receiverType == 1 ? (
                        <Stack direction='column'>
                          <Autocomplete
                            componentName='receiver'
                            options={CustomerFetch?.data?.map((customer: any) => ({
                              id: customer.id,
                              label: customer.company || customer.title,
                            }))}
                            getOptionLabel={(option: any) => option.label}
                            onChange={(e: any, value: any) => {
                              setFieldValue('receiver', value?.id || '');
                            }}
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField variant='outlined' {...params} label='Müşteri / Çalışan' />
                            )}
                          />
                          {errors.receiver && touched.receiver ? (
                            <span className='error'>* {errors.receiver}</span>
                          ) : null}
                        </Stack>
                      ) : null}
                      {values.receiverType == 2 ? (
                        <Stack direction='column'>
                          <Autocomplete
                            componentName='receiver'
                            options={BankFetch?.data
                              ?.filter((bank: any) => bank._id != id)
                              ?.map((bank: any) => ({ id: bank._id, label: bank.account_name }))}
                            getOptionLabel={(option: any) => option.label}
                            onChange={(e: any, value: any) => {
                              setFieldValue('receiver', value?.id || '');
                            }}
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField variant='outlined' {...params} label='Banka Hesabı' />
                            )}
                          />
                          {errors.receiver && touched.receiver ? (
                            <span className='error'>* {errors.receiver}</span>
                          ) : null}
                        </Stack>
                      ) : null}
                      {values.receiverType == 3 ? (
                        <Stack direction='column'>
                          <Autocomplete
                            componentName='receiver'
                            options={CaseFetch?.data?.map((_case: any) => _case?._id)}
                            getOptionLabel={(option: any) =>
                              CaseFetch?.data?.find((c: any) => c._id == option)?.account_name
                            }
                            onChange={(e: any, value: any) => {
                              setFieldValue('receiver', value || '');
                            }}
                            clearOnEscape
                            noOptionsText='Açık kasa bulunamadı'
                            renderInput={(params) => (
                              <TextField variant='outlined' {...params} label='Kasa' />
                            )}
                          />
                          {errors.receiver && touched.receiver ? (
                            <span className='error'>* {errors.receiver}</span>
                          ) : null}
                        </Stack>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='description'>Açıklama</label>
                      <textarea
                        name='description'
                        className={UString.concat_class_name(
                          errors.description && touched.description ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Açıklama'
                        value={values.description}
                      />
                      {errors.description && touched.description ? (
                        <span className='error'>* {errors.description}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className='header-bottom'>
          <button
            type='submit'
            disabled={Save.loading}
            className='ml-auto classic-btn'
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
          </button>
        </div>
      </React.Fragment>
    );
  }

  return <React.Fragment></React.Fragment>;
}
