import { useContext, useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { ArrowCircleLeftIcon, MenuIcon } from "@heroicons/react/solid";

import Authenticate from "services/authenticate";

import SideBar from "components/sidebar";

export default function (): JSX.Element {
  const AuthContext = useContext(Authenticate.Context);
  const Location = useLocation(),
    [sidebar, setSidebar] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(setSidebar.bind(null, false), [Location]);
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div id="layout">
      <SideBar show={sidebar} onClose={setSidebar.bind(null, false)} />
      <main>
        <div id="user-control">
          <div id="user-control-wrapper">
            <button
              onClick={setSidebar.bind(null, !sidebar)}
              className="xl:hidden w-7 h-7"
            >
              <MenuIcon />
            </button>
            <div className="flex ml-2">
              <button type="button" onClick={goBack}>
                <ArrowCircleLeftIcon className="h-10 w-10" />
              </button>
            </div>
            <div id="user">{AuthContext?.User?.email}</div>
          </div>
        </div>
        <Outlet />
      </main>
    </div>
  );
}
