import { Service as HService } from "services/http"

export default class Service {
    private Http: HService | null = null

    constructor(http: HService) {
        this.Http = http
    }

    public async get(start: number | undefined = undefined, end: number | undefined = undefined) {
        const base: URL = new URL(`${this.Http?.baseURL}/analysismoneyouts`);

        if (start !== undefined && !Number.isNaN(start))
            base.searchParams.append("_start", String(start))

        if (end !== undefined && !Number.isNaN(end))
            base.searchParams.append("_end", String(end))

        return await this.Http?.instance.get(base.href);
    }

    public async getById(id: string) {
        return await this.Http?.instance.get(`/analysismoneyouts/${id}`);
    }

    public async create(data: any) {
        return await this.Http?.instance.post("/analysismoneyouts", data)
    }
}
