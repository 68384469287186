import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  IconButton,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import Warehouse from 'services/warehouse';
import Ingredient from 'services/ingradient';
import Response from 'services/response';
import Http from 'services/http';
import Loading from 'components/loading';
import clone from 'clone';
import { ResponsiveTableCell, ResponsiveTableRow } from 'assets/table';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function () {
  const navigate = useNavigate();

  const CService = new Warehouse(useContext(Http.Context)!);
  const CFetch = new Response<any[]>();

  const IService = new Ingredient(useContext(Http.Context)!);
  const IFetch = new Response<any[]>();

  const [selectedMainWarehouse, setSelectedMainWarehouse] = useState('');
  const [selectedAction, setSelectedAction] = useState(1);
  const [selectedSecondWarehouse, setSelectedSecondWarehouse] = useState('');
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [description, setDescription] = useState('');

  const editProducts = () => {
    let products = clone(IFetch.data)?.filter(
      (p: any) =>
        p.warehouse?._id == selectedMainWarehouse &&
        !selectedProducts.find((product: any) => product.id == p.id),
    );
    return products;
  };

  const handleChange = (action: string, value: any) => {
    switch (action) {
      case 'mainWarehouse':
        setSelectedMainWarehouse(value);
        setSelectedSecondWarehouse('');
        setSelectedProducts([]);
        break;
      case 'secondWarehouse':
        setSelectedSecondWarehouse(value);
        break;
      case 'action':
        setSelectedAction(value);
        break;
      case 'selectedProduct':
        setSelectedProduct(value);
        break;
      default:
        break;
    }
  };

  const addProduct = () => {
    let product = editProducts().find((p: any) => p.id == selectedProduct);
    if (!product) return;
    let products = clone(selectedProducts);
    products.push({ id: product.id, title: product.title, stock: product.stock, quantity: 0 });
    setSelectedProducts(products);
    setSelectedProduct('');
  };

  const handleChangeProduct = (product: string, action: string, quantity?: number) => {
    let products = clone(selectedProducts);
    let index = products.findIndex((p: any) => p.id == product);
    if (index > -1) {
      switch (action) {
        case 'delete':
          products.splice(index, 1);
          break;
        case 'quantity':
          if (quantity) {
            if (quantity > products[index].stock) {
              products[index].quantity = products[index].stock;
            } else {
              products[index].quantity = quantity;
            }
          } else {
            products[index].quantity = 0;
          }
          break;
        default:
          break;
      }
    }
    setSelectedProducts(products);
  };

  const handleSubmit = async () => {
    let data = {
      action: selectedAction,
      products: selectedProducts,
      mainWarehouse: selectedMainWarehouse,
      secondWarehouse: selectedSecondWarehouse,
      description: description,
    };
    if (!data.mainWarehouse) {
      toast.warning('Lütfen ana depoyu seçiniz.', { autoClose: 3000, hideProgressBar: false });
      return;
    }

    if (data.action == 2 && !data.secondWarehouse) {
      toast.warning('Lütfen stok aktarımı yapılacak depoyu seçiniz.', {
        autoClose: 3000,
        hideProgressBar: false,
      });
      return;
    }

    if (data.products.length == 0) {
      toast.warning('Lütfen ürün ekleyiniz.', { autoClose: 3000, hideProgressBar: false });
      return;
    }

    const response = await IService.edit(data);
    if (response?.status == 200) {
      toast.success(`Stok ${selectedAction == 1 ? 'silme' : 'transfer etme'} başarılı.`, {
        autoClose: 3000,
        hideProgressBar: false,
      });
      navigate('/storage');
      return;
    }
    toast.error(`Beklenmedik bir hata oluştu. Hata Kodu: ${response?.status}`, {
      autoClose: 3000,
      hideProgressBar: false,
    });
  };

  useEffect(() => (!CFetch.data && CFetch.handle(CService.get()), undefined), [CFetch.data]);
  useEffect(() => (!IFetch.data && IFetch.handle(IService.get()), undefined), [IFetch.data]);
  if (!IFetch.data) return <Loading done={!IFetch.loading} />;
  if (CFetch.data?.length == 0) return <Box>Hiç depo bulunamadı.</Box>;
  return (
    <React.Fragment>
      <div className='header-top'>
        <h2 className='text-xl font-medium pl-2'>Stokları Düzenle</h2>
      </div>
      <Loading done={!CFetch.loading && !IFetch.loading} />
      <Box className='warehouses'>
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{ marginLeft: '2vw', marginTop: '2vw', marginRight: '2vw' }}
        >
          <Box>
            <InputLabel sx={{ fontSize: '1vw' }} id='mainStorageLbl'>
              İşlem Yapılacak Depo
            </InputLabel>
            <Select
              value={selectedMainWarehouse}
              onChange={(e: any) => handleChange('mainWarehouse', e.target.value)}
              sx={{ width: '10vw', fontSize: '1vw', height: '3vw' }}
              labelId='mainStorageLbl'
            >
              {CFetch.data?.map((warehouse: any) => (
                <MenuItem value={warehouse.id}>{warehouse.title}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <FormControl>
              <FormLabel>İşlem Seçiniz</FormLabel>
              <RadioGroup
                value={selectedAction}
                row
                onChange={(e: any) => handleChange('action', e.target.value)}
              >
                <FormControlLabel value={1} control={<Radio />} label='Stok Sil' />
                <FormControlLabel value={2} control={<Radio />} label='Stok Taşı' />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box visibility={selectedAction == 2 ? 'visible' : 'hidden'}>
            <InputLabel sx={{ fontSize: '1vw' }} id='secondStorageLbl'>
              Aktarılacak Depo
            </InputLabel>
            <Select
              value={selectedSecondWarehouse}
              onChange={(e: any) => handleChange('secondWarehouse', e.target.value)}
              sx={{ width: '10vw', fontSize: '1vw', height: '3vw' }}
              labelId='secondStorageLbl'
            >
              {CFetch.data
                ?.filter((warehouse: any) => warehouse.id != selectedMainWarehouse)
                .map((warehouse: any) => (
                  <MenuItem value={warehouse.id}>{warehouse.title}</MenuItem>
                ))}
            </Select>
          </Box>
        </Stack>
      </Box>
      <Box className='products' sx={{ marginLeft: '2vw', marginTop: '2vw', marginRight: '2vw' }}>
        <Stack direction='row' justifyContent='space-between'>
          <Box
            visibility={
              selectedMainWarehouse &&
              (selectedAction == 1 || (selectedAction == 2 && selectedSecondWarehouse))
                ? 'visible'
                : 'hidden'
            }
          >
            <InputLabel sx={{ fontSize: '1vw' }} id='productLbl'>
              Ürün
            </InputLabel>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Select
                value={selectedProduct}
                onChange={(e: any) => handleChange('selectedProduct', e.target.value)}
                sx={{ width: '10vw', fontSize: '1vw', height: '3vw' }}
                labelId='productLbl'
              >
                {editProducts()?.map((product: any) => (
                  <MenuItem value={product.id}>{product.title}</MenuItem>
                ))}
              </Select>
              <Button
                color='inherit'
                variant='contained'
                onClick={addProduct}
                sx={{ width: '6vw', fontSize: '1vw', height: '2vw' }}
              >
                Ekle
              </Button>
            </Stack>
          </Box>
          <Box>
            <InputLabel id='descLbl'>Açıklama</InputLabel>
            <TextField
              multiline
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </Box>
        </Stack>
      </Box>
      <Box className='table' sx={{ width: '60%', margin: 'auto', marginTop: '2vw' }}>
        <TableContainer sx={{ height: '23vw' }}>
          <Table stickyHeader>
            <TableHead>
              <ResponsiveTableRow>
                <ResponsiveTableCell>Ürün</ResponsiveTableCell>
                <ResponsiveTableCell>Stok</ResponsiveTableCell>
                <ResponsiveTableCell>
                  {selectedAction == 1 ? 'Silinecek' : 'Taşınacak'} Miktar
                </ResponsiveTableCell>
                <ResponsiveTableCell></ResponsiveTableCell>
              </ResponsiveTableRow>
            </TableHead>
            <TableBody>
              {selectedProducts.map((product: any) => (
                <ResponsiveTableRow hover>
                  <ResponsiveTableCell>{product.title}</ResponsiveTableCell>
                  <ResponsiveTableCell>{product.stock}</ResponsiveTableCell>
                  <ResponsiveTableCell>
                    <TextField
                      inputProps={{ min: 0 }}
                      value={product.quantity.toString()}
                      type='number'
                      onChange={(e: any) =>
                        handleChangeProduct(product.id, 'quantity', parseInt(e.target.value))
                      }
                      size='small'
                      sx={{ width: '8vw', fontSize: '1vw', margin: 'auto' }}
                    />
                  </ResponsiveTableCell>
                  <ResponsiveTableCell>
                    <IconButton onClick={() => handleChangeProduct(product.id, 'delete')}>
                      <DeleteIcon />
                    </IconButton>
                  </ResponsiveTableCell>
                </ResponsiveTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant='contained'
          color='success'
          sx={{ width: '8vw', fontSize: '1vw', marginLeft: '45vw' }}
          onClick={handleSubmit}
        >
          Kaydet
        </Button>
      </Box>
    </React.Fragment>
  );
}
