import Http from "services/http"
import Authenticate, { Service } from "services/authenticate"

export default function ({ children }: { children: JSX.Element }): JSX.Element {
    return (
        <Authenticate.Context.Provider value={Authenticate.createInstance(Service.getStoredTokens())}>
            <Authenticate.Context.Consumer>
                {
                    value =>
                        <Http.Context.Provider value={Http.createInstance(value?.IsAuthenticated ? { Authorization: value.bearerToken() } : undefined)}>
                            {children}
                        </Http.Context.Provider>
                }
            </Authenticate.Context.Consumer>
        </Authenticate.Context.Provider>
    )
}