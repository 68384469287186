import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Transition, Listbox, Tab } from '@headlessui/react';
import FilterListIcon from '@mui/icons-material/FilterList';
import CancelIcon from '@mui/icons-material/Cancel';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteIcon from '@mui/icons-material/Delete';

import Http from 'services/http';
import Case from 'services/cases';
import Response from 'services/response';
import Structures from 'services/structures';

import Loading from 'components/loading';
import Spinner from 'components/spinner';

import UString from 'utilities/string';
import Table from 'components/table';
import MoneyIn from 'services/moneyIn';
import MoneyOut from 'services/moneyOut';
import Customer from 'services/customer';
import Bank from 'services/bank';
import Invoice from 'services/invoice';
import MoneyTransfers from 'services/money-transfers';

import Modal from 'components/modal';

import { CaseValidation, MoneyInValidation, MoneyOutValidation } from 'validations';

import { toast } from 'react-toastify';
import {
  Stack,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  TextField,
  Autocomplete,
  Skeleton,
  Typography,
  TableContainer,
  Table as MTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  Checkbox,
  Pagination,
} from '@mui/material';
import { orange, green, red, blue } from '@mui/material/colors';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  BoldTableCell,
  InvoiceTableRow,
  ResponsiveTableCell,
  ResponsiveTableRow,
} from 'assets/table';
import { ResponsiveTypography } from 'assets/data-display';
import clone from 'clone';

export default function () {
  const [total, setTotal] = useState<any>(0);
  const [allData, setAllData] = useState<any[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [delCase, setDelCase] = useState<any | null>(null);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [invoiceTotalCount, setInvoiceTotalCount] = useState<number>(0);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<any>();
  const [payOpen, setPayOpen] = useState<boolean>(false);
  const [payDialogType, setPayDialogType] = useState<string>('');
  const [moneyData, setMoneyData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [questionOpen, setQuestionOpen] = useState(false);
  const [selectedTransferId, setSelectedTransfer] = useState('');

  //query params for invoice page
  const invoiceType = useRef(1);
  const sort = useRef('DATE_DESC');
  const paid = useRef(2);
  const invoicePage = useRef(1);
  const [invoicePerPage, setInvocePerPage] = useState(12);

  const Delete = new Response();

  const Service = new Case(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();

  const CService = new MoneyIn(useContext(Http.Context)!);
  const CFetch = new Response();

  const MoneyService = new MoneyTransfers(useContext(Http.Context)!);
  const MoneyFetch = new Response();

  const CustomerService = new Customer(useContext(Http.Context)!);
  const CustomerFetch = new Response();
  const Customers = new Response();

  const IService = new Invoice(useContext(Http.Context)!);
  const IFetch = new Response();

  const BankService = new Bank(useContext(Http.Context)!);
  const BankFetch = new Response();

  const OService = new MoneyOut(useContext(Http.Context)!);
  const OFetch = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();

  const { id, path } = useParams();

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => (id && Fetch.handle(Service.getById(id!)), undefined), [id]);

  useEffect(() => (id && CFetch.handle(CService.getById(id!)), undefined), [id]);

  useEffect(() => (id && OFetch.handle(OService.getById(id!)), undefined), [id]);

  useEffect(() => (id && MoneyFetch.handle(MoneyService.getAll(0, 8, id, '3')), undefined), [id]);

  useEffect(() => (id && Customers.handle(CustomerService.getAll()), undefined), [id]);

  useEffect(
    () => (
      id &&
        IFetch.handle(
          IService.getByInvoiceType(
            invoicePage.current - 1,
            invoicePerPage,
            invoiceType.current,
            sort.current,
            paid.current,
          ),
        ),
      undefined
    ),
    [id],
  );

  useEffect(
    () => (!CustomerFetch.data && CustomerFetch.handle(CustomerService.get()), undefined),
    [CustomerFetch.data],
  );

  useEffect(
    () => (!BankFetch.data && BankFetch.handle(BankService.get()), undefined),
    [BankFetch.data],
  );

  useEffect(() => {
    if (CFetch.data && OFetch.data) {
      let total = 0;
      CFetch.data.map((inf: any) => {
        total += inf.amount;
      });
      OFetch.data.map((out: any) => {
        total -= out.amount;
      });
      setTotal(total);
      setAllData([...CFetch.data, ...OFetch.data]);
    }
  }, [CFetch.data, OFetch.data]);

  useEffect(() => {
    if (IFetch.data) {
      setInvoiceData(IFetch?.data?.data);
      setInvoiceTotalCount(IFetch?.data?.totalCount);
    }
  }, [IFetch?.data]);

  useEffect(() => {
    if (Save.data && !Save.error) {
      if (path == 'new' || path == 'edit') {
        toast.success(`Kasa ${id ? 'düzenleme' : 'ekleme'} başarılı.`);
        navigate('/cases');
      } else {
        toast.success(`Para ${path == 'addMoney' ? 'ekleme' : 'çekme'} başarılı.`);
        navigate('/cases');
      }
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      if (path == 'new' || path == 'edit') {
        toast.error(`Kasa ${id ? 'düzenleme' : 'ekleme'} başarısız.`);
        navigate('/cases');
      } else {
        toast.error(`Para ${path == 'addMoney' ? 'ekleme' : 'çekme'} başarısız. (${Save.error})`);
        navigate('/cases');
      }
    }
  }, [Save.error]);

  useEffect(() => {
    if (MoneyFetch.data) {
      setMoneyData(MoneyFetch?.data?.data);
      setTotalPage(getPages());
    }
  }, [MoneyFetch.data]);

  const closeCase = () => {
    Delete.handle(Service.closeCase(id!));
    setModal(false);
    navigate('/cases');
  };

  const getInvoices = () => {
    IFetch.handle(
      IService.getByInvoiceType(
        invoicePage.current - 1,
        invoicePerPage,
        invoiceType.current,
        sort.current,
        paid.current,
      ),
    );
  };

  const getPages = () => {
    var pageCount: number = 0;
    var count = MoneyFetch?.data?.totalCount;
    var pageCount = Math.ceil(count / 8);
    return pageCount;
  };

  const handlePageChange = (e: any, value: any, type: number) => {
    MoneyFetch.handle(MoneyService.getAll(value - 1, 8, id!, '3'));
    if (totalPage != getPages()) {
      setTotalPage(getPages());
    }
    setPage(value);
  };

  const getCustomer = (data: any) => {
    if (data.process_type == 1) {
      if (data.section_id == id) {
        let customer = Customers?.data?.find((customer: any) => customer._id == data.sender);
        return customer?.account_name || customer?.company;
      }
      let customer = Customers?.data?.find((customer: any) => customer._id == data.section_id);
      return customer?.account_name || customer?.company;
    }
    if (data.section_id == id) {
      let customer = Customers?.data?.find((customer: any) => customer._id == data.receiver);
      return customer?.account_name || customer?.company;
    }
    let customer = Customers?.data?.find((customer: any) => customer._id == data.section_id);
    return customer?.account_name || customer?.company;
  };

  const fetchMoreInvoices = async () => {
    invoicePage.current++;
    let invoices: any = await IService.getByInvoiceType(
      invoicePage.current - 1,
      invoicePerPage,
      invoiceType.current,
      sort.current,
      paid.current,
    );
    setInvoiceData([...invoiceData, ...invoices?.data?.data]);
    setInvoiceTotalCount(invoices?.data?.totalCount);
  };

  const FilterDialog = () => {
    const [sorting, setSort] = useState(sort.current);
    const [paidStatus, setPaid] = useState(paid.current);

    const handleChange = (property: string, value: any) => {
      switch (property) {
        case 'sort':
          setSort(value);
          break;
        case 'paid':
          setPaid(value);
          break;
        default:
          break;
      }
    };

    const handleSubmit = async () => {
      sort.current = sorting;
      paid.current = paidStatus;
      invoicePage.current = 1;
      let invoices: any = await IService.getByInvoiceType(
        invoicePage.current - 1,
        invoicePerPage,
        invoiceType.current,
        sort.current,
        paid.current,
      );
      setInvoiceData(invoices?.data?.data);
      setInvoiceTotalCount(invoices?.data?.totalCount);
      setFilterOpen(false);
    };

    const handleClose = () => {
      setSort(sort.current);
      setPaid(paid.current);
      setFilterOpen(false);
    };

    return (
      <Dialog open={filterOpen} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Box
            sx={{
              padding: '0.8vw',
              margin: 'auto',
              width: '60%',
              backgroundColor: orange[600],
              borderRadius: 8,
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 'bold', color: '#fff' }} textAlign='center'>
              Filtreler
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Stack direction='column' spacing={2}>
              <Stack direction='row' spacing={3} justifyContent='space-around'>
                <FormControl>
                  <FormLabel sx={{ fontSize: '1vw' }}>Ödenme Durumu</FormLabel>
                  <RadioGroup
                    value={paidStatus}
                    onChange={(e: any) => handleChange('paid', e.target.value)}
                  >
                    <FormControlLabel
                      key={0}
                      value={2}
                      control={<Radio />}
                      label={<ResponsiveTypography>Tümü</ResponsiveTypography>}
                    />
                    <FormControlLabel
                      key={1}
                      value={1}
                      control={<Radio />}
                      label={
                        <ResponsiveTypography sx={{ fontSize: '1vw' }}>
                          Ödenenler
                        </ResponsiveTypography>
                      }
                    />
                    <FormControlLabel
                      key={2}
                      value={0}
                      control={<Radio />}
                      label={
                        <ResponsiveTypography sx={{ fontSize: '1vw' }}>
                          Ödenmeyenler
                        </ResponsiveTypography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <Box>
                  <InputLabel id='sortLbl' sx={{ fontSize: '1vw' }}>
                    Sırala
                  </InputLabel>
                  <Select
                    value={sorting}
                    labelId='sortLbl'
                    sx={{ width: '12vw', fontSize: '1vw' }}
                    onChange={(e: any) => handleChange('sort', e.target.value)}
                  >
                    <MenuItem key={0} value='DATE_DESC'>
                      Önce en yeniler
                    </MenuItem>
                    <MenuItem key={1} value='DATE_ASC'>
                      Önce en eskiler
                    </MenuItem>
                    <MenuItem key={2} value='PRICE_ASC'>
                      Fiyata göre artan
                    </MenuItem>
                    <MenuItem key={3} value='PRICE_DESC'>
                      Fiyata göre azalan
                    </MenuItem>
                  </Select>
                </Box>
              </Stack>
              <Stack direction='row' spacing={3} justifyContent='space-around'></Stack>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='success'
            sx={{ width: '12vw', fontSize: '0.9vw', height: '2.3vw', padding: '0.3vw' }}
            onClick={handleSubmit}
          >
            Filtreleri Uygula
          </Button>
          <Button
            variant='outlined'
            color='error'
            sx={{ width: '6vw', height: '2.3vw', fontSize: '0.9vw', padding: '0.3vw' }}
            onClick={handleClose}
          >
            İptal
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const DetailDialog = () => {
    const [showCancelled, setCancelled] = useState(false);

    const getDaysLeftDueDate = (datePaid?: Date) => {
      if (datePaid) {
        let timestamp = Math.abs(
          new Date(datePaid).getTime() - new Date(selectedInvoice?.expiry_date).getTime(),
        );
        let days = Math.round(timestamp / (1000 * 3600 * 24));
        let isPast = new Date() > new Date(selectedInvoice?.expiry_date);
        let isEqual =
          new Date().setHours(0, 0, 0, 0) ==
          new Date(selectedInvoice?.expiry_date).setHours(0, 0, 0, 0);
        return {
          days,
          isPast,
          isEqual,
        };
      }
      let timestamp = Math.abs(
        new Date().getTime() - new Date(selectedInvoice?.expiry_date).getTime(),
      );
      let days = Math.round(timestamp / (1000 * 3600 * 24));
      let isPast = new Date() > new Date(selectedInvoice?.expiry_date);
      let isEqual =
        new Date().setHours(0, 0, 0, 0) ==
        new Date(selectedInvoice?.expiry_date).setHours(0, 0, 0, 0);
      return {
        days,
        isPast,
        isEqual,
      };
    };

    const cancelPayment = async (paymentId: string) => {
      let response = await IService.cancelPayment(selectedInvoice._id, paymentId);
      if (response?.status == 200) {
        let updatedInvoice = response.data;
        let temp = clone(invoiceData);
        let i = temp.findIndex(
          (invoice: any) => invoice._id?.toString() == updatedInvoice?._id?.toString(),
        );
        if (i > -1) {
          temp[i] = updatedInvoice;
          setInvoiceData(temp);
        }
        setSelectedInvoice(updatedInvoice);
        toast.success('Ödeme başarıyla silindi');
        return;
      }
      toast.error('Ödeme silinirken bir hata oluştu');
    };

    const undoCancelledPayment = async (paymentId: string) => {
      let payment = selectedInvoice?.payments?.find((p: any) => p._id?.toString() == paymentId);
      if (!payment) {
        toast.error('Ödeme bulunamadı');
        return;
      }
      if (selectedInvoice.paidAmount + payment.amount > selectedInvoice.total_amount) {
        toast.error('Ödenen tutar, toplam tutardan fazla olacağı için ödeme geri alınamıyor.');
        return;
      }
      let response = await IService.undoCancelledPayment(selectedInvoice._id, paymentId);
      if (response?.status == 200) {
        let updatedInvoice = response.data;
        let temp = clone(invoiceData);
        let i = temp.findIndex(
          (invoice: any) => invoice._id?.toString() == updatedInvoice?._id?.toString(),
        );
        if (i > -1) {
          temp[i] = updatedInvoice;
          setInvoiceData(temp);
        }
        setSelectedInvoice(updatedInvoice);
        toast.success('Ödeme başarıyla geri alındı');
        return;
      }
      toast.error('Ödeme geri alınırken bir hata oluştu');
    };

    return (
      <Dialog open={detailOpen} onClose={() => setDetailOpen(false)} maxWidth='md' fullWidth>
        <DialogTitle>
          <Box
            sx={{
              padding: '0.8vw',
              margin: 'auto',
              width: '60%',
              backgroundColor: blue[600],
              borderRadius: 8,
              boxShadow:
                'rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px',
              border: selectedInvoice?.isPaid ? `2px solid ${green[400]}` : `2px solid ${red[400]}`,
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 'bold', color: '#fff' }} textAlign='center'>
              Fatura Detayları
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Stack direction='column' spacing={3} justifyContent='space-around'>
            <Stack direction='row' spacing={4} justifyContent='space-around' alignItems='center'>
              <TableContainer>
                <MTable>
                  <TableBody>
                    <ResponsiveTableRow>
                      <BoldTableCell variant='head'>Müşteri</BoldTableCell>
                      <ResponsiveTableCell>
                        {selectedInvoice?.customer?.company ||
                          selectedInvoice?.customer?.account_name}
                      </ResponsiveTableCell>
                    </ResponsiveTableRow>
                    <ResponsiveTableRow>
                      <BoldTableCell variant='head'>Müşteri Tipi</BoldTableCell>
                      <ResponsiveTableCell>
                        {selectedInvoice?.customerModel == 'Bank' ? 'Banka' : 'Analiz Müşterisi'}
                      </ResponsiveTableCell>
                    </ResponsiveTableRow>
                    <ResponsiveTableRow>
                      <BoldTableCell variant='head'>Müşteri Bakiyesi</BoldTableCell>
                      <ResponsiveTableCell>
                        {selectedInvoice?.customerModel == 'Bank'
                          ? selectedInvoice?.customer?.balance[0]?.amount
                          : selectedInvoice?.customer?.balance}{' '}
                        ₺
                      </ResponsiveTableCell>
                    </ResponsiveTableRow>
                    <ResponsiveTableRow>
                      <BoldTableCell>Ödenen Tutar</BoldTableCell>
                      <ResponsiveTableCell>
                        {selectedInvoice?.paidAmount?.toFixed(2) || '0.00'} ₺
                      </ResponsiveTableCell>
                    </ResponsiveTableRow>
                    <ResponsiveTableRow>
                      <BoldTableCell>Son Ödeme Tarihi</BoldTableCell>
                      <ResponsiveTableCell>
                        {new Date(selectedInvoice?.expiry_date)?.toLocaleDateString()}
                        {selectedInvoice?.isPaid ? null : (
                          <>
                            {getDaysLeftDueDate().isEqual ? (
                              <FormHelperText sx={{ color: red[600] }}>
                                Son ödeme tarihi bugün.
                              </FormHelperText>
                            ) : getDaysLeftDueDate().isPast ? (
                              <FormHelperText sx={{ color: red[600] }}>
                                Son ödeme tarihinin üzerinden {getDaysLeftDueDate().days} gün
                                geçmiş.
                              </FormHelperText>
                            ) : (
                              <FormHelperText sx={{ color: blue[600] }}>
                                Son ödeme tarihine {getDaysLeftDueDate().days} gün var.
                              </FormHelperText>
                            )}
                          </>
                        )}
                        {!selectedInvoice?.isPaid ? null : (
                          <>
                            {getDaysLeftDueDate(selectedInvoice?.datePaid).isEqual ? (
                              <FormHelperText sx={{ color: green[600] }}>
                                Son ödeme tarihinde ödenmiş.
                              </FormHelperText>
                            ) : getDaysLeftDueDate(selectedInvoice?.datePaid).isPast ? (
                              <FormHelperText sx={{ color: green[600] }}>
                                Son ödeme tarihinden{' '}
                                {getDaysLeftDueDate(selectedInvoice?.datePaid).days} gün sonra
                                ödenmiş.
                              </FormHelperText>
                            ) : (
                              <FormHelperText sx={{ color: green[600] }}>
                                Son ödeme tarihinden{' '}
                                {getDaysLeftDueDate(selectedInvoice?.datePaid).days} gün önce
                                ödenmiş.
                              </FormHelperText>
                            )}
                          </>
                        )}
                      </ResponsiveTableCell>
                    </ResponsiveTableRow>
                    {selectedInvoice?.isPaid ? (
                      <ResponsiveTableRow>
                        <BoldTableCell>Borcun Kapatıldığı Tarih</BoldTableCell>
                        <ResponsiveTableCell>
                          {new Date(selectedInvoice?.datePaid)?.toLocaleString()}
                        </ResponsiveTableCell>
                      </ResponsiveTableRow>
                    ) : null}
                  </TableBody>
                </MTable>
              </TableContainer>
              <TableContainer>
                <MTable>
                  <TableBody>
                    <ResponsiveTableRow>
                      <BoldTableCell>Fatura Sıra No</BoldTableCell>
                      <ResponsiveTableCell>{selectedInvoice?.invoice_no}</ResponsiveTableCell>
                    </ResponsiveTableRow>
                    <ResponsiveTableRow>
                      <BoldTableCell>Fatura Seri No</BoldTableCell>
                      <ResponsiveTableCell>{selectedInvoice?.invoice_code}</ResponsiveTableCell>
                    </ResponsiveTableRow>
                    <ResponsiveTableRow>
                      <BoldTableCell>Fatura Tutarı</BoldTableCell>
                      <ResponsiveTableCell>
                        {selectedInvoice?.total_amount?.toFixed(2)} ₺
                      </ResponsiveTableCell>
                    </ResponsiveTableRow>
                    <ResponsiveTableRow>
                      <BoldTableCell>Sisteme Giriş Tarihi</BoldTableCell>
                      <ResponsiveTableCell>
                        {new Date(selectedInvoice?.createdAt)?.toLocaleString()}
                      </ResponsiveTableCell>
                    </ResponsiveTableRow>
                    <ResponsiveTableRow>
                      <BoldTableCell>Fatura Tarihi</BoldTableCell>
                      <ResponsiveTableCell>
                        {new Date(selectedInvoice?.invoice_time)?.toLocaleString()}
                      </ResponsiveTableCell>
                    </ResponsiveTableRow>
                  </TableBody>
                </MTable>
              </TableContainer>
            </Stack>
            {selectedInvoice?.payments?.length > 0 ? (
              <Stack
                direction='row'
                justifyContent='space-around'
                alignItems='center'
                marginTop='1vw'
              >
                <TableContainer sx={{ margin: 'auto', width: '60%' }}>
                  <Box
                    sx={{
                      padding: '0.4vw',
                      margin: 'auto',
                      width: '40%',
                      backgroundColor: green[600],
                      borderRadius: 8,
                      boxShadow:
                        'rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px',
                      border: `2px solid ${blue[600]}`,
                    }}
                  >
                    <Typography
                      variant='h5'
                      sx={{ fontWeight: 'bold', color: '#fff' }}
                      textAlign='center'
                    >
                      Ödemeler
                    </Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={showCancelled}
                        onChange={(e: any) => {
                          setCancelled(e.target.checked);
                        }}
                      />
                    }
                    label='İptal edilenleri göster'
                  />

                  <MTable>
                    <TableHead>
                      <ResponsiveTableRow>
                        <BoldTableCell></BoldTableCell>
                        <BoldTableCell align='center'>Tutar</BoldTableCell>
                        <BoldTableCell align='center'>Tarih</BoldTableCell>
                        <BoldTableCell align='center'></BoldTableCell>
                      </ResponsiveTableRow>
                    </TableHead>
                    <TableBody>
                      {selectedInvoice?.payments
                        ?.filter((payment: any) => !payment.isCancelled || showCancelled)
                        ?.map((payment: any, index: number) => (
                          <ResponsiveTableRow>
                            <ResponsiveTableCell>
                              {payment.isCancelled ? (
                                <Typography sx={{ color: red[800] }}>İptal Edildi</Typography>
                              ) : null}
                            </ResponsiveTableCell>
                            <ResponsiveTableCell align='center'>
                              {payment.amount?.toFixed(2)} ₺
                            </ResponsiveTableCell>
                            <ResponsiveTableCell align='center'>
                              {new Date(payment.createdAt)?.toLocaleString()}
                            </ResponsiveTableCell>
                            <ResponsiveTableCell align='center'>
                              {payment.isCancelled ? (
                                <IconButton onClick={() => undoCancelledPayment(payment._id)}>
                                  <RestoreIcon />
                                </IconButton>
                              ) : (
                                <IconButton onClick={() => cancelPayment(payment._id)}>
                                  <CancelIcon />
                                </IconButton>
                              )}
                            </ResponsiveTableCell>
                          </ResponsiveTableRow>
                        ))}
                    </TableBody>
                  </MTable>
                </TableContainer>
              </Stack>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='error' onClick={() => setDetailOpen(false)}>
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const PayDialog = () => {
    const [amount, setAmount] = useState<number>(0);

    const handleClose = () => {
      setPayOpen(false);
    };

    const handlePay = async () => {
      let remainingAmount = selectedInvoice?.total_amount - (selectedInvoice.paidAmount || 0);
      if (amount > remainingAmount) {
        toast.warning('Tutar kalan borçtan yüksek olamaz!');
        return;
      }
      let response: any = await IService.payInvoice(selectedInvoice._id, id!, amount);
      if (response?.status == 200) {
        toast.success('Ödeme başarılı');
        updateInvoiceData(response.data);
        setPayOpen(false);
        return;
      }
      toast.error(`Beklenmedik bir hata oluştu. Hata Kodu: ${response.status}`);
    };

    const updateInvoiceData = (invoice: any) => {
      let invoices = clone(invoiceData);
      let findIndex = invoices?.findIndex(
        (i: any) => i._id?.toString() == invoice?._id?.toString(),
      );
      if (findIndex > -1) {
        invoices[findIndex] = invoice;
      }
      setInvoiceData(invoices);
    };

    return (
      <Dialog open={payOpen} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Box
            sx={{
              padding: '0.8vw',
              margin: 'auto',
              width: '60%',
              backgroundColor: payDialogType == 'getPayment' ? green[600] : red[600],
              borderRadius: 8,
              boxShadow:
                'rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px',
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 'bold', color: '#fff' }} textAlign='center'>
              {payDialogType == 'getPayment' ? <>Tahsilat</> : <>Ödeme Yap</>}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Stack
            alignItems='center'
            justifyContent='space-around'
            marginTop='4vw'
            direction='column'
            spacing={3}
          >
            <Stack direction='column' justifyContent='space-around' alignItems='center' spacing={3}>
              <Stack direction='row' spacing={3}>
                <ResponsiveTypography>
                  <strong>Fatura Tutarı: </strong>
                  {selectedInvoice?.total_amount?.toFixed(2)} ₺
                </ResponsiveTypography>
                <ResponsiveTypography>
                  <strong>Ödenen Tutar: </strong>
                  {selectedInvoice?.paidAmount?.toFixed(2) || '0.00'} ₺
                </ResponsiveTypography>
              </Stack>
              <ResponsiveTypography>
                <strong>Kalan Tutar: </strong>
                {(selectedInvoice?.total_amount - (selectedInvoice?.paidAmount || 0))?.toFixed(2)} ₺
              </ResponsiveTypography>
            </Stack>
            <Box>
              <InputLabel id='pLbl' sx={{ fontSize: '1vw' }}>
                {payDialogType == 'getPayment' ? 'Tahsil Edilecek Tutar' : 'Ödenecek Tutar'}
              </InputLabel>
              <Stack direction='row' spacing={1} alignItems='center'>
                <TextField
                  type='number'
                  size='small'
                  value={amount}
                  onChange={(e: any) => setAmount(e.target.value)}
                  sx={{ width: '12vw', fontSize: '1vw' }}
                />
                <span>₺</span>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='success' onClick={handlePay}>
            Onayla
          </Button>
          <Button variant='outlined' color='error' onClick={handleClose}>
            İptal
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleInvoiceClick = (invoice: any) => {
    setSelectedInvoice(invoice);
    setDetailOpen(true);
  };

  const handlePaymentClick = (e: any, type: string, invoice: any) => {
    e.stopPropagation();
    setSelectedInvoice(invoice);
    setPayDialogType(type);
    setPayOpen(true);
  };

  const deleteMoneyTransfer = async () => {
    let response = await MoneyService.deleteMoneyTransfer(selectedTransferId);
    if (response?.status == 200) {
      toast.success('Para transferi başarıyla silindi.', {
        autoClose: 3000,
        hideProgressBar: false,
      });
      Fetch.handle(Service.getById(id!));
      let tempMoneys: any[] = clone(moneyData);
      let i = tempMoneys.findIndex((m: any) => m._id == selectedTransferId);
      if (i > -1) {
        tempMoneys.splice(i, 1);
        setMoneyData(tempMoneys);
      }
      setQuestionOpen(false);
      return;
    }
    setQuestionOpen(false);
    toast.error('Beklenmeyen bir hata oluştu.', { autoClose: 3000, hideProgressBar: false });
  };

  const QuestionDialog = () => {
    return (
      <Dialog open={questionOpen} onClose={() => setQuestionOpen(false)}>
        <DialogTitle>Para işlemini silmek istediğinize emin misiniz?</DialogTitle>
        <DialogContent>Bu işlem geri alınamaz.</DialogContent>
        <DialogActions>
          <Button variant='contained' color='success' onClick={() => deleteMoneyTransfer()}>
            Evet
          </Button>
          <Button variant='outlined' color='error' onClick={() => setQuestionOpen(false)}>
            İptal
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (path == 'new' || path == 'edit') {
    return (
      <React.Fragment>
        <Loading done={!Fetch.loading} />
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Kasa {id ? 'Düzenle' : 'Ekle'}</h2>
        </div>
        <div id='content'>
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              account_name: Fetch.data?.account_name || String(),
              balance:
                Fetch.data?.balance ||
                Array({
                  type: 1,
                  amount: Number(),
                  currency: Number(Object.keys(Structures.types.currency_opt)[0]),
                }),
            }}
            validationSchema={CaseValidation}
            onSubmit={(values) =>
              Save.handle(
                id && Fetch.data
                  ? Service.update(id, {
                      ...Fetch.data,
                      ...values,
                    })
                  : Service.create(values),
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className='action' onSubmit={handleSubmit}>
                <div className='wrapper'>
                  <div className='item'>
                    <div className='w-full'>
                      <label htmlFor='account_name'>Hesap İsmi</label>
                      <input
                        type='text'
                        name='account_name'
                        className={UString.concat_class_name(
                          errors.account_name && touched.account_name ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Hesap İsmi'
                        value={values.account_name}
                      />
                      {errors.account_name && touched.account_name ? (
                        <span className='error'>* {errors.account_name}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='balance[0].amount'>Açılış Bakiyesi</label>
                      <input
                        type='number'
                        name='balance[0].amount'
                        className={UString.concat_class_name(
                          errors.balance && touched.balance ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Açılış Bakiyesi'
                        value={values.balance[0].amount}
                      />
                      {errors.balance && touched.balance ? (
                        <span className='error'>* {errors.balance}</span>
                      ) : null}
                    </div>
                    <div className='relative w-full'>
                      <label>Döviz Türü</label>
                      <Listbox
                        value={values.balance[0].currency}
                        onChange={(value) => setFieldValue('balance[0].currency', Number(value))}
                      >
                        <div className='relative mt-1'>
                          <Listbox.Button className='listbox-btn'>
                            <span className='listbox-title'>
                              {(Structures.types.currency_opt as any)[values.balance[0].currency]}
                            </span>
                            <span className='listbox-selector-icon'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='w-5 h-5 text-gray-400'
                                viewBox='0 0 20 20'
                                fill='currentColor'
                              >
                                <path
                                  fillRule='evenodd'
                                  d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                  clipRule='evenodd'
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={React.Fragment}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Listbox.Options className='listbox-options'>
                              {Object.keys(Structures.types.currency_opt).map((type, index) => (
                                <Listbox.Option
                                  className={UString.concat_class_name(
                                    values.balance[0].currency === Number(type)
                                      ? 'bg-ebony text-white'
                                      : 'hover:bg-ebony hover:text-white',
                                    'flex items-center cursor-pointer px-6 py-2',
                                  )}
                                  key={index}
                                  value={Number(type)}
                                >
                                  <span>{(Structures.types.currency_opt as any)[type]}</span>
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className='header-bottom'>
          <button
            type='submit'
            disabled={Save.loading}
            className='ml-auto classic-btn'
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
          </button>
        </div>
      </React.Fragment>
    );
  }
  if (path == 'detail') {
    return (
      <React.Fragment>
        <QuestionDialog />
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Kasa Detayı</h2>
          <div className='ml-auto flex space-x-2'>
            {Fetch?.data?.is_open ? (
              <>
                <button
                  onClick={() => {
                    setDelCase(Fetch?.data);
                    setModal(true);
                  }}
                  className='flex items-center text-white bg-red-700 py-3 px-6 rounded-lg text-sm font-bold ml-auto'
                >
                  <span>Kasayı Kapat</span>
                </button>
                <Link
                  to={`/cases/action/invoices/${id}`}
                  className='flex items-center text-white bg-green-700 py-3 px-6 rounded-lg text-sm ml-auto'
                >
                  <span>Fatura İşlemleri</span>
                </Link>
              </>
            ) : null}
            <Link
              className='flex items-center text-white bg-blue-600 py-3 px-6 rounded-lg text-sm font-medium ml-auto'
              to={`/cases/action/addMoney/${id}`}
            >
              <span>Ödeme Al</span>
            </Link>
            <Link
              className='flex items-center text-white bg-red-600 py-3 px-6 rounded-lg text-sm font-medium ml-auto'
              to={`/cases/action/withdrawMoney/${id}`}
            >
              <span>Ödeme Yap</span>
            </Link>
          </div>
          <Modal onClose={() => !Delete.loading && setModal(false)} state={[modal, setModal]}>
            <React.Fragment>
              <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 text-red-600'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={1.5}
                        d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                      />
                    </svg>
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <h3 className='text-lg leading-6 font-medium text-gray-900'>
                      {delCase?.account_name}
                    </h3>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>
                        Bu Kasayı kapatmak istediğinize emin misiniz? Bu işlem geri alınamaz.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-actions'>
                <button
                  disabled={Delete.data || Delete.loading}
                  type='button'
                  className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
                  onClick={closeCase}
                >
                  {Delete.data ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-5 w-5'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                    >
                      <path
                        fillRule='evenodd'
                        d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                        clipRule='evenodd'
                      />
                    </svg>
                  ) : Delete.loading ? (
                    <Spinner className='h-5 m-auto' />
                  ) : (
                    <span>Kapat</span>
                  )}
                </button>
                <button
                  disabled={Delete.loading}
                  type='button'
                  className='mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto'
                  onClick={setModal.bind(null, false)}
                >
                  İptal
                </button>
              </div>
            </React.Fragment>
          </Modal>
        </div>
        <div id='content' className='flex flex-row'>
          <div className='flex-1 space-y-8'>
            <div>
              <ul className='flex flex-col divide divide-y'>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Kasa Adı:</span>
                  <span>{Fetch.data?.account_name}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Açılış Bakiyesi: </span>
                  <span>{Number(Fetch.data?.opening_balance || 0)}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Mevcut Bakiye: </span>
                  <span>{Number(Fetch.data?.balance[0]?.amount || 0)}</span>
                </li>
                <li className='px-12 flex space-x-4 py-3'>
                  <span className='font-medium'>Döviz Türü: </span>
                  <span>
                    {(Structures.types.currency_opt as any)[Fetch.data?.balance[0].currency]}
                  </span>
                </li>
              </ul>
            </div>
            {MoneyFetch?.data ? (
              <>
                {MoneyFetch?.data?.totalCount == 0 ? (
                  <>
                    <div style={{ height: '32vw' }}>
                      <Typography textAlign='center' variant='h5' fontWeight='bold'>
                        Kasanın hiç para işlemi yok.
                      </Typography>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ height: '32vw' }}>
                      {moneyData && (
                        <TableContainer>
                          <MTable>
                            <TableHead>
                              <ResponsiveTableRow>
                                <ResponsiveTableCell>Açıklama</ResponsiveTableCell>
                                <ResponsiveTableCell>Tarih</ResponsiveTableCell>
                                <ResponsiveTableCell>Tutar</ResponsiveTableCell>
                                <ResponsiveTableCell>Alıcı/Gönderen</ResponsiveTableCell>
                                <ResponsiveTableCell>Oluşturulma Tarihi</ResponsiveTableCell>
                                <ResponsiveTableCell></ResponsiveTableCell>
                              </ResponsiveTableRow>
                            </TableHead>
                            <TableBody>
                              {moneyData?.map((moneys: any) => (
                                <InvoiceTableRow
                                  isPaid={
                                    (moneys?.process_type == 1 && moneys?.section_id == id) ||
                                    (moneys?.process_type == 0 &&
                                      moneys?.receiver?.toString() == id)
                                      ? true
                                      : false
                                  }
                                >
                                  <ResponsiveTableCell>{moneys.description}</ResponsiveTableCell>
                                  <ResponsiveTableCell>
                                    {moneys?.in_time
                                      ? new Date(moneys?.in_time)?.toLocaleDateString()
                                      : new Date(moneys?.out_time)?.toLocaleDateString()}
                                  </ResponsiveTableCell>
                                  <ResponsiveTableCell>
                                    {moneys.amount?.toFixed(2)}
                                  </ResponsiveTableCell>
                                  <ResponsiveTableCell>{getCustomer(moneys)}</ResponsiveTableCell>
                                  <ResponsiveTableCell>
                                    {new Date(moneys?.createdAt)?.toLocaleString()}
                                  </ResponsiveTableCell>
                                  <ResponsiveTableCell>
                                    <IconButton
                                      color='inherit'
                                      onClick={() => {
                                        setSelectedTransfer(moneys?._id?.toString());
                                        setQuestionOpen(true);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </ResponsiveTableCell>
                                </InvoiceTableRow>
                              ))}
                            </TableBody>
                          </MTable>
                        </TableContainer>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <>
                  <Stack direction='column' spacing={1} alignItems='center' sx={{ height: '32vw' }}>
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={30} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={50} />
                  </Stack>
                </>
              </>
            )}
            {MoneyFetch?.data?.totalCount != 0 ? (
              <div
                style={{
                  display: 'flex',
                  width: '90%',
                  margin: 'auto',
                  marginTop: '1.3vw',
                }}
              >
                <Pagination
                  sx={{ margin: 'auto' }}
                  onChange={(e, value) => handlePageChange(e, value, 2)}
                  color='primary'
                  page={page}
                  count={totalPage}
                  size='large'
                />
              </div>
            ) : null}
            <div style={{ height: '0.6vw' }}></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  if (path == 'invoices') {
    return (
      <div>
        <div className='w-full' style={{ overflowY: 'scroll' }}>
          <div className='w-full'>
            <Tab.Group
              onChange={(value: number) => {
                invoiceType.current = value == 0 ? 1 : 3;
                invoicePage.current = 1;
                getInvoices();
              }}
            >
              <Stack direction='row' spacing={1} sx={{ width: '100%' }} justifyContent='center'>
                <Tab.List
                  className='flex space-x-2 p-4 rounded-xl bg-white'
                  style={{ width: '60%' }}
                >
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                        selected
                          ? 'text-white shadow bg-red-600 border-2 border-red-600'
                          : 'text-red-600 hover:bg-red-600 hover:text-white border-2 border-red-600',
                      )
                    }
                  >
                    Alış Faturaları
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                        selected
                          ? 'text-white shadow bg-green-600 border-2 border-green-600'
                          : 'text-green-600 hover:bg-green-600 hover:text-white border-2 border-green-600',
                      )
                    }
                  >
                    Satış Faturaları
                  </Tab>
                </Tab.List>
                <IconButton size='medium' onClick={() => setFilterOpen(true)}>
                  <FilterListIcon />
                </IconButton>
              </Stack>
              <Tab.Panels className='mt-2'>
                {IFetch?.data ? (
                  <>
                    {invoiceTotalCount == 0 ? (
                      <Typography variant='h4' fontWeight='bold' textAlign='center'>
                        Filtrelere uygun fatura bulunamadı.
                      </Typography>
                    ) : (
                      <>
                        <Tab.Panel>
                          <InfiniteScroll
                            dataLength={invoiceData.length || 0}
                            next={fetchMoreInvoices}
                            loader={
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                              </Box>
                            }
                            hasMore={invoiceData?.length < invoiceTotalCount}
                            style={{ padding: '1vw' }}
                          >
                            <TableContainer sx={{ height: '49vw', overflowY: 'scroll' }}>
                              <MTable>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Fatura Sıra No</TableCell>
                                    <TableCell>Fatura Seri No</TableCell>
                                    <TableCell>Toplam Tutar</TableCell>
                                    <TableCell>Müşteri</TableCell>
                                    <TableCell>Oluşturan</TableCell>
                                    <TableCell>Oluşturulma Tarihi</TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {IFetch?.data &&
                                    invoiceData.map((invoice: any, index: number) => (
                                      <InvoiceTableRow
                                        key={index}
                                        isPaid={invoice?.isPaid}
                                        onClick={() => handleInvoiceClick(invoice)}
                                      >
                                        <TableCell>{invoice.invoice_no}</TableCell>
                                        <TableCell>{invoice.invoice_code}</TableCell>
                                        <TableCell>{invoice.total_amount}</TableCell>
                                        <TableCell>
                                          {invoice.customer?.company ||
                                            invoice.customer?.account_name}
                                        </TableCell>
                                        <TableCell>
                                          {invoice.user?.name} {invoice.user?.lastname}
                                        </TableCell>
                                        <TableCell>
                                          {new Date(invoice.createdAt)?.toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                          {!invoice?.isPaid && !invoice?.isCancelled ? (
                                            <Button
                                              onClick={(e: any) =>
                                                handlePaymentClick(e, 'pay', invoice)
                                              }
                                              variant='contained'
                                              sx={{
                                                fontSize: '0.9vw',
                                                fontWeight: 'bold',
                                                width: '8vw',
                                                backgroundColor: orange[700],
                                                '&:hover': { backgroundColor: orange[600] },
                                              }}
                                            >
                                              ÖDEME YAP
                                            </Button>
                                          ) : null}
                                          {invoice?.isCancelled ? <>İPTAL EDİLDİ</> : null}
                                        </TableCell>
                                      </InvoiceTableRow>
                                    ))}
                                </TableBody>
                              </MTable>
                            </TableContainer>
                          </InfiniteScroll>
                        </Tab.Panel>
                        <Tab.Panel>
                          <InfiniteScroll
                            dataLength={invoiceData.length || 0}
                            next={fetchMoreInvoices}
                            loader={
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                                <Skeleton animation='wave' variant='rectangular' height={60} />
                              </Box>
                            }
                            hasMore={invoiceData?.length < invoiceTotalCount}
                            style={{ padding: '1vw' }}
                          >
                            <TableContainer sx={{ height: '39vw', overflowY: 'scroll' }}>
                              <MTable>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Fatura Sıra No</TableCell>
                                    <TableCell>Fatura Seri No</TableCell>
                                    <TableCell>Toplam Tutar</TableCell>
                                    <TableCell>Müşteri</TableCell>
                                    <TableCell>Oluşturan</TableCell>
                                    <TableCell>Oluşturulma Tarihi</TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody sx={{ overflowY: 'scroll' }}>
                                  {IFetch?.data &&
                                    invoiceData.map((invoice: any, index: number) => (
                                      <InvoiceTableRow
                                        key={index}
                                        isPaid={invoice?.isPaid}
                                        onClick={() => handleInvoiceClick(invoice)}
                                      >
                                        <TableCell>{invoice.invoice_no}</TableCell>
                                        <TableCell>{invoice.invoice_code}</TableCell>
                                        <TableCell>{invoice.total_amount}</TableCell>
                                        <TableCell>
                                          {invoice.customer?.company ||
                                            invoice.customer?.account_name}
                                        </TableCell>
                                        <TableCell>
                                          {invoice.user?.name} {invoice.user?.lastname}
                                        </TableCell>
                                        <TableCell>
                                          {new Date(invoice.createdAt)?.toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                          {!invoice?.isPaid && !invoice?.isCancelled ? (
                                            <Button
                                              variant='contained'
                                              onClick={(e: any) =>
                                                handlePaymentClick(e, 'getPayment', invoice)
                                              }
                                              sx={{
                                                fontSize: '0.9vw',
                                                fontWeight: 'bold',
                                                width: '8vw',
                                                backgroundColor: green[700],
                                                '&:hover': { backgroundColor: green[600] },
                                              }}
                                            >
                                              TAHSİLAT
                                            </Button>
                                          ) : null}
                                          {invoice?.isCancelled ? <>İPTAL EDİLDİ</> : null}
                                        </TableCell>
                                      </InvoiceTableRow>
                                    ))}
                                </TableBody>
                              </MTable>
                            </TableContainer>
                          </InfiniteScroll>
                        </Tab.Panel>
                      </>
                    )}
                  </>
                ) : (
                  <Stack direction='column' spacing={1}>
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={40} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={40} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={40} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={40} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={40} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={40} />
                    <Skeleton variant='rectangular' animation='wave' width='100%' height={40} />
                  </Stack>
                )}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
        <DetailDialog />
        <FilterDialog />
        <PayDialog />
      </div>
    );
  }
  if (path == 'addMoney') {
    return (
      <React.Fragment>
        <Loading done={!CFetch.loading || !CFetch.loading || !CFetch.loading} />
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Para Ekle</h2>
        </div>
        <div id='content'>
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              in_time: CFetch.data?.in_time || String(),
              amount: CFetch.data?.amount || String(),
              description: CFetch.data?.description || String(),
              senderType: CFetch.data?.senderType || 1,
              sender: CFetch.data?.sender || String(),
              process_type: 1,
              section_id: id,
            }}
            validationSchema={MoneyInValidation}
            onSubmit={(values) => Save.handle(CService.create(values))}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className='action' onSubmit={handleSubmit}>
                <div className='wrapper'>
                  <div className='item'>
                    <div className='w-full'>
                      <label htmlFor='in_time'>Tarih ve Saat</label>
                      <input
                        type='datetime-local'
                        name='in_time'
                        className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                          errors.in_time && touched.in_time ? 'has-error' : undefined,
                          'my-1',
                        )}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.in_time}
                      />
                      {errors.in_time && touched.in_time ? (
                        <span className='error'>* {errors.in_time}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='amount'>Tutar</label>
                      <input
                        type='number'
                        name='amount'
                        className={UString.concat_class_name(
                          errors.amount && touched.amount ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Tutar'
                        value={values.amount}
                      />
                      {errors.amount && touched.amount ? (
                        <span className='error'>* {errors.amount}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <FormLabel id='sender-select-label'>Gönderen Tipi</FormLabel>
                      <RadioGroup
                        value={values.senderType}
                        aria-labeledBy='sender-select-label'
                        name='senderType'
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue('sender', '');
                        }}
                      >
                        <Stack direction='row'>
                          <FormControlLabel value={1} control={<Radio />} label='Müşteri' />
                          <FormControlLabel value={2} control={<Radio />} label='Banka' />
                        </Stack>
                      </RadioGroup>
                    </div>
                    <div className='w-full'>
                      {values.senderType == 1 ? (
                        <Stack direction='column'>
                          <Autocomplete
                            componentName='sender'
                            options={CustomerFetch?.data?.map((customer: any) => ({
                              id: customer.id,
                              label: customer.company || customer.title,
                            }))}
                            getOptionLabel={(option: any) => option.label}
                            onChange={(e: any, value: any) => {
                              setFieldValue('sender', value?.id || '');
                            }}
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField variant='outlined' {...params} label='Müşteri / Çalışan' />
                            )}
                          />
                          {errors.sender && touched.sender ? (
                            <span className='error'>* {errors.sender}</span>
                          ) : null}
                        </Stack>
                      ) : null}
                      {values.senderType == 2 ? (
                        <Stack direction='column'>
                          <Autocomplete
                            componentName='sender'
                            options={BankFetch?.data?.map((bank: any) => ({
                              id: bank._id,
                              label: bank.account_name,
                            }))}
                            getOptionLabel={(option: any) => option.label}
                            onChange={(e: any, value: any) => {
                              setFieldValue('sender', value?.id || '');
                            }}
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField variant='outlined' {...params} label='Banka Hesabı' />
                            )}
                          />
                          {errors.sender && touched.sender ? (
                            <span className='error'>* {errors.sender}</span>
                          ) : null}
                        </Stack>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='description'>Açıklama</label>
                      <textarea
                        name='description'
                        className={UString.concat_class_name(
                          errors.description && touched.description ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Açıklama'
                        value={values.description}
                      />
                      {errors.description && touched.description ? (
                        <span className='error'>* {errors.description}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className='header-bottom'>
          <button
            type='submit'
            disabled={Save.loading}
            className='ml-auto classic-btn'
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
          </button>
        </div>
      </React.Fragment>
    );
  }
  if (path == 'withdrawMoney') {
    return (
      <React.Fragment>
        <Loading done={!OFetch.loading || !OFetch.loading || !OFetch.loading} />
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Para Çek</h2>
        </div>
        <div id='content'>
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              out_time: OFetch.data?.out_time || String(),
              amount: OFetch.data?.amount || String(),
              description: OFetch.data?.description || String(),
              process_type: 0,
              section_id: id,
              receiver: OFetch.data?.sender || String(),
              receiverType: OFetch.data?.senderType || 1,
            }}
            validationSchema={MoneyOutValidation}
            onSubmit={(values) => Save.handle(OService.create(values))}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className='action' onSubmit={handleSubmit}>
                <div className='wrapper'>
                  <div className='item'>
                    <div className='w-full'>
                      <label htmlFor='out_time'>Tarih ve Saat</label>
                      <input
                        type='datetime-local'
                        name='out_time'
                        className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                          errors.out_time && touched.out_time ? 'has-error' : undefined,
                          'my-1',
                        )}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.out_time}
                      />
                      {errors.out_time && touched.out_time ? (
                        <span className='error'>* {errors.out_time}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='amount'>Tutar</label>
                      <input
                        type='number'
                        name='amount'
                        className={UString.concat_class_name(
                          errors.amount && touched.amount ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Tutar'
                        value={values.amount}
                      />
                      {errors.amount && touched.amount ? (
                        <span className='error'>* {errors.amount}</span>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <FormLabel id='receiver-select-label'>Alıcı Tipi</FormLabel>
                      <RadioGroup
                        value={values.receiverType}
                        aria-labeledBy='receiver-select-label'
                        name='receiverType'
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue('receiver', '');
                        }}
                      >
                        <Stack direction='row'>
                          <FormControlLabel value={1} control={<Radio />} label='Müşteri' />
                          <FormControlLabel value={2} control={<Radio />} label='Banka' />
                        </Stack>
                      </RadioGroup>
                    </div>
                    <div className='w-full'>
                      {values.receiverType == 1 ? (
                        <Stack direction='column'>
                          <Autocomplete
                            componentName='receiver'
                            options={CustomerFetch?.data?.map((customer: any) => ({
                              id: customer.id,
                              label: customer.company || customer.title,
                            }))}
                            getOptionLabel={(option: any) => option.label}
                            onChange={(e: any, value: any) => {
                              setFieldValue('receiver', value?.id || '');
                            }}
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField variant='outlined' {...params} label='Müşteri / Çalışan' />
                            )}
                          />
                          {errors.receiver && touched.receiver ? (
                            <span className='error'>* {errors.receiver}</span>
                          ) : null}
                        </Stack>
                      ) : null}
                      {values.receiverType == 2 ? (
                        <Stack direction='column'>
                          <Autocomplete
                            componentName='sender'
                            options={BankFetch?.data?.map((bank: any) => ({
                              id: bank._id,
                              label: bank.account_name,
                            }))}
                            getOptionLabel={(option: any) => option.label}
                            onChange={(e: any, value: any) => {
                              setFieldValue('receiver', value?.id || '');
                            }}
                            clearOnEscape
                            renderInput={(params) => (
                              <TextField variant='outlined' {...params} label='Banka Hesabı' />
                            )}
                          />
                          {errors.receiver && touched.receiver ? (
                            <span className='error'>* {errors.receiver}</span>
                          ) : null}
                        </Stack>
                      ) : null}
                    </div>
                    <div className='w-full'>
                      <label htmlFor='description'>Açıklama</label>
                      <textarea
                        name='description'
                        className={UString.concat_class_name(
                          errors.description && touched.description ? 'has-error' : undefined,
                          'my-1',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Açıklama'
                        value={values.description}
                      />
                      {errors.description && touched.description ? (
                        <span className='error'>* {errors.description}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className='header-bottom'>
          <button
            type='submit'
            disabled={Save.loading}
            className='ml-auto classic-btn'
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
          </button>
        </div>
      </React.Fragment>
    );
  }

  return <React.Fragment></React.Fragment>;
}
