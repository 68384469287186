import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, FieldArray, FormikErrors, FormikTouched } from 'formik';
import { Transition, Listbox } from '@headlessui/react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Http from 'services/http';
import Semiingredient from 'services/semiingradient';
import Warehouse from 'services/warehouse';
import Recipe from 'services/recipe';
import Product from 'services/product';
import Response from 'services/response';
import Structures from 'services/structures';

import Loading from 'components/loading';
import Spinner from 'components/spinner';
import UString from 'utilities/string';
import Ingradient from 'services/ingradient';
import { RecipeValidation, SemiIngredientRecipeValidation } from 'validations';

interface IDetail {
  product: string;
  productId: string;
  productType: string;
  amount: number;
  quantity: number;
}

import { toast } from 'react-toastify';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import clone from 'clone';

export default function () {
  const [data, setData] = useState<any[]>([]);
  const [data2, setData2] = useState<any[]>([]);
  const [allData, setAllData] = useState<any[]>([]);
  const [cost, setCost] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [manualPrice, setManualPrice] = useState<number>(0);

  const Service = new Semiingredient(useContext(Http.Context)!);
  const Fetch = new Response();
  const SelectedFetch = new Response();
  const Save = new Response();

  const CService = new Warehouse(useContext(Http.Context)!);
  const CFetch = new Response<any[]>();

  const OService = new Ingradient(useContext(Http.Context)!);
  const OFetch = new Response();

  const LService = new Recipe(useContext(Http.Context)!);
  const LFetch = new Response();
  const SelectedLFetch = new Response();

  const SService = new Product(useContext(Http.Context)!);
  const SFetch = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();
  const { id, path } = useParams();
  useEffect(
    () => (
      path == 'semi' && id && !SelectedFetch.data && SelectedFetch.handle(Service.getById(id!)),
      undefined
    ),
    [SelectedFetch.data],
  );
  useEffect(
    () => (
      path == 'recipe' &&
        id &&
        !SelectedLFetch.data &&
        SelectedLFetch.handle(LService.getById(id!)),
      undefined
    ),
    [SelectedLFetch.data],
  );

  useEffect(() => (!Fetch.data && Fetch.handle(Service.get()), undefined), [Fetch.data]);
  useEffect(() => (!OFetch.data && OFetch.handle(OService.get()), undefined), [OFetch.data]);
  useEffect(() => (!CFetch.data && CFetch.handle(CService.get()), undefined), [CFetch.data]);
  useEffect(() => (!LFetch.data && LFetch.handle(LService.get()), undefined), [LFetch.data]);
  useEffect(() => (!SFetch.data && SFetch.handle(SService.get()), undefined), [SFetch.data]);
  useEffect(() => {
    if (OFetch.data) {
      if (id && SelectedFetch.data) {
        setCost(SelectedFetch.data?.purchase_amount);
        setTotalPrice(SelectedFetch.data?.product_amount);
        setManualPrice(SelectedFetch.data?.product_amount);
        return;
      }
      let p = OFetch.data[0];
      if (!p) return;
      setCost(p.purchase_amount);
      setTotalPrice(p.product_amount);
      setManualPrice(p.product_amount);
    }
  }, [OFetch.data]);

  useEffect(() => Save.data && navigate('/recipe'), [Save.data]);

  useEffect(() => {
    Fetch?.data && setData(Fetch.data);
  }, [Fetch.data]);
  useEffect(() => {
    OFetch?.data && setData2(OFetch.data);
  }, [OFetch.data]);
  useEffect(() => (setAllData(data.concat(data2)), undefined), [data, data2]);

  useEffect(() => {
    if (Save.data && !Save.error) {
      if (path == 'recipe') {
        toast.success(`Reçete ${id ? 'düzenleme' : 'ekleme'} başarılı.`);
        navigate('/recipe');
      } else {
        toast.success(`Yarı Mamül ${id ? 'düzenleme' : 'ekleme'} başarılı.`);
        navigate('/recipe');
      }
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      if (path == 'recipe') {
        toast.error(`Reçete ${id ? 'düzenleme' : 'ekleme'} başarısız.`);
        navigate('/recipe');
      } else {
        toast.error(`Yaru Mamül ${id ? 'düzenleme' : 'ekleme'} başarısız.`);
        navigate('/recipe');
      }
    }
  }, [Save.error]);

  const calculateCostAndPrice = (products: any[]) => {
    let cost = 0;
    let price = 0;
    products.map((product: any) => {
      let p = allData?.find((pr: any) => pr.id == product.productId);
      if (!p) return;
      let pPrice = p.purchase_amount || p.purchases[p.purchases?.length - 1].purchase_price || 0;
      cost += pPrice * (Number(product.quantity) || 0);
      price += (product.amount || 0) * (Number(product.quantity) || 0);
    });

    setTotalPrice(price);

    return cost;
  };
  if (path == 'recipe' && (SelectedLFetch.data || !id)) {
    return (
      <React.Fragment>
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Reçete {id ? 'Düzenle' : 'Ekle'}</h2>
        </div>
        <Loading done={!SelectedLFetch.loading} />
        <div id='content'>
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              title: SelectedLFetch.data?.title || SFetch.data?.[0]?.title || String(),
              product: SelectedLFetch.data?.product || SFetch.data?.[0]?.id || String(),
              purchase_amount:
                SelectedLFetch.data?.purchase_amount ||
                OFetch.data?.[0]?.purchase_amount ||
                Number(),
              product_amount: SelectedLFetch.data?.product_amount || Number(),
              detail:
                (SelectedLFetch.data?.detail as Array<IDetail>) ||
                Array({
                  product:
                    SelectedLFetch.data?.detail.product || OFetch.data?.[0]?.title || String(),
                  productId:
                    SelectedLFetch.data?.detail.productId || OFetch.data?.[0]?.id || String(),
                  productType:
                    SelectedLFetch.data?.detail.productType || OFetch.data?.[0]?.detail
                      ? 'SemiIngredient'
                      : 'Ingredient' || String(),
                  amount:
                    SelectedFetch.data?.amount || OFetch.data?.[0]?.product_amount || Number(),
                  quantity: SelectedFetch.data?.quantity || '1' || String(),
                }),
            }}
            validationSchema={RecipeValidation}
            onSubmit={(values) =>
              Save.handle(
                id && SelectedLFetch.data
                  ? LService.update(id, { ...SelectedLFetch.data, ...values })
                  : LService.create(values),
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className='action' onSubmit={handleSubmit}>
                <div className='item'>
                  <div className='relative w-full'>
                    <label>Reçete Adı</label>
                    {SFetch.data ? (
                      <Listbox
                        value={values.product}
                        onChange={(value) => setFieldValue('product', value)}
                      >
                        <div className='relative mt-1'>
                          <Listbox.Button className='listbox-btn'>
                            <span className='listbox-title'>
                              {
                                SFetch.data.find((product: any) => product.id == values.product)
                                  ?.title
                              }
                            </span>
                            <span className='listbox-selector-icon'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='w-5 h-5 text-gray-400'
                                viewBox='0 0 20 20'
                                fill='currentColor'
                              >
                                <path
                                  fillRule='evenodd'
                                  d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                  clipRule='evenodd'
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                          <Listbox.Options className='listbox-options'>
                            {SFetch.data &&
                              SFetch?.data?.map((product: any) => (
                                <Listbox.Option
                                  className={UString.concat_class_name(
                                    values.product === product.id
                                      ? 'bg-ebony text-white'
                                      : 'hover:bg-ebony hover:text-white',
                                    'flex items-center cursor-pointer px-6 py-2',
                                  )}
                                  key={product.id}
                                  value={product.id}
                                >
                                  <span>{product.title}</span>
                                </Listbox.Option>
                              ))}
                          </Listbox.Options>
                        </div>
                      </Listbox>
                    ) : (
                      <div className='classic-btn w-full min-h-[38px] mt-1'>
                        <Spinner className='w-5 m-auto' />
                      </div>
                    )}
                  </div>
                  <div className=''>
                    {
                      <FieldArray
                        name='detail'
                        render={({ insert, remove }) => (
                          <>
                            <div className='flex flex-col space-y-4 divide-y divide-gray-200 xl:divide-y-0'>
                              {values.detail.map((item, index) => {
                                const touch = touched.detail?.[index] as
                                  | FormikTouched<IDetail>
                                  | undefined;
                                const error = errors.detail?.[index] as
                                  | FormikErrors<IDetail>
                                  | undefined;

                                return (
                                  <div
                                    className='flex flex-col xl:flex-row gap-4 w-full py-4 xl:py-0'
                                    key={index}
                                  >
                                    <div className='relative w-full'>
                                      <label>Ürün</label>
                                      {allData ? (
                                        <Listbox
                                          value={`detail.[${index}].product`}
                                          onChange={(value) => {
                                            let p: any = allData.find(
                                              (product: any) => product.id == value,
                                            );
                                            if (!p) return;
                                            setFieldValue(`detail.[${index}].productId`, value);
                                            setFieldValue(`detail.[${index}].product`, p.title);
                                            setFieldValue(
                                              `detail.[${index}].amount`,
                                              p.product_amount,
                                            );
                                            setFieldValue(
                                              `detail.[${index}].productType`,
                                              p.detail ? 'SemiIngredient' : 'Ingredient',
                                            );
                                            let details = clone(values.detail);
                                            details[index].productId = value;
                                            details[index].amount = p.product_amount;
                                            let cost = calculateCostAndPrice(details);
                                            setFieldValue('purchase_amount', cost);
                                          }}
                                        >
                                          <div className='relative mt-1'>
                                            <Listbox.Button className='listbox-btn'>
                                              <span className='listbox-title'>
                                                {
                                                  allData.find(
                                                    (ingrediant: any) =>
                                                      ingrediant.id === item.productId,
                                                  )?.title
                                                }
                                              </span>
                                              <span className='listbox-selector-icon'>
                                                <svg
                                                  xmlns='http://www.w3.org/2000/svg'
                                                  className='w-5 h-5 text-gray-400'
                                                  viewBox='0 0 20 20'
                                                  fill='currentColor'
                                                >
                                                  <path
                                                    fillRule='evenodd'
                                                    d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                                    clipRule='evenodd'
                                                  />
                                                </svg>
                                              </span>
                                            </Listbox.Button>
                                            <Listbox.Options className='listbox-options'>
                                              {allData &&
                                                allData.map((product: any, index: any) => (
                                                  <Listbox.Option
                                                    className={UString.concat_class_name(
                                                      item.productId === product.id
                                                        ? 'bg-ebony text-white'
                                                        : 'hover:bg-ebony hover:text-white',
                                                      'flex items-center cursor-pointer px-6 py-2',
                                                    )}
                                                    key={index}
                                                    value={product.id}
                                                  >
                                                    <span>{product.title}</span>
                                                  </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                          </div>
                                        </Listbox>
                                      ) : (
                                        <div className='classic-btn w-full min-h-[38px] mt-1'>
                                          <Spinner className='w-5 m-auto' />
                                        </div>
                                      )}
                                    </div>
                                    <div className='w-full xl:w-1/2'>
                                      <label htmlFor={`detail.[${index}].quantity`}>Miktar</label>
                                      <input
                                        type='number'
                                        name={`detail.[${index}].quantity`}
                                        className={UString.concat_class_name(
                                          error?.amount && touch?.amount ? 'has-error' : undefined,
                                          'mt-1',
                                        )}
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          let details = clone(values.detail);
                                          details[index].quantity = e.target.value;
                                          let cost = calculateCostAndPrice(details);
                                          setFieldValue('purchase_amount', cost);
                                        }}
                                        onBlur={handleBlur}
                                        value={item.quantity}
                                      />
                                      {error?.quantity && touch?.quantity ? (
                                        <span className='error'>* {error?.quantity}</span>
                                      ) : null}
                                    </div>
                                    <div className='w-full xl:w-1/2'>
                                      <label htmlFor={`detail.[${index}].amount`}>Tutar</label>
                                      <input
                                        type='number'
                                        name={`detail.[${index}].amount`}
                                        className={UString.concat_class_name(
                                          error?.amount && touch?.amount ? 'has-error' : undefined,
                                          'mt-1',
                                        )}
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          let details = clone(values.detail);
                                          details[index].quantity = e.target.value;
                                          let cost = calculateCostAndPrice(details);
                                          setFieldValue('purchase_amount', cost);
                                        }}
                                        onBlur={handleBlur}
                                        value={item.amount}
                                      />
                                      {error?.amount && touch?.amount ? (
                                        <span className='error'>* {error?.amount}</span>
                                      ) : null}
                                    </div>
                                    <div className='xl:mt-6'>
                                      <button
                                        className='classic-btn red w-full xl:w-auto'
                                        type='button'
                                        onClick={() => {
                                          values.detail.length > 0 && remove(index);
                                          let clonedDetail = clone(values.detail);
                                          clonedDetail.splice(index, 1);
                                          let cost = calculateCostAndPrice(clonedDetail);
                                          setFieldValue('purchase_amount', cost);
                                        }}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          className='h-5 w-5'
                                          fill='none'
                                          viewBox='0 0 24 24'
                                          stroke='currentColor'
                                        >
                                          <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth={1.5}
                                            d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className='w-full pt-4 '>
                              <button
                                className='w-full xl:ml-auto classic-btn justify-center'
                                type='button'
                                onClick={() => {
                                  let product = {
                                    product:
                                      Fetch.data?.product || OFetch.data?.[0]?.title || String(),
                                    productId:
                                      Fetch.data?.productId || OFetch.data?.[0]?.id || String(),
                                    productType:
                                      Fetch.data?.productType || OFetch.data?.[0]?.detail
                                        ? 'SemiIngredient'
                                        : 'Ingredient' || String(),
                                    amount:
                                      Fetch.data?.amount ||
                                      OFetch.data?.[0]?.product_amount ||
                                      Number(),
                                    quantity: Fetch.data?.quantity || 1,
                                    quantity_type:
                                      Fetch.data?.quantity_type ||
                                      Number(Object.keys(Structures.types.quantity)[0]),
                                  };
                                  setFieldValue('detail', values.detail.concat([product]));

                                  let cost = calculateCostAndPrice(
                                    clone(values.detail?.concat([product])),
                                  );
                                  setFieldValue('purchase_amount', cost);
                                }}
                              >
                                Ekle
                              </button>
                            </div>
                          </>
                        )}
                      />
                    }
                  </div>
                </div>
                <Stack
                  alignItems='flex-end'
                  sx={{ marginRight: '3vw', marginBottom: '2vw', marginTop: '5vw' }}
                >
                  <TableContainer sx={{ width: '30%' }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell width='60%' align='right' sx={{ fontSize: '1vw' }}>
                            <strong>Toplam Maliyet:</strong>
                          </TableCell>
                          <TableCell width='40%' sx={{ fontSize: '1vw' }}>
                            {values.purchase_amount} ₺{' '}
                            <InfoOutlinedIcon
                              titleAccess='Eklenen ürünlerin toplam maliyeti'
                              sx={{ cursor: 'pointer', marginLeft: '1vw' }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell width='60%' align='right' sx={{ fontSize: '1vw' }}>
                            <strong>Toplam Satış Tutarı:</strong>
                          </TableCell>
                          <TableCell width='40%' sx={{ fontSize: '1vw' }}>
                            {totalPrice} ₺{' '}
                            <InfoOutlinedIcon
                              titleAccess='Eklenen ürünlerin toplam satış tutarı'
                              sx={{ cursor: 'pointer', marginLeft: '1vw' }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell width='60%' align='right' sx={{ fontSize: '1vw' }}>
                            <strong>Satış Fiyatı:</strong>
                          </TableCell>
                          <TableCell width='40%' sx={{ fontSize: '1vw' }}>
                            <Stack direction='row' spacing={1} alignItems='center'>
                              <TextField
                                size='small'
                                name='product_amount'
                                type='number'
                                value={values.product_amount}
                                sx={{ width: '8vw', fontSize: '0.9vw' }}
                                helperText={
                                  errors?.product_amount && touched?.product_amount
                                    ? errors?.product_amount
                                    : ''
                                }
                                error={
                                  errors?.product_amount && touched?.product_amount ? true : false
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <span>₺</span>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </form>
            )}
          </Formik>
        </div>
        <div className='header-bottom'>
          <button
            type='submit'
            disabled={Save.loading}
            className='ml-auto classic-btn'
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
          </button>
        </div>
      </React.Fragment>
    );
  }
  if (path == 'semi') {
    return (
      <React.Fragment>
        <Loading done={!SelectedFetch.loading} />
        <div className='header-top'>
          <h2 className='text-xl font-medium pl-2'>Yarı Mamül {id ? 'Düzenle' : 'Ekle'}</h2>
        </div>
        <div id='content'>
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              title: SelectedFetch.data?.title || String(),
              purchase_amount:
                SelectedLFetch.data?.purchase_amount ||
                OFetch.data?.[0]?.purchase_amount ||
                Number(),
              product_amount: SelectedFetch.data?.product_amount || Number(),
              quantity_type:
                SelectedFetch.data?.quantity_type ||
                Number(Object.keys(Structures.types.quantity)[0]),
              detail:
                (SelectedFetch.data?.detail as Array<IDetail>) ||
                Array({
                  product: SelectedFetch.data?.product || OFetch.data?.[0]?.title || String(),
                  productId: SelectedFetch.data?.productId || OFetch.data?.[0]?.id || String(),
                  productType:
                    SelectedLFetch.data?.detail.productType || OFetch.data?.[0]?.detail
                      ? 'SemiIngredient'
                      : 'Ingredient' || String(),
                  amount:
                    SelectedFetch.data?.amount || OFetch.data?.[0]?.product_amount || Number(),
                  quantity: SelectedFetch.data?.quantity || '1' || String(),
                }),
            }}
            validationSchema={SemiIngredientRecipeValidation}
            onSubmit={(values) =>
              Save.handle(
                id && SelectedFetch.data
                  ? Service.update(id, { ...SelectedFetch.data, ...values })
                  : Service.create(values),
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className='action' onSubmit={handleSubmit}>
                <div className='item'>
                  <div className='w-full xl:w-1/5'>
                    <label htmlFor='title'>Yarı Mamül Adı</label>
                    <input
                      type='text'
                      name='title'
                      className={UString.concat_class_name(
                        errors.title && touched.title ? 'has-error' : undefined,
                        'my-1',
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                    {errors.title && touched.title ? (
                      <span className='error'>* {errors.title}</span>
                    ) : null}
                  </div>
                  <div className='w-full xl:w-1/5'>
                    <label>Tipi</label>
                    <Listbox
                      value={values.quantity_type}
                      onChange={(value) => setFieldValue(`quantity_type`, Number(value))}
                    >
                      <div className='relative mt-1'>
                        <Listbox.Button className='listbox-btn'>
                          <span className='listbox-title'>
                            {(Structures.types.quantity as any)[values.quantity_type]}
                          </span>
                          <span className='listbox-selector-icon'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className='w-5 h-5 text-gray-400'
                              viewBox='0 0 20 20'
                              fill='currentColor'
                            >
                              <path
                                fillRule='evenodd'
                                d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                clipRule='evenodd'
                              />
                            </svg>
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={React.Fragment}
                          leave='transition ease-in duration-100'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <Listbox.Options className='listbox-options'>
                            {Object.keys(Structures.types.quantity).map((type) => (
                              <Listbox.Option
                                className={UString.concat_class_name(
                                  values?.quantity_type === Number(type)
                                    ? 'bg-ebony text-white'
                                    : 'hover:bg-ebony hover:text-white',
                                  'flex items-center cursor-pointer px-6 py-2',
                                )}
                                key={type}
                                value={Number(type)}
                              >
                                <span>{(Structures.types.quantity as any)[type]}</span>
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                  <div className=''>
                    {
                      <FieldArray
                        name='detail'
                        render={({ insert, remove }) => (
                          <>
                            <div className='flex flex-col space-y-4 divide-y divide-gray-200 xl:divide-y-0 mt-2'>
                              {values.detail.map((item, index) => {
                                const touch = touched.detail?.[index] as
                                  | FormikTouched<IDetail>
                                  | undefined;
                                const error = errors.detail?.[index] as
                                  | FormikErrors<IDetail>
                                  | undefined;

                                return (
                                  <div
                                    className='flex flex-col xl:flex-row gap-4 w-full py-4 xl:py-0'
                                    key={index}
                                  >
                                    <div className='relative w-full'>
                                      <label>Ürün</label>
                                      {allData ? (
                                        <Listbox
                                          value={`detail.[${index}].product`}
                                          onChange={(value) => {
                                            let p: any = allData.find(
                                              (product: any) => product.id == value,
                                            );
                                            if (!p) return;
                                            setFieldValue(`detail.[${index}].productId`, value);
                                            setFieldValue(`detail.[${index}].product`, p.title);
                                            setFieldValue(
                                              `detail.[${index}].amount`,
                                              p.product_amount,
                                            );
                                            setFieldValue(
                                              `detail.[${index}].productType`,
                                              p.detail ? 'SemiIngredient' : 'Ingredient',
                                            );
                                            let details = clone(values.detail);
                                            details[index].productId = value;
                                            details[index].amount = p.product_amount;
                                            let cost = calculateCostAndPrice(details);
                                            setFieldValue('purchase_amount', cost);
                                          }}
                                        >
                                          <div className='relative mt-1'>
                                            <Listbox.Button className='listbox-btn'>
                                              <span className='listbox-title'>
                                                {
                                                  allData.find(
                                                    (ingrediant: any) =>
                                                      ingrediant.id === item.productId,
                                                  )?.title
                                                }
                                              </span>
                                              <span className='listbox-selector-icon'>
                                                <svg
                                                  xmlns='http://www.w3.org/2000/svg'
                                                  className='w-5 h-5 text-gray-400'
                                                  viewBox='0 0 20 20'
                                                  fill='currentColor'
                                                >
                                                  <path
                                                    fillRule='evenodd'
                                                    d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                                    clipRule='evenodd'
                                                  />
                                                </svg>
                                              </span>
                                            </Listbox.Button>
                                            <Listbox.Options className='listbox-options'>
                                              {allData &&
                                                allData?.map((product: any, index: any) => (
                                                  <Listbox.Option
                                                    className={UString.concat_class_name(
                                                      item.productId === product.id
                                                        ? 'bg-ebony text-white'
                                                        : 'hover:bg-ebony hover:text-white',
                                                      'flex items-center cursor-pointer px-6 py-2',
                                                    )}
                                                    key={index}
                                                    value={product.id}
                                                  >
                                                    <span>{product.title}</span>
                                                  </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                          </div>
                                        </Listbox>
                                      ) : (
                                        <div className='classic-btn w-full min-h-[38px] mt-1'>
                                          <Spinner className='w-5 m-auto' />
                                        </div>
                                      )}
                                    </div>
                                    <div className='w-full xl:w-1/2'>
                                      <label htmlFor={`detail.[${index}].quantity`}>Miktar</label>
                                      <input
                                        type='number'
                                        name={`detail.[${index}].quantity`}
                                        className={UString.concat_class_name(
                                          error?.amount && touch?.amount ? 'has-error' : undefined,
                                          'mt-1',
                                        )}
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          let details = clone(values.detail);
                                          details[index].quantity = e.target.value;
                                          let cost = calculateCostAndPrice(details);
                                          setFieldValue('purchase_amount', cost);
                                        }}
                                        onBlur={handleBlur}
                                        value={item.quantity}
                                      />
                                      {error?.quantity && touch?.quantity ? (
                                        <span className='error'>* {error?.quantity}</span>
                                      ) : null}
                                    </div>
                                    <div className='w-full xl:w-1/2'>
                                      <label htmlFor={`detail.[${index}].amount`}>Tutar</label>
                                      <input
                                        type='number'
                                        name={`detail.[${index}].amount`}
                                        className={UString.concat_class_name(
                                          error?.amount && touch?.amount ? 'has-error' : undefined,
                                          'mt-1',
                                        )}
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          let details = clone(values.detail);
                                          details[index].amount = e.target.value;
                                          let cost = calculateCostAndPrice(details);
                                          setFieldValue('purchase_amount', cost);
                                        }}
                                        onBlur={handleBlur}
                                        value={item.amount}
                                      />
                                      {error?.amount && touch?.amount ? (
                                        <span className='error'>* {error?.amount}</span>
                                      ) : null}
                                    </div>
                                    <div className='xl:mt-6'>
                                      <button
                                        className='classic-btn red w-full xl:w-auto'
                                        type='button'
                                        onClick={() => {
                                          values.detail.length > 0 && remove(index);
                                          let clonedDetail = clone(values.detail);
                                          clonedDetail.splice(index, 1);
                                          let cost = calculateCostAndPrice(clonedDetail);
                                          setFieldValue('purchase_amount', cost);
                                        }}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          className='h-5 w-5'
                                          fill='none'
                                          viewBox='0 0 24 24'
                                          stroke='currentColor'
                                        >
                                          <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth={1.5}
                                            d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className='w-full pt-4 '>
                              <button
                                className='w-full xl:ml-auto classic-btn justify-center'
                                type='button'
                                onClick={() => {
                                  let product = {
                                    product:
                                      Fetch.data?.product || OFetch.data?.[0]?.title || String(),
                                    productId:
                                      Fetch.data?.productId || OFetch.data?.[0]?.id || String(),
                                    productType:
                                      Fetch.data?.productType || OFetch.data?.[0]?.detail
                                        ? 'SemiIngredient'
                                        : 'Ingredient' || String(),
                                    amount:
                                      Fetch.data?.amount ||
                                      OFetch.data?.[0]?.product_amount ||
                                      Number(),
                                    quantity: Fetch.data?.quantity || 1,
                                    quantity_type:
                                      Fetch.data?.quantity_type ||
                                      Number(Object.keys(Structures.types.quantity)[0]),
                                  };
                                  setFieldValue('detail', values.detail.concat([product]));

                                  let cost = calculateCostAndPrice(
                                    clone(values.detail?.concat([product])),
                                  );
                                  setFieldValue('purchase_amount', cost);
                                }}
                              >
                                Ekle
                              </button>
                            </div>
                          </>
                        )}
                      />
                    }
                  </div>
                </div>
                <Stack
                  alignItems='flex-end'
                  sx={{ marginRight: '3vw', marginBottom: '2vw', marginTop: '5vw' }}
                >
                  <TableContainer sx={{ width: '30%' }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell width='60%' align='right' sx={{ fontSize: '1vw' }}>
                            <strong>Toplam Maliyet:</strong>
                          </TableCell>
                          <TableCell width='40%' sx={{ fontSize: '1vw' }}>
                            {values.purchase_amount} ₺{' '}
                            <InfoOutlinedIcon
                              titleAccess='Eklenen ürünlerin toplam maliyeti'
                              sx={{ cursor: 'pointer', marginLeft: '1vw' }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell width='60%' align='right' sx={{ fontSize: '1vw' }}>
                            <strong>Toplam Satış Tutarı:</strong>
                          </TableCell>
                          <TableCell width='40%' sx={{ fontSize: '1vw' }}>
                            {totalPrice} ₺{' '}
                            <InfoOutlinedIcon
                              titleAccess='Eklenen ürünlerin toplam satış tutarı'
                              sx={{ cursor: 'pointer', marginLeft: '1vw' }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell width='60%' align='right' sx={{ fontSize: '1vw' }}>
                            <strong>Satış Fiyatı:</strong>
                          </TableCell>
                          <TableCell width='40%' sx={{ fontSize: '1vw' }}>
                            <Stack direction='row' spacing={1} alignItems='center'>
                              <TextField
                                type='number'
                                size='small'
                                name='product_amount'
                                value={values.product_amount}
                                onChange={handleChange}
                                sx={{ width: '8vw', fontSize: '0.9vw' }}
                                helperText={
                                  errors?.product_amount && touched?.product_amount
                                    ? errors?.product_amount
                                    : ''
                                }
                                error={
                                  errors?.product_amount && touched?.product_amount ? true : false
                                }
                                onBlur={handleBlur}
                              />
                              <span>₺</span>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </form>
            )}
          </Formik>
        </div>
        <div className='header-bottom'>
          <button
            type='submit'
            disabled={Save.loading}
            className='ml-auto classic-btn'
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
          </button>
        </div>
      </React.Fragment>
    );
  }

  return <React.Fragment></React.Fragment>;
}
