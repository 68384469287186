import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import Http from 'services/http';
import Ingradient from 'services/ingradient';
import Warehouse from 'services/warehouse';
import Response from 'services/response';

import Table from 'components/table';
import Modal from 'components/modal';
import Loading from 'components/loading';
import NetworkError from 'components/network-error';
import Spinner from 'components/spinner';
import clone from 'clone';
import { MenuItem, Stack, TextField } from '@mui/material';

export default function () {
  const Service = new Ingradient(useContext(Http.Context)!);
  const Fetch = new Response();
  const WService = new Warehouse(useContext(Http.Context)!);
  const WFetch = new Response();
  const Delete = new Response();

  const [modal, setModal] = useState<boolean>(false);
  const [option, setOption] = useState<any | null>(null);
  const [stocks, setStocks] = useState<Array<any>>([]);
  const ref = useRef('all');

  useEffect(() => (!Fetch.data && Fetch.handle(Service.get()), undefined), [Fetch.data]);
  useEffect(() => (!WFetch.data && WFetch.handle(WService.get()), undefined), [WFetch.data]);
  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading)
      setTimeout(
        () => (
          (Fetch.data = Fetch.data.filter((data: any) => data.id !== option.id)), setModal(false)
        ),
        500,
      );
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(option)), [option]);

  useEffect(() => {
    if (Fetch.data) {
      filterStocks();
    }
  }, [Fetch.data]);

  useEffect(
    () => (!modal && setTimeout(() => (setOption(null), (Delete.data = null)), 200), undefined),
    [modal],
  );

  const filterStocks = () => {
    if (ref.current == 'all') {
      var s = clone(Fetch.data)?.reduce((prev: any, data: any, index: number) => {
        let i = prev.findIndex((product: any) => product.title == data.title);
        if (i > -1) {
          if (prev[i].count) {
            prev[i].count++;
          } else {
            prev[i].count = 2;
          }
          if (data) {
            prev[i].stock += data.stock;
            prev[i].purchases.push(data.purchases);
            prev[i].product_amount += data.product_amount;
          }
        } else {
          prev.push(data);
        }
        return prev;
      }, []);
    } else {
      var s = clone(Fetch?.data).filter((product: any) => product.warehouse?._id == ref.current);
    }
    setStocks(s);
  };

  const calculateAveragePrice = (prices: any[]) => {
    let price: number = 0,
      count: number = 0;
    prices.map((p: any) => {
      count++;
      price += p.purchase_price;
    });
    return isNaN(price / count) ? '-' : (price / count).toFixed(2);
  };

  return (
    <React.Fragment>
      <Modal onClose={() => !Delete.loading && setModal(false)} state={[modal, setModal]}>
        <React.Fragment>
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='sm:flex sm:items-start'>
              <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-red-600'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
              </div>
              <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>
                  {option?.special_name}
                </h3>
                <div className='mt-2'>
                  <p className='text-sm text-gray-500'>
                    Bu Stoğu silmek istediğinize emin misiniz? Bu işlem geri alınamaz.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-actions'>
            <button
              disabled={Delete.data || Delete.loading}
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={() => Delete.handle(Service.delete(option.id))}
            >
              {Delete.data ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : Delete.loading ? (
                <Spinner className='h-5 m-auto' />
              ) : (
                <span>Sil</span>
              )}
            </button>
            <button
              disabled={Delete.loading}
              type='button'
              className='mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto'
              onClick={setModal.bind(null, false)}
            >
              İptal
            </button>
          </div>
        </React.Fragment>
      </Modal>
      <div className='header-top justify-between'>
        <div className='flex flex-row'>
          <button className='to-gray-btn'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5 xl:mr-2'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z'
                clipRule='evenodd'
              />
            </svg>
            <span className='hidden xl:block'>İçeri aktar</span>
          </button>
          <button className='to-gray-btn'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5 xl:mr-2'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
            <span className='hidden xl:block'>Dışarı aktar</span>
          </button>
        </div>
        <Stack direction='row' spacing={3}>
          <div>
            <TextField
              select
              variant='outlined'
              label='Depo'
              value={ref.current}
              onChange={async (e: any) => {
                ref.current = e.target.value;
                filterStocks();
              }}
              sx={{ width: '10vw', fontSize: '1vw' }}
            >
              <MenuItem value='all'>Tümü</MenuItem>
              {WFetch?.data?.map((warehouse: any, index: number) => (
                <MenuItem value={warehouse.id} key={index}>
                  {warehouse.title}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <Link
            to='/stock/action'
            className='flex shadow-md items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5 mr-2'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                clipRule='evenodd'
              />
            </svg>
            <span>Yeni Stok</span>
          </Link>
        </Stack>
      </div>
      <div id='content'>
        <Loading done={!Fetch.loading} />
        {!Fetch.loading ? (
          <NetworkError error={Fetch.error} retry={() => Fetch.handle(Service.get())} />
        ) : null}
        {Fetch.data && !Fetch.loading && !Fetch.error && ref.current != 'all' && (
          <Table
            data={{
              head: [
                { element: 'Kodu', props: { scope: 'col' } },
                { element: 'Başlık', props: { scope: 'col' } },
                { element: 'Stok', props: { scope: 'col' } },
                { element: 'Ortalama Alış Fiyat', props: { scope: 'col' } },
                { element: 'Satış Fiyatı', props: { scope: 'col' } },
                { element: 'Envanter', props: { scope: 'col' } },
                {
                  element: 'Aksiyonlar',
                  props: { scope: 'col' },
                  screenReader: true,
                },
              ],
              body: stocks.map((ingradient: any) => ({
                id: ingradient.id,
                elements: [
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>
                          {ingradient?.stock_code || '-'}
                        </div>
                      </div>
                    ),
                  },
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>{ingradient.title}</div>
                      </div>
                    ),
                  },
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>{ingradient.stock}</div>
                      </div>
                    ),
                  },
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>
                          {calculateAveragePrice(ingradient.purchases)}
                        </div>
                      </div>
                    ),
                  },
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>
                          {ingradient.product_amount?.toFixed(2)}
                        </div>
                      </div>
                    ),
                  },
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>
                          {ingradient.warehouse?.title}
                        </div>
                      </div>
                    ),
                  },
                  {
                    element: (
                      <>
                        <Link
                          to={`/stock/detail/${ingradient.id}`}
                          className='text-green-600 hover:text-green-900'
                        >
                          Detay
                        </Link>
                        <Link
                          to={`/stock/action/${ingradient.id}`}
                          className='text-indigo-600 hover:text-indigo-900'
                        >
                          Düzenle
                        </Link>
                        <button
                          onClick={setOption.bind(null, ingradient)}
                          className='text-red-600 hover:text-red-900'
                        >
                          Sil
                        </button>
                      </>
                    ),
                    props: {
                      className: 'text-right text-sm font-medium space-x-6',
                    },
                  },
                ],
              })),
            }}
          />
        )}
        {Fetch.data && !Fetch.loading && !Fetch.error && ref.current == 'all' && (
          <Table
            data={{
              head: [
                { element: 'Kodu', props: { scope: 'col' } },
                { element: 'Adı', props: { scope: 'col' } },
                { element: 'Stok', props: { scope: 'col' } },
                { element: 'Ortalama Alış Fiyatı', props: { scope: 'col' } },
                { element: 'Ortalama Satış Fiyatı', props: { scope: 'col' } },
              ],
              body: stocks.map((ingradient: any) => ({
                id: ingradient.id,
                elements: [
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>
                          {ingradient?.stock_code}
                        </div>
                      </div>
                    ),
                  },
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>{ingradient?.title}</div>
                      </div>
                    ),
                  },
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>{ingradient.stock}</div>
                      </div>
                    ),
                  },
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>
                          {calculateAveragePrice(ingradient.purchases)}
                        </div>
                      </div>
                    ),
                  },
                  {
                    element: (
                      <div className='flex items-center'>
                        <div className='text-sm font-medium text-gray-900'>
                          {ingradient.product_amount / (ingradient.count || 1) == 0
                            ? '-'
                            : (ingradient.product_amount / (ingradient.count || 1))?.toFixed(2)}
                        </div>
                      </div>
                    ),
                  },
                ],
              })),
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
