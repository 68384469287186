import React, { useState, useEffect, useContext, useRef } from 'react';

import {
  Box,
  Typography,
  Stack,
  Divider,
  Paper,
  ButtonGroup,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Skeleton,
} from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Menu, Tab } from '@headlessui/react';
import Modal from 'components/modal';

import Invoice from 'services/invoice';
import Response from 'services/response';
import Http from 'services/http';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'components/spinner';
import { Link, useNavigate } from 'react-router-dom';

export default function index() {
  const invoiceType = useRef('all');
  const page = useRef(1);
  const perPage = useRef(6);
  const sort = useRef('DATE_DESC');
  const paid = useRef(2);
  const [invoiceData, setInvoiceData]: any = useState([]);
  const [totalCount, setInvoiceTotalCount] = useState(0);
  const [modal, setModal] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<any | null>(null);

  const navigate = useNavigate();

  const IService = new Invoice(useContext(Http.Context)!);
  const IFetch = new Response();

  const Delete = new Response();

  const fetchData = (reset: boolean = false) => {
    if (reset) setInvoiceData([]);

    IFetch.handle(
      IService.getByInvoiceType(
        page.current - 1,
        perPage.current,
        invoiceType.current,
        sort.current,
        paid.current,
      ),
    );
  };

  useEffect(() => fetchData(), []);

  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading)
      setTimeout(() => (fetchData(true), setModal(false)), 500);
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(invoice)), [invoice]);
  useEffect(
    () => (!modal && setTimeout(() => (setInvoice(null), (Delete.data = null)), 200), undefined),
    [modal],
  );

  useEffect(() => {
    if (IFetch.data) {
      setInvoiceData((prevData: any) => [...prevData, ...IFetch.data?.data]);
      setInvoiceTotalCount(IFetch?.data?.totalCount);
    }
  }, [IFetch?.data]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  const handleTabChange = (value: any) => {
    setInvoiceData([]);
    switch (value) {
      case 0:
        invoiceType.current = 'all';
        break;
      case 1:
        invoiceType.current = '1';
        break;
      case 2:
        invoiceType.current = '2';
        break;
      case 3:
        invoiceType.current = '3';
        break;
      case 4:
        invoiceType.current = '4';
        break;
      default:
        break;
    }
    page.current = 1;
    fetchData();
  };

  const loadMoreData = () => {
    page.current++;
    fetchData();
  };

  const getInvoiceType = (type: number) => {
    switch (type) {
      case 1:
        return 'Alış Faturası';
      case 2:
        return 'Alış İade Faturası';
      case 3:
        return 'Satış Faturası';
      case 4:
        return 'Satış İade Faturası';
      default:
        break;
    }
  };

  return (
    <Box>
      <Modal onClose={() => !Delete.loading && setModal(false)} state={[modal, setModal]}>
        <React.Fragment>
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='sm:flex sm:items-start'>
              <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 p-0.5'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-red-600'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
              </div>
              <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>{invoice?.title}</h3>
                <div className='mt-2'>
                  <p className='text-sm text-gray-500'>
                    Bu Faturayı silmek istediğinize emin misiniz? Bu işlem geri alınamaz.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-actions'>
            <button
              disabled={Delete.data || Delete.loading}
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={() => Delete.handle(IService.delete(invoice._id))}
            >
              {Delete.data ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : Delete.loading ? (
                <Spinner className='h-5 m-auto' />
              ) : (
                <span>Sil</span>
              )}
            </button>
            <button
              disabled={Delete.loading}
              type='button'
              className='mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto'
              onClick={setModal.bind(null, false)}
            >
              İptal
            </button>
          </div>
        </React.Fragment>
      </Modal>
      <Box>
        <Stack direction='row' justifyContent='space-around' padding='1vw'>
          <Typography variant='h4' fontSize='2.2vw' fontWeight='bold'>
            Faturalar
          </Typography>
          <div className='flex flex-col ml-auto relative z-10 shadow-md'>
            <Menu>
              <Menu.Button className='flex flex-row font-medium bg-ebony text-white py-3 px-6 rounded-lg text-sm'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5 mr-2'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                    clipRule='evenodd'
                  />
                </svg>
                <span>Yeni Fatura Oluştur</span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5 ml-2'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  />
                </svg>
              </Menu.Button>
              <Menu.Items className='flex w-full shadow-md flex-col absolute right-15 top-12 bg-white rounded-lg text-black'>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className='py-2 px-4 m-2 hover:bg-sky-500 hover:text-white rounded-lg'
                      to='/bill/action'
                    >
                      Alış Faturası
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className='py-2 px-4 m-2 hover:bg-emerald-500 hover:text-white rounded-lg'
                      to='/sellBill/action'
                    >
                      Satış Faturası
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className='py-2 px-4 m-2 hover:bg-red-500 hover:text-white rounded-lg'
                      to='/bills/action'
                    >
                      İade Faturası
                    </Link>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </Stack>
        <Divider />
      </Box>
      <Tab.Group onChange={handleTabChange}>
        <Tab.List>
          <Stack direction='row' spacing={1} padding='1vw'>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-green-600 border-2 border-green-600'
                    : 'text-green-600 hover:bg-green-600 hover:text-white border-2 border-green-600',
                )
              }
            >
              Tümü
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-red-600 border-2 border-red-600'
                    : 'text-red-600 hover:bg-red-600 hover:text-white border-2 border-red-600',
                )
              }
            >
              Alış Faturaları
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-orange-600 border-2 border-orange-600'
                    : 'text-orange-600 hover:bg-orange-600 hover:text-white border-2 border-orange-600',
                )
              }
            >
              Alış İade Faturaları
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-green-600 border-2 border-green-600'
                    : 'text-green-600 hover:bg-green-600 hover:text-white border-2 border-green-600',
                )
              }
            >
              Satış Faturaları
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium outline-none',
                  selected
                    ? 'text-white shadow bg-orange-600 border-2 border-orange-600'
                    : 'text-orange-600 hover:bg-orange-600 hover:text-white border-2 border-orange-600',
                )
              }
            >
              Satış İade Faturaları
            </Tab>
          </Stack>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            {IFetch.data ? (
              <>
                {totalCount == 0 ? (
                  <Typography variant='h4' fontWeight='bold' textAlign='center'>
                    Filtrelere uygun alış faturası bulunamadı.
                  </Typography>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={invoiceData.length || 0}
                      next={loadMoreData}
                      loader={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Spinner />
                        </Box>
                      }
                      hasMore={invoiceData?.length < totalCount}
                    >
                      <Box
                        sx={{
                          height: '32vw',
                          marginTop: '2vw',
                          overflowY: 'scroll',
                        }}
                      >
                        <Paper elevation={3}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Fatura Tipi
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Seri No
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Sıra No
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Toplam Tutar
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Müşteri
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Fatura Tarihi
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Son Ödeme Tarihi
                                  </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {invoiceData &&
                                invoiceData.map((invoice: any) => (
                                  <TableRow>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {getInvoiceType(invoice.invoice_types)}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>{invoice.invoice_no}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.invoice_code}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.total_amount} ₺
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.customer?.account_name ||
                                          invoice.customer?.company}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {new Date(invoice.invoice_time)?.toLocaleDateString()}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {new Date(invoice.expiry_date)?.toLocaleDateString()}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <ButtonGroup orientation='horizontal'>
                                        {!invoice.isCancelled && !invoice.isPaid ? (
                                          <Tooltip title='Düzenle'>
                                            <IconButton
                                              onClick={() => {
                                                switch (invoice.invoice_types) {
                                                  case 1:
                                                    navigate(`/bill/action/${invoice._id}`);
                                                    break;
                                                  case 2:
                                                    navigate(
                                                      `/bill/action/rebate/${invoice._id}/edit`,
                                                    );
                                                    break;
                                                  case 3:
                                                    navigate(`/sellBill/action/${invoice._id}`);
                                                    break;
                                                  case 4:
                                                    navigate(
                                                      `/sellBill/action/rebate/${invoice._id}/edit`,
                                                    );
                                                    break;
                                                  default:
                                                    break;
                                                }
                                              }}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        ) : null}
                                        {!invoice.isCancelled &&
                                        !invoice.isPaid &&
                                        invoice.invoice_types != 2 &&
                                        invoice.invoice_types != 4 ? (
                                          <Tooltip title='İade Oluştur'>
                                            <IconButton
                                              onClick={() => {
                                                switch (invoice.invoice_types) {
                                                  case 1:
                                                    navigate(
                                                      `/bill/action/rebate/${invoice._id}/create`,
                                                    );
                                                    break;
                                                  case 3:
                                                    navigate(
                                                      `/sellBill/action/rebate/${invoice._id}/create`,
                                                    );
                                                    break;
                                                  default:
                                                    break;
                                                }
                                              }}
                                            >
                                              <PaidIcon />
                                            </IconButton>
                                          </Tooltip>
                                        ) : null}
                                        <Tooltip title='Faturayı Sil'>
                                          <IconButton onClick={setInvoice.bind(null, invoice)}>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </ButtonGroup>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Box>
                    </InfiniteScroll>
                  </>
                )}
              </>
            ) : (
              <Box sx={{ width: '10%', height: '10%', margin: 'auto', marginTop: '12vw' }}>
                <Spinner />
              </Box>
            )}
          </Tab.Panel>
          <Tab.Panel>
            {IFetch.data ? (
              <>
                {totalCount == 0 ? (
                  <Typography variant='h4' fontWeight='bold' textAlign='center'>
                    Filtrelere uygun alış faturası bulunamadı.
                  </Typography>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={invoiceData.length || 0}
                      next={loadMoreData}
                      loader={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Spinner />
                        </Box>
                      }
                      hasMore={invoiceData?.length < totalCount}
                    >
                      <Box
                        sx={{
                          height: '32vw',
                          marginTop: '2vw',
                          overflowY: 'scroll',
                        }}
                      >
                        <Paper elevation={3}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Seri No
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Sıra No
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Durum
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Toplam Tutar
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Müşteri
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Fatura Tarihi
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Son Ödeme Tarihi
                                  </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {invoiceData &&
                                invoiceData.map((invoice: any) => (
                                  <TableRow>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>{invoice.invoice_no}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.invoice_code}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <div className='flex items-center'>
                                        {invoice?.isPaid ? (
                                          <div className='text-sm font-medium text-green-700'>
                                            Ödendi
                                          </div>
                                        ) : invoice?.isCancelled ? (
                                          <div className='text-sm font-medium text-red-600'>
                                            İade Edildi
                                          </div>
                                        ) : (
                                          <div className='text-sm font-medium text-orange-600'>
                                            Ödeme Bekleniyor
                                          </div>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.total_amount} ₺
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.customer?.account_name ||
                                          invoice.customer?.company}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {new Date(invoice.invoice_time)?.toLocaleDateString()}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {new Date(invoice.expiry_date)?.toLocaleDateString()}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <ButtonGroup orientation='horizontal'>
                                        {!invoice.isCancelled && !invoice.isPaid ? (
                                          <Tooltip title='Düzenle'>
                                            <IconButton
                                              onClick={() =>
                                                navigate(`/bill/action/${invoice._id}`)
                                              }
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        ) : null}
                                        {!invoice.isCancelled && !invoice.isPaid ? (
                                          <Tooltip title='İade Oluştur'>
                                            <IconButton>
                                              <PaidIcon />
                                            </IconButton>
                                          </Tooltip>
                                        ) : null}

                                        <Tooltip title='Faturayı Sil'>
                                          <IconButton onClick={setInvoice.bind(null, invoice)}>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </ButtonGroup>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Box>
                    </InfiniteScroll>
                  </>
                )}
              </>
            ) : (
              <Box sx={{ width: '10%', height: '10%', margin: 'auto', marginTop: '12vw' }}>
                <Spinner />
              </Box>
            )}
          </Tab.Panel>
          <Tab.Panel>
            {IFetch.data ? (
              <>
                {totalCount == 0 ? (
                  <Typography variant='h4' fontWeight='bold' textAlign='center'>
                    Filtrelere uygun alış faturası bulunamadı.
                  </Typography>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={invoiceData.length || 0}
                      next={loadMoreData}
                      loader={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Spinner />
                        </Box>
                      }
                      hasMore={invoiceData?.length < totalCount}
                    >
                      <Box
                        sx={{
                          height: '32vw',
                          marginTop: '2vw',
                          overflowY: 'scroll',
                        }}
                      >
                        <Paper elevation={3}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Seri No
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Sıra No
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Toplam Tutar
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Müşteri
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Fatura Tarihi
                                  </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {invoiceData &&
                                invoiceData.map((invoice: any) => (
                                  <TableRow>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>{invoice.invoice_no}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.invoice_code}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.total_amount} ₺
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.customer?.account_name ||
                                          invoice.customer?.company}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {new Date(invoice.invoice_time)?.toLocaleDateString()}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <ButtonGroup orientation='horizontal'>
                                        {!invoice.isCancelled && !invoice.isPaid ? (
                                          <Tooltip title='Düzenle'>
                                            <IconButton
                                              onClick={() =>
                                                navigate(`/bill/action/rebate/${invoice._id}/edit`)
                                              }
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        ) : null}
                                        <Tooltip title='Faturayı Sil'>
                                          <IconButton onClick={setInvoice.bind(null, invoice)}>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </ButtonGroup>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Box>
                    </InfiniteScroll>
                  </>
                )}
              </>
            ) : (
              <Box sx={{ width: '10%', height: '10%', margin: 'auto', marginTop: '12vw' }}>
                <Spinner />
              </Box>
            )}
          </Tab.Panel>
          <Tab.Panel>
            {IFetch.data ? (
              <>
                {totalCount == 0 ? (
                  <Typography variant='h4' fontWeight='bold' textAlign='center'>
                    Filtrelere uygun alış faturası bulunamadı.
                  </Typography>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={invoiceData.length || 0}
                      next={loadMoreData}
                      loader={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Spinner />
                        </Box>
                      }
                      hasMore={invoiceData?.length < totalCount}
                    >
                      <Box
                        sx={{
                          height: '32vw',
                          marginTop: '2vw',
                          overflowY: 'scroll',
                        }}
                      >
                        <Paper elevation={3}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Seri No
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Sıra No
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Durum
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Toplam Tutar
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Müşteri
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Fatura Tarihi
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Son Ödeme Tarihi
                                  </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {invoiceData &&
                                invoiceData.map((invoice: any) => (
                                  <TableRow>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>{invoice.invoice_no}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.invoice_code}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <div className='flex items-center'>
                                        {invoice?.isPaid ? (
                                          <div className='text-sm font-medium text-green-700'>
                                            Ödendi
                                          </div>
                                        ) : invoice?.isCancelled ? (
                                          <div className='text-sm font-medium text-red-600'>
                                            İade Edildi
                                          </div>
                                        ) : (
                                          <div className='text-sm font-medium text-orange-600'>
                                            Tahsilat Bekleniyor
                                          </div>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.total_amount} ₺
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.customer?.account_name ||
                                          invoice.customer?.company}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {new Date(invoice.invoice_time)?.toLocaleDateString()}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {new Date(invoice.expiry_date)?.toLocaleDateString()}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <ButtonGroup orientation='horizontal'>
                                        {!invoice.isCancelled && !invoice.isPaid ? (
                                          <Tooltip title='Düzenle'>
                                            <IconButton
                                              onClick={() =>
                                                navigate(`/sellBill/action/${invoice._id}`)
                                              }
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        ) : null}
                                        {!invoice.isCancelled && !invoice.isPaid ? (
                                          <Tooltip title='İade Oluştur'>
                                            <IconButton>
                                              <PaidIcon />
                                            </IconButton>
                                          </Tooltip>
                                        ) : null}
                                        <Tooltip title='Faturayı Sil'>
                                          <IconButton onClick={setInvoice.bind(null, invoice)}>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </ButtonGroup>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Box>
                    </InfiniteScroll>
                  </>
                )}
              </>
            ) : (
              <Box sx={{ width: '10%', height: '10%', margin: 'auto', marginTop: '12vw' }}>
                <Spinner />
              </Box>
            )}
          </Tab.Panel>
          <Tab.Panel>
            {IFetch.data ? (
              <>
                {totalCount == 0 ? (
                  <Typography variant='h4' fontWeight='bold' textAlign='center'>
                    Filtrelere uygun alış faturası bulunamadı.
                  </Typography>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={invoiceData.length || 0}
                      next={loadMoreData}
                      loader={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Spinner />
                        </Box>
                      }
                      hasMore={invoiceData?.length < totalCount}
                    >
                      <Box
                        sx={{
                          height: '32vw',
                          marginTop: '2vw',
                          overflowY: 'scroll',
                        }}
                      >
                        <Paper elevation={3}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Seri No
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Sıra No
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Toplam Tutar
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Müşteri
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography fontSize='1.2vw' fontWeight='bold'>
                                    Fatura Tarihi
                                  </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {invoiceData &&
                                invoiceData.map((invoice: any) => (
                                  <TableRow>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>{invoice.invoice_no}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.invoice_code}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.total_amount} ₺
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {invoice.customer?.account_name ||
                                          invoice.customer?.company}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography fontSize='1.1vw'>
                                        {new Date(invoice.invoice_time)?.toLocaleDateString()}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <ButtonGroup orientation='horizontal'>
                                        {!invoice.isCancelled && !invoice.isPaid ? (
                                          <Tooltip title='Düzenle'>
                                            <IconButton
                                              onClick={() =>
                                                navigate(
                                                  `/sellBill/action/rebate/${invoice._id}/edit`,
                                                )
                                              }
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        ) : null}
                                        <Tooltip title='Faturayı Sil'>
                                          <IconButton onClick={setInvoice.bind(null, invoice)}>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </ButtonGroup>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Box>
                    </InfiniteScroll>
                  </>
                )}
              </>
            ) : (
              <Box sx={{ width: '10%', height: '10%', margin: 'auto', marginTop: '12vw' }}>
                <Spinner />
              </Box>
            )}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Box>
  );
}
