import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Http from 'services/http';
import Bank from 'services/bank';
import Response from 'services/response';

import Table from 'components/table';
import Modal from 'components/modal';
import Loading from 'components/loading';
import NetworkError from 'components/network-error';
import Spinner from 'components/spinner';

import Structures from 'services/structures';

export default function () {
  const Service = new Bank(useContext(Http.Context)!);
  const Banks = new Response();
  const Delete = new Response();

  useEffect(() => (!Banks.data && Banks.handle(Service.get()), undefined), [Banks.data]);
  const [modal, setModal] = useState<boolean>(false);
  const [delBank, setDelBank] = useState<any | null>(null);

  useEffect(() => (!Banks.data && Banks.handle(Service.get()), undefined), [Banks.data]);
  useEffect(() => {
    if (Delete.data && !Delete.error && !Delete.loading)
      setTimeout(
        () => (
          (Banks.data = Banks.data.filter((data: any) => data._id !== delBank._id)), setModal(false)
        ),
        500,
      );
  }, [Delete.data, Delete.loading]);

  useEffect(() => setModal(Boolean(delBank)), [delBank]);
  useEffect(
    () => (!modal && setTimeout(() => (setDelBank(null), (Delete.data = null)), 200), undefined),
    [modal],
  );

  return (
    <React.Fragment>
      <Modal onClose={() => !Delete.loading && setModal(false)} state={[modal, setModal]}>
        <React.Fragment>
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='sm:flex sm:items-start'>
              <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>
                  {delBank?.account_name}
                </h3>
                <div className='mt-2'>
                  <p className='text-sm text-gray-500'>
                    Bu Bankayı silmek istediğinize emin misiniz? Bu işlem geri alınamaz.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-actions'>
            <button
              disabled={Delete.data || Delete.loading}
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={() => Delete.handle(Service.delete(delBank._id))}
            >
              {Delete.data ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : Delete.loading ? (
                <Spinner className='h-5 m-auto' />
              ) : (
                <span>Sil</span>
              )}
            </button>
            <button
              disabled={Delete.loading}
              type='button'
              className='mt-3 w-full classic-btn justify-center sm:mt-0 sm:ml-3 sm:w-auto'
              onClick={setModal.bind(null, false)}
            >
              İptal
            </button>
          </div>
        </React.Fragment>
      </Modal>
      <div className='header-top'>
        <button className='to-gray-btn'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5 xl:mr-2'
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path
              fillRule='evenodd'
              d='M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z'
              clipRule='evenodd'
            />
          </svg>
          <span className='hidden xl:block'>İçeri aktar</span>
        </button>
        <button className='to-gray-btn'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5 xl:mr-2'
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path
              fillRule='evenodd'
              d='M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
          <span className='hidden xl:block'>Dışarı aktar</span>
        </button>
        <Link
          to='/bank/action/new'
          className='flex shadow-md items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium ml-auto'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5 mr-2'
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path
              fillRule='evenodd'
              d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
              clipRule='evenodd'
            />
          </svg>
          <span>Yeni Banka Oluştur</span>
        </Link>
      </div>
      <div id='content'>
        <Loading done={!Banks.loading} />
        {!Banks.loading ? (
          <NetworkError error={Banks.error} retry={() => Banks.handle(Service.get())} />
        ) : null}
        {Banks.data && !Banks.loading && !Banks.error && (
          <Table
            data={{
              head: [
                {
                  element: 'Hesap Adı',
                  props: { scope: 'col' },
                },
                { element: 'Bakiye', props: { scope: 'col' } },
                { element: 'Döviz Türü', props: { scope: 'col' } },
                {
                  element: 'Banka Adı',
                  props: { scope: 'col' },
                },
                {
                  element: 'Aksiyonlar',
                  props: { scope: 'col' },
                  screenReader: true,
                },
              ],
              body: Banks.data.map((bank: any) => ({
                id: bank._id,
                elements: [
                  {
                    element: bank.account_name,
                  },
                  {
                    element: `${bank?.balance[0]?.amount?.toFixed(2)}`,
                    props: {
                      className: 'text-left text-sm text-gray-900',
                    },
                  },
                  {
                    element: (Structures.types.currency_opt as any)[bank?.balance[0]?.currency],
                  },
                  {
                    element: bank.bank_name,
                  },
                  {
                    element: (
                      <div
                        className='space-x-4'
                        style={{ width: '7vw', display: 'flex', justifyContent: 'flex-start' }}
                      >
                        <Link
                          to={`/bank/action/detail/${bank._id}`}
                          className='text-yellow-600 hover:text-yellow-800'
                        >
                          Detay
                        </Link>
                        <Link
                          to={`/bank/action/edit/${bank._id}`}
                          className='text-indigo-600 hover:text-indigo-900'
                        >
                          Düzenle
                        </Link>
                        {bank.amount_given == 0 && bank.amount_received == 0 ? (
                          <button
                            onClick={setDelBank.bind(null, bank)}
                            className='text-red-600 hover:text-red-900'
                          >
                            Sil
                          </button>
                        ) : null}
                      </div>
                    ),
                    props: {
                      className: 'text-right text-sm font-medium space-x-1',
                    },
                  },
                ],
              })),
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
