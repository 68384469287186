import React, { useContext, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, FieldArray, FormikErrors, FormikTouched } from 'formik';
import { Listbox, Tab, Transition } from '@headlessui/react';

interface IDetail {
  product_service: string;
  amount: number;
  quantity: number;
  vat: number;
  discount: number;
  total: number;
  process: number;
  quantity_type: number;
  warehouse: string;
}

import Http from 'services/http';
import Invoice from 'services/invoice';
import Response from 'services/response';
import Structures from 'services/structures';
import Warehouse from 'services/warehouse';

import Spinner from 'components/spinner';

import UString from 'utilities/string';
import Customer from 'services/customer';
import Bank from 'services/bank';
import Ingradient from 'services/ingradient';

import { InvoiceValidation } from 'validations';

import { toast } from 'react-toastify';
import {
  Autocomplete,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import clone from 'clone';

export default function () {
  const Service = new Invoice(useContext(Http.Context)!);
  const Fetch = new Response();
  const Save = new Response();

  const CService = new Customer(useContext(Http.Context)!);
  const CFetch = new Response();

  const BService = new Bank(useContext(Http.Context)!);
  const BFetch = new Response();

  const OService = new Ingradient(useContext(Http.Context)!);
  const OFetch = new Response();

  const WService = new Warehouse(useContext(Http.Context)!);
  const WFetch = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();

  const { id } = useParams();

  const someFunctionWithLogic = (
    setFieldValue: any,
    index: any,
    value: number,
    section: any,
    item: any,
    values: any,
  ) => {
    value = Number(value);
    if (section == 'quantity') {
      item.quantity = value;
    }
    if (section == 'amount') {
      item.amount = value;
    }
    if (section == 'vat') {
      item.vat = value;
    }
    if (section == 'discount') {
      item.discount = value;
    }
    if (!isNaN(item.quantity) && !isNaN(item.amount)) {
      item.total =
        item.quantity * item.amount +
        (item.vat == 0 ? 0 : item.quantity * item.amount * (item.vat / 100)) -
        (item.discount == 0
          ? 0
          : (item.quantity * item.amount +
              (item.vat == 0 ? 0 : item.quantity * item.amount * (item.vat / 100))) *
            (item.discount / 100));
    }
    let total = 0;
    values.detail?.map((d: any) => {
      total += d.total;
    });
    setFieldValue('total_amount', total);
    setFieldValue(`detail[${index}]`, item);
  };

  useEffect(() => (!OFetch.data && OFetch.handle(OService.get()), undefined), [OFetch.data]);
  useEffect(() => (!id && !Fetch.data && Fetch.handle(Service.get()), undefined), [Fetch.data]);
  useEffect(() => (!WFetch.data && WFetch.handle(WService.get()), undefined), [WFetch.data]);
  useEffect(() => (id && Fetch.handle(Service.getById(id!)), undefined), [id]);
  useEffect(() => (!CFetch.data && CFetch.handle(CService.get()), undefined), [CFetch.data]);
  useEffect(() => (!BFetch.data && BFetch.handle(BService.get()), undefined), [BFetch.data]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(`Alış Faturası ${id ? 'düzenleme' : 'ekleme'} başarılı.`);
      navigate('/bills');
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(`Alış Faturası ${id ? 'düzenleme' : 'ekleme'} başarısız.`);
      navigate('/bills');
    }
  }, [Save.error]);

  useEffect(() => {
    if (id && Fetch?.error?.request?.status) {
      navigate('/404');
    }
  }, [Fetch.error || Fetch.data]);

  const calculateTotals = (type: string, products: any) => {
    let totalVat = 0;
    let totalDiscount = 0;
    let generalTotal = 0;
    products.map((product: any) => {
      let amount = product.quantity * product.amount;
      let vat = amount * (product.vat / 100);
      let discount = (amount + vat) * (product.discount / 100);
      totalVat += vat;
      totalDiscount += discount;
      generalTotal += product.total;
    });
    let subTotal = generalTotal - totalVat + totalDiscount;
    let gross = subTotal - totalDiscount;
    switch (type) {
      case 'sub':
        return subTotal ? subTotal?.toFixed(2) : '0.00';
      case 'discount':
        return totalDiscount ? totalDiscount?.toFixed(2) : '0.00';
      case 'gross':
        return gross ? gross?.toFixed(2) : '0.00';
      case 'vat':
        return totalVat ? totalVat?.toFixed(2) : '0.00';
      case 'general':
        return generalTotal ? generalTotal?.toFixed(2) : '0.00';
      default:
        return '0.00';
    }
  };

  return (
    <React.Fragment>
      <div className='header-top'>
        <h2 className='text-xl font-medium pl-2'>Alış Faturası {id ? 'Düzenle' : 'Ekle'}</h2>
      </div>
      <div id='content'>
        <Formik
          innerRef={Form}
          enableReinitialize={true}
          initialValues={{
            invoice_time: Fetch.data?.invoice_time?.slice(0, -8) || String(),
            invoice_code: Fetch.data?.invoice_code || String(),
            invoice_no: Fetch.data?.invoice_no || String(),
            customer:
              Fetch.data?.customer?.toString() ||
              Fetch.data?.bank?.toString() ||
              CFetch.data?.[0]?.id ||
              String(),
            currency: Fetch.data?.currency || Number(Object.keys(Structures.types.currency_opt)[0]),
            pay_type: Fetch.data?.pay_type || Number(Object.keys(Structures.types.payment_type)[0]),
            expiry_date: Fetch.data?.expiry_date?.slice(0, -8) || String(),
            invoice_types: 1,
            invoice_type: true,
            description: Fetch.data?.description || String(),
            customerType: Fetch.data?.customerType || 1,
            total_amount: Fetch.data?.total_amount || Number(),
            detail:
              (Fetch.data?.detail as Array<IDetail>) ||
              Array({
                product_service: Fetch.data?.product_service || String(),
                amount: Fetch.data?.amount || Number(),
                quantity: Fetch.data?.quantity || Number(),
                vat: Fetch.data?.vat || Number(),
                discount: Fetch.data?.discount || Number(),
                total: Fetch.data?.total || Number(),
                quantity_type:
                  Fetch.data?.quantity_type || Number(Object.keys(Structures.types.quantity)[0]),
                warehouse: Fetch.data?.warehouse || WFetch?.data?.[0]?.id || String(),
              }),
          }}
          validationSchema={InvoiceValidation}
          onSubmit={(values) =>
            Save.handle(
              id && Fetch.data
                ? Service.update(id, {
                    ...Fetch.data,
                    ...values,
                  })
                : Service.create(values),
            )
          }
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form className='action' onSubmit={handleSubmit}>
              <Tab.Group>
                <Tab.List className='my-4'>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        'mr-3 ',
                        '',
                        selected
                          ? 'border-b border-red-600 text-red-600 font-bold'
                          : 'border-b border-black',
                      )
                    }
                  >
                    Temel Bilgiler
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        'mr-3 ',
                        '',
                        selected
                          ? 'border-b border-red-600 text-red-600 font-bold'
                          : 'border-b border-black',
                      )
                    }
                  >
                    Ödeme Bilgileri
                  </Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel>
                    <div className='wrapper'>
                      <div className='item'>
                        <div className='w-full'>
                          <label htmlFor='invoice_time'>Tarih ve Saat</label>
                          <input
                            type='datetime-local'
                            name='invoice_time'
                            className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                              errors.invoice_time && touched.invoice_time ? 'has-error' : undefined,
                              'my-1',
                            )}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.invoice_time}
                          />
                          {errors.invoice_time && touched.invoice_time ? (
                            <span className='error'>* {errors.invoice_time}</span>
                          ) : null}
                        </div>
                        <div className='w-full'>
                          <label htmlFor='invoice_no'>Fatura No Seri</label>
                          <input
                            type='text'
                            name='invoice_no'
                            className={UString.concat_class_name(
                              errors.invoice_no && touched.invoice_no ? 'has-error' : undefined,
                              'my-1',
                            )}
                            placeholder='Fatura No Seri'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.invoice_no}
                          />
                          {errors.invoice_no && touched.invoice_no ? (
                            <span className='error'>* {errors.invoice_no}</span>
                          ) : null}
                        </div>
                        <div className='w-full'>
                          <label htmlFor='invoice_code'>Fatura No Sıra</label>
                          <input
                            type='text'
                            name='invoice_code'
                            className={UString.concat_class_name(
                              errors.invoice_code && touched.invoice_code ? 'has-error' : undefined,
                              'my-1',
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder='Fatura No Sıra'
                            value={values.invoice_code}
                          />
                          {errors.invoice_code && touched.invoice_code ? (
                            <span className='error'>* {errors.invoice_code}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className='item'>
                        <div className='w-full'>
                          <FormLabel id='customer-select-label'>Müşteri Tipi</FormLabel>
                          <RadioGroup
                            value={Number(values.customerType)}
                            aria-labeledBy='customer-select-label'
                            name='customerType'
                            onChange={(e: any) => {
                              setFieldValue('customerType', parseInt(e.target.value));
                              e.target.value = parseInt(e.target.value);
                              switch (parseInt(e.target.value)) {
                                case 1:
                                  setFieldValue('customer', CFetch?.data[0]?.id || '');
                                  break;
                                case 2:
                                  setFieldValue('customer', BFetch?.data[0]?._id || '');
                                  setFieldValue('detail', []);
                                  break;
                                default:
                                  break;
                              }
                            }}
                          >
                            <Stack direction='row'>
                              <FormControlLabel value={1} control={<Radio />} label='Müşteri' />
                              <FormControlLabel value={2} control={<Radio />} label='Banka' />
                            </Stack>
                          </RadioGroup>
                        </div>
                        <div className='relative w-full'>
                          {CFetch.data ? (
                            <>
                              {values.customerType == 1 ? (
                                <Stack direction='column'>
                                  <Autocomplete
                                    defaultValue={values.customer || CFetch?.data[0]?.id}
                                    componentName='customer'
                                    options={CFetch?.data
                                      ?.filter((customer: any) => customer.card_type != 4)
                                      ?.map((customer: any) => customer.id)}
                                    getOptionLabel={(option: any) =>
                                      CFetch?.data?.find((customer: any) => customer.id == option)
                                        ?.title
                                    }
                                    onChange={(e: any, value: any) => {
                                      setFieldValue('customer', value || '');
                                    }}
                                    clearOnEscape
                                    renderInput={(params) => (
                                      <TextField variant='outlined' {...params} label='Müşteri' />
                                    )}
                                  />
                                  {errors.customer && touched.customer ? (
                                    <span className='error'>* {errors.customer}</span>
                                  ) : null}
                                </Stack>
                              ) : null}
                              {values.customerType == 2 ? (
                                <Stack direction='column'>
                                  <Autocomplete
                                    defaultValue={values.customer || BFetch?.data[0]?._id}
                                    componentName='customer'
                                    options={BFetch?.data?.map((bank: any) => bank._id)}
                                    getOptionLabel={(option: any) =>
                                      BFetch?.data?.find(
                                        (bank: any) => bank._id?.toString() == option,
                                      )?.account_name
                                    }
                                    onChange={(e: any, value: any) => {
                                      setFieldValue('customer', value || '');
                                    }}
                                    clearOnEscape
                                    renderInput={(params) => (
                                      <TextField
                                        variant='outlined'
                                        {...params}
                                        label='Banka Hesabı'
                                      />
                                    )}
                                  />
                                  {errors.customer && touched.customer ? (
                                    <span className='error'>* {errors.customer}</span>
                                  ) : null}
                                </Stack>
                              ) : null}
                            </>
                          ) : (
                            <div className='classic-btn w-full min-h-[38px] mt-1'>
                              <Spinner className='w-5 m-auto' />
                            </div>
                          )}
                        </div>
                        <div className='relative w-full'>
                          <label>Döviz Cinsi</label>
                          <Listbox
                            value={values.currency}
                            onChange={(value) => setFieldValue('currency', Number(value))}
                          >
                            <div className='relative mt-1'>
                              <Listbox.Button className='listbox-btn'>
                                <span className='listbox-title'>
                                  {(Structures.types.currency_opt as any)[values.currency]}
                                </span>
                                <span className='listbox-selector-icon'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='w-5 h-5 text-gray-400'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                  >
                                    <path
                                      fillRule='evenodd'
                                      d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                      clipRule='evenodd'
                                    />
                                  </svg>
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={React.Fragment}
                                leave='transition ease-in duration-100'
                                leaveFrom='opacity-100'
                                leaveTo='opacity-0'
                              >
                                <Listbox.Options className='listbox-options'>
                                  {Object.keys(Structures.types.currency_opt).map((type, index) => (
                                    <Listbox.Option
                                      className={UString.concat_class_name(
                                        values.currency === Number(type)
                                          ? 'bg-ebony text-white'
                                          : 'hover:bg-ebony hover:text-white',
                                        'flex items-center cursor-pointer px-6 py-2',
                                      )}
                                      key={index}
                                      value={Number(type)}
                                    >
                                      <span>{(Structures.types.currency_opt as any)[type]}</span>
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      </div>
                    </div>
                    <hr className='my-3' />
                    {values.customerType == 2 ? (
                      <div className='text-lg'>
                        <div className='w-full'>
                          <label htmlFor='invoice_no'>Toplam Tutar</label>
                          <input
                            type='number'
                            name='total_amount'
                            className={UString.concat_class_name(
                              errors.total_amount && touched.total_amount ? 'has-error' : undefined,
                              'my-1',
                            )}
                            placeholder='Toplam Tutar'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.total_amount}
                          />
                          {errors.total_amount && touched.total_amount ? (
                            <span className='error'>* {errors.total_amount}</span>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <div className='text-lg'>
                        {WFetch.data?.length == 0 ? (
                          <Typography
                            fontSize='1.3vw'
                            fontWeight='bold'
                            textAlign='center'
                            marginBottom='4vw'
                          >
                            Oluşturulmuş depo bulunamadı.
                          </Typography>
                        ) : (
                          <>
                            {OFetch.data?.length == 0 ? (
                              <Typography
                                fontSize='1.3vw'
                                fontWeight='bold'
                                textAlign='center'
                                marginBottom='4vw'
                              >
                                Alım yapılacak stok bulunamadı.
                              </Typography>
                            ) : (
                              <>
                                {
                                  <FieldArray
                                    name='detail'
                                    render={({ insert, remove }) => (
                                      <>
                                        <div className='flex flex-col space-y-4 divide-y divide-gray-200 xl:divide-y-0'>
                                          {values.detail.map((item, index) => {
                                            const touch = touched.detail?.[index] as
                                              | FormikTouched<IDetail>
                                              | undefined;
                                            const error = errors.detail?.[index] as
                                              | FormikErrors<IDetail>
                                              | undefined;

                                            return (
                                              <div
                                                className='flex flex-col xl:flex-row gap-4 w-full py-4 xl:py-0'
                                                key={index}
                                              >
                                                <div className='relative w-full'>
                                                  <label htmlFor={`detail.[${index}].warehouse`}>
                                                    Depo
                                                  </label>
                                                  <Listbox
                                                    value={values.detail[index].warehouse}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `detail[${index}].warehouse`,
                                                        String(value),
                                                      );
                                                      setFieldValue(
                                                        `detail[${index}].product_service`,
                                                        '',
                                                      );
                                                    }}
                                                  >
                                                    <div className='relative mt-1'>
                                                      <Listbox.Button className='listbox-btn'>
                                                        <span className='listbox-title'>
                                                          {WFetch?.data?.find(
                                                            (warehouse: any) =>
                                                              warehouse.id == item.warehouse,
                                                          )?.title || 'Depo Seç'}
                                                        </span>
                                                        <span className='listbox-selector-icon'>
                                                          <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            className='w-5 h-5 text-gray-400'
                                                            viewBox='0 0 20 20'
                                                            fill='currentColor'
                                                          >
                                                            <path
                                                              fillRule='evenodd'
                                                              d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                                              clipRule='evenodd'
                                                            />
                                                          </svg>
                                                        </span>
                                                      </Listbox.Button>
                                                      <Transition
                                                        as={React.Fragment}
                                                        leave='transition ease-in duration-100'
                                                        leaveFrom='opacity-100'
                                                        leaveTo='opacity-0'
                                                      >
                                                        <Listbox.Options className='listbox-options'>
                                                          {WFetch?.data?.map((warehouse: any) => (
                                                            <Listbox.Option
                                                              className={UString.concat_class_name(
                                                                values.detail[index]?.warehouse ===
                                                                  warehouse.id
                                                                  ? 'bg-ebony text-white'
                                                                  : 'hover:bg-ebony hover:text-white',
                                                                'flex items-center cursor-pointer px-6 py-2',
                                                              )}
                                                              key={warehouse.id}
                                                              value={warehouse.id}
                                                            >
                                                              <span>{warehouse.title}</span>
                                                            </Listbox.Option>
                                                          ))}
                                                        </Listbox.Options>
                                                      </Transition>
                                                    </div>
                                                  </Listbox>
                                                </div>
                                                <div className='relative w-full'>
                                                  <label>Ürün</label>
                                                  {OFetch.data ? (
                                                    <div className='relative mt-1'>
                                                      <Autocomplete
                                                        size='small'
                                                        key={`detail.[${index}].product_service`}
                                                        value={
                                                          values.detail[index].product_service ||
                                                          null
                                                        }
                                                        componentName='product'
                                                        options={OFetch.data?.map(
                                                          (product: any) => product.id,
                                                        )}
                                                        getOptionLabel={(option: any) =>
                                                          OFetch?.data?.find(
                                                            (product: any) => product.id == option,
                                                          )?.title
                                                        }
                                                        onChange={(e: any, value: any) => {
                                                          setFieldValue(
                                                            `detail.[${index}].product_service`,
                                                            value || '',
                                                          );
                                                        }}
                                                        clearOnEscape
                                                        renderInput={(params) => (
                                                          <TextField
                                                            variant='outlined'
                                                            {...params}
                                                          />
                                                        )}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div className='classic-btn w-full min-h-[38px] mt-1'>
                                                      <Spinner className='w-5 m-auto' />
                                                    </div>
                                                  )}
                                                </div>
                                                <div className='w-full xl:w-1/2'>
                                                  <label htmlFor={`detail.[${index}].quantity`}>
                                                    Miktar
                                                  </label>
                                                  <input
                                                    type='number'
                                                    name={`detail.[${index}].quantity`}
                                                    className={UString.concat_class_name(
                                                      error?.amount && touch?.amount
                                                        ? 'has-error'
                                                        : undefined,
                                                      'mt-1',
                                                    )}
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                      someFunctionWithLogic(
                                                        setFieldValue,
                                                        index,
                                                        Number(e.target.value),
                                                        'quantity',
                                                        item,
                                                        values,
                                                      );
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={item.quantity}
                                                  />
                                                  {error?.quantity && touch?.quantity ? (
                                                    <span className='error'>
                                                      * {error?.quantity}
                                                    </span>
                                                  ) : null}
                                                </div>
                                                <div className='relative w-full xl:w-1/2'>
                                                  <label>Tipi</label>
                                                  <Listbox
                                                    value={values.detail[index].quantity_type}
                                                    onChange={(value) =>
                                                      setFieldValue(
                                                        `detail[${index}].quantity_type`,
                                                        Number(value),
                                                      )
                                                    }
                                                  >
                                                    <div className='relative mt-1'>
                                                      <Listbox.Button className='listbox-btn'>
                                                        <span className='listbox-title'>
                                                          {
                                                            (Structures.types.quantity as any)[
                                                              values.detail[index].quantity_type
                                                            ]
                                                          }
                                                        </span>
                                                        <span className='listbox-selector-icon'>
                                                          <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            className='w-5 h-5 text-gray-400'
                                                            viewBox='0 0 20 20'
                                                            fill='currentColor'
                                                          >
                                                            <path
                                                              fillRule='evenodd'
                                                              d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                                              clipRule='evenodd'
                                                            />
                                                          </svg>
                                                        </span>
                                                      </Listbox.Button>
                                                      <Transition
                                                        as={React.Fragment}
                                                        leave='transition ease-in duration-100'
                                                        leaveFrom='opacity-100'
                                                        leaveTo='opacity-0'
                                                      >
                                                        <Listbox.Options className='listbox-options'>
                                                          {Object.keys(
                                                            Structures.types.quantity,
                                                          ).map((type) => (
                                                            <Listbox.Option
                                                              className={UString.concat_class_name(
                                                                values.detail[index]
                                                                  ?.quantity_type === Number(type)
                                                                  ? 'bg-ebony text-white'
                                                                  : 'hover:bg-ebony hover:text-white',
                                                                'flex items-center cursor-pointer px-6 py-2',
                                                              )}
                                                              key={type}
                                                              value={Number(type)}
                                                            >
                                                              <span>
                                                                {
                                                                  (
                                                                    Structures.types.quantity as any
                                                                  )[type]
                                                                }
                                                              </span>
                                                            </Listbox.Option>
                                                          ))}
                                                        </Listbox.Options>
                                                      </Transition>
                                                    </div>
                                                  </Listbox>
                                                </div>
                                                <div className='w-full xl:w-1/2'>
                                                  <label htmlFor={`detail.[${index}].amount`}>
                                                    Birim Fiyat
                                                  </label>
                                                  <input
                                                    type='number'
                                                    name={`detail.[${index}].amount`}
                                                    className={UString.concat_class_name(
                                                      error?.amount && touch?.amount
                                                        ? 'has-error'
                                                        : undefined,
                                                      'mt-1',
                                                    )}
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                      someFunctionWithLogic(
                                                        setFieldValue,
                                                        index,
                                                        Number(e.target.value),
                                                        'amount',
                                                        item,
                                                        values,
                                                      );
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={item.amount}
                                                  />
                                                  {error?.amount && touch?.amount ? (
                                                    <span className='error'>* {error?.amount}</span>
                                                  ) : null}
                                                </div>
                                                <div className='w-full xl:w-1/2'>
                                                  <label htmlFor={`detail.[${index}].vat`}>
                                                    Kdv %
                                                  </label>
                                                  <input
                                                    type='number'
                                                    name={`detail.[${index}].vat`}
                                                    className={UString.concat_class_name(
                                                      error?.amount && touch?.amount
                                                        ? 'has-error'
                                                        : undefined,
                                                      'mt-1',
                                                    )}
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                      someFunctionWithLogic(
                                                        setFieldValue,
                                                        index,
                                                        Number(e.target.value),
                                                        'vat',
                                                        item,
                                                        values,
                                                      );
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={item.vat}
                                                  />
                                                  {error?.vat && touch?.vat ? (
                                                    <span className='error'>* {error?.vat}</span>
                                                  ) : null}
                                                </div>
                                                <div className='w-full xl:w-1/2'>
                                                  <label htmlFor={`detail.[${index}].discount`}>
                                                    İndirim %
                                                  </label>
                                                  <input
                                                    type='number'
                                                    name={`detail.[${index}].discount`}
                                                    className={UString.concat_class_name(
                                                      error?.amount && touch?.amount
                                                        ? 'has-error'
                                                        : undefined,
                                                      'mt-1',
                                                    )}
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                      someFunctionWithLogic(
                                                        setFieldValue,
                                                        index,
                                                        Number(e.target.value),
                                                        'discount',
                                                        item,
                                                        values,
                                                      );
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={item.discount}
                                                  />
                                                  {error?.discount && touch?.discount ? (
                                                    <span className='error'>
                                                      * {error?.discount}
                                                    </span>
                                                  ) : null}
                                                </div>
                                                <div className='w-full xl:w-1/2'>
                                                  <label htmlFor={`detail.[${index}].total`}>
                                                    Toplam
                                                  </label>
                                                  <input
                                                    type='number'
                                                    name={`detail.[${index}].total`}
                                                    className={UString.concat_class_name(
                                                      error?.amount && touch?.amount
                                                        ? 'has-error'
                                                        : undefined,
                                                      'mt-1',
                                                    )}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={item.total}
                                                  />
                                                  {error?.total && touch?.total ? (
                                                    <span className='error'>* {error?.total}</span>
                                                  ) : null}
                                                </div>
                                                <div className='xl:mt-6'>
                                                  <button
                                                    className='classic-btn red w-full xl:w-auto'
                                                    type='button'
                                                    onClick={() => {
                                                      let total = 0;
                                                      let tempValue = clone(values.detail);
                                                      tempValue.splice(index, 1);
                                                      values.detail.length > 0 && remove(index);
                                                      tempValue?.map((d: any) => {
                                                        total += d.total;
                                                      });
                                                      setFieldValue('total_amount', total);
                                                    }}
                                                  >
                                                    <svg
                                                      xmlns='http://www.w3.org/2000/svg'
                                                      className='h-5 w-5'
                                                      fill='none'
                                                      viewBox='0 0 24 24'
                                                      stroke='currentColor'
                                                    >
                                                      <path
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        strokeWidth={1.5}
                                                        d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
                                                      />
                                                    </svg>
                                                  </button>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <div className='w-full pt-4 '>
                                          <button
                                            className='w-full xl:ml-auto classic-btn justify-center'
                                            type='button'
                                            onClick={insert.bind(null, values.detail.length + 1, {
                                              product_service:
                                                Fetch.data?.product_service || String(),
                                              amount: Fetch.data?.amount || Number(),
                                              quantity: Fetch.data?.quantity || Number(),
                                              vat: Fetch.data?.vat || Number(),
                                              discount: Fetch.data?.discount || Number(),
                                              total: Fetch.data?.total || Number(),
                                              quantity_type:
                                                Fetch.data?.quantity_type ||
                                                Number(Object.keys(Structures.types.quantity)[0]),
                                            } as IDetail)}
                                          >
                                            Ekle
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  />
                                }
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className='wrapper'>
                      <div className='item'>
                        <div className='relative w-full'>
                          <label>Ödeme Şekli</label>
                          <Listbox
                            value={values.pay_type}
                            onChange={(value) => setFieldValue('pay_type', Number(value))}
                          >
                            <div className='relative mt-1'>
                              <Listbox.Button className='listbox-btn'>
                                <span className='listbox-title'>
                                  {(Structures.types.payment_type as any)[values.pay_type]}
                                </span>
                                <span className='listbox-selector-icon'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='w-5 h-5 text-gray-400'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                  >
                                    <path
                                      fillRule='evenodd'
                                      d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                      clipRule='evenodd'
                                    />
                                  </svg>
                                </span>
                              </Listbox.Button>
                              <Listbox.Options className='listbox-options'>
                                {Object.keys(Structures.types.payment_type).map((type, index) => (
                                  <Listbox.Option
                                    className={UString.concat_class_name(
                                      values.pay_type === Number(type)
                                        ? 'bg-ebony text-white'
                                        : 'hover:bg-ebony hover:text-white',
                                      'flex items-center cursor-pointer px-6 py-2',
                                    )}
                                    key={index}
                                    value={Number(type)}
                                  >
                                    <span>{(Structures.types.payment_type as any)[type]}</span>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </div>
                          </Listbox>
                        </div>
                        <div className='w-full'>
                          <label htmlFor='expiry_date'>Son Ödeme Tarihi</label>
                          <input
                            type='datetime-local'
                            name='expiry_date'
                            className={`w-full rounded-lg border-gray-300 focus:border-black ring-0 ring-offset-black ${UString.concat_class_name(
                              errors.expiry_date && touched.expiry_date ? 'has-error' : undefined,
                              'my-1',
                            )}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.expiry_date}
                          />
                          {errors.expiry_date && touched.expiry_date ? (
                            <span className='error'>* {errors.expiry_date}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className='item'>
                        <div className='w-full'>
                          <label htmlFor='description'>Ödeme Notu</label>
                          <textarea
                            name='description'
                            className={UString.concat_class_name(
                              errors.description && touched.description ? 'has-error' : undefined,
                              'my-1',
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder='Ödeme Notu'
                            value={values.description}
                          />
                          {errors.description && touched.description ? (
                            <span className='error'>* {errors.description}</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
              <div style={{ marginTop: '1vw' }}>
                <Stack direction='column' spacing={1}>
                  <Stack direction='row' spacing={1} alignSelf='center'>
                    <div>
                      <strong>Ara Toplam:</strong> {calculateTotals('sub', values.detail)} ₺
                    </div>
                    <div>
                      <strong>Toplam İndirim:</strong> {calculateTotals('discount', values.detail)}{' '}
                      ₺
                    </div>
                  </Stack>
                  <Stack direction='row' spacing={1} alignSelf='center'>
                    <div>
                      <strong>Brüt Toplam:</strong> {calculateTotals('gross', values.detail)} ₺
                    </div>
                    <div>
                      <strong>Toplam KDV:</strong> {calculateTotals('vat', values.detail)} ₺
                    </div>
                  </Stack>
                  <Stack direction='row' spacing={1} alignSelf='center'>
                    <div>
                      <strong>Genel Toplam:</strong> {calculateTotals('general', values.detail)} ₺
                    </div>
                  </Stack>
                </Stack>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className='header-bottom'>
        <button
          type='submit'
          disabled={Save.loading}
          className='ml-auto classic-btn'
          onClick={() => {
            Form.current.handleSubmit();
          }}
        >
          {Save.loading ? <Spinner className='h-5 m-auto' /> : 'Kaydet'}
        </button>
      </div>
    </React.Fragment>
  );
}
