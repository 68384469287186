import { Service as HService } from 'services/http';

export default class Service {
  private Http: HService | null = null;

  constructor(http: HService) {
    this.Http = http;
  }

  public async get(
    page: number | undefined = undefined,
    perPage: number | undefined = undefined,
    sort: string | undefined = undefined,
  ) {
    const base: URL = new URL(`${this.Http?.baseURL}/bank-receipts`);

    if (page !== undefined && !Number.isNaN(page)) base.searchParams.append('page', String(page));
    if (perPage !== undefined && !Number.isNaN(perPage))
      base.searchParams.append('perPage', String(perPage));
    if (sort !== undefined) {
      base.searchParams.append('sort', String(sort));
    }

    return await this.Http?.instance.get(base.href);
  }

  public async getById(receiptId: string) {
    return await this.Http?.instance.get(`/bank-receipts/${receiptId}`);
  }

  public async create(data: any) {
    return await this.Http?.instance.post('/bank-receipts', data);
  }

  public async delete(receiptId: string) {
    return await this.Http?.instance.delete(`/bank-receipts/${receiptId}`);
  }

  public async update(receiptId: string, data: any) {
    return await this.Http?.instance.put(`/checks/${receiptId}`, data);
  }
}
