import { Service as HService } from 'services/http';
import { IMoneyInFlow, IMoneyOut } from './interfaces/money-transfers';

export default class Service {
  private Http: HService | null = null;

  constructor(http: HService) {
    this.Http = http;
  }

  public async getAll(
    page: number | undefined = undefined,
    perPage: number | undefined = undefined,
    sectionId: string,
    sectionType: string,
  ) {
    const base: URL = new URL(`${this.Http?.baseURL}/money-transfers/${sectionId}/${sectionType}`);

    if (page !== undefined && !Number.isNaN(page)) base.searchParams.append('page', String(page));

    if (perPage !== undefined && !Number.isNaN(perPage))
      base.searchParams.append('perPage', String(perPage));

    return await this.Http?.instance.get(base.href);
  }

  public async getAllTransfersAndInvoices(
    page: number | undefined = undefined,
    perPage: number | undefined = undefined,
    userId: string,
    userType: string,
  ) {
    const base: URL = new URL(`${this.Http?.baseURL}/transfers-invoices/${userId}/${userType}`);

    if (page !== undefined && !Number.isNaN(page)) base.searchParams.append('page', String(page));

    if (perPage !== undefined && !Number.isNaN(perPage))
      base.searchParams.append('perPage', String(perPage));

    return await this.Http?.instance.get(base.href);
  }

  public async deleteMoneyTransfer(transferId: string) {
    return await this.Http?.instance.delete(`/money-transfers/${transferId}`);
  }
}
